import {GraphQLError} from 'graphql/error';
import _, {compact} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import config, {envIsLocal} from '../../config';
import {
  CloudProvider,
  ErrorSeverity,
  Maybe,
  StorageBucketInfo,
  useAvailableBucketProvidersQuery,
  useAvailableOrgQuery,
  useAvailableTeamQuery,
  useOrganizationStorageBucketInfosQuery,
  useStorageBucketInfosQuery,
  useTestBucketStoreConnectionMutation,
} from '../../generated/graphql';
import {useAdminModeActive} from '../../util/admin';
import {propagateErrorsContext} from '../../util/errors';
import {useDebounceState} from '../../util/hooks';
import {slugFormat} from '../../util/text';
import {StorageBucketInfoFormValue, StorageBucketValidState} from './types';

export const cloudProviderValuesToDisplayNames = {
  [CloudProvider.Aws]: 'AWS',
  [CloudProvider.Azure]: 'Azure',
  [CloudProvider.Gcp]: 'Google',
  [CloudProvider.Minio]: 'S3-compatible storage',
};

export interface AzureBucketInfo {
  accountName: string;
  containerName: string;
  tenantID?: Maybe<string>;
  clientID?: Maybe<string>;
}
export function getAzureBucketInfo(
  bucketInfo?: StorageBucketInfoFormValue
): AzureBucketInfo | null {
  if (!bucketInfo || bucketInfo.provider !== CloudProvider.Azure) {
    return null;
  }

  const sections = bucketInfo.name.split('/');

  return {
    accountName: sections[0] ?? '',
    containerName: sections[1] ?? '',
    tenantID: bucketInfo.azureTenantID ?? '',
    clientID: bucketInfo.azureClientID ?? '',
  };
}

export function useCloudProviderOptions(): [
  isLoading: boolean,
  options: Array<{
    value: CloudProvider;
    text: string;
  }>,
  refetch: () => void
] {
  const {data, loading, refetch} = useAvailableBucketProvidersQuery();

  return useMemo(() => {
    if (loading) {
      return [true, [], refetch];
    }

    const options = (data?.serverInfo?.availableBucketProviders || []).map(
      provider => {
        return {
          value: provider,
          text: cloudProviderValuesToDisplayNames[provider],
        };
      }
    );

    return [false, options, refetch];
  }, [data, loading, refetch]);
}

export function useStorageBucketInfoOptions(
  organizationId: string | null
): [isLoading: boolean, options: StorageBucketInfo[], refetch: () => void] {
  const adminModeActive = useAdminModeActive();
  const baseOptions = {
    context: propagateErrorsContext(),
    onError: ({
      graphQLErrors,
    }: {
      graphQLErrors: ReadonlyArray<GraphQLError>;
    }) => {
      graphQLErrors = graphQLErrors.filter(
        err => err.message !== 'permission denied'
      );
      if (graphQLErrors.length > 0) {
        throw graphQLErrors[0];
      }
    },
  };
  const storageBucketInfosQueryResult = useStorageBucketInfosQuery({
    // only execute this query in W&B server. In SaaS, we query for storage buckets at the organization level.
    // unfortunately, W&B server does not have the concept of organizations, so we have to make an entirely different query
    skip: !config.ENVIRONMENT_IS_PRIVATE || !adminModeActive,
    ...baseOptions,
  });
  const organizationStorageBucketInfosQueryResult =
    useOrganizationStorageBucketInfosQuery({
      variables: {
        organizationId: organizationId ?? '',
      },
      skip: organizationId == null,
      ...baseOptions,
    });

  return useMemo(() => {
    if (!config.ENVIRONMENT_IS_PRIVATE) {
      const {data, loading, refetch} =
        organizationStorageBucketInfosQueryResult;
      if (loading) {
        return [true, [], refetch];
      }
      const options =
        compact(
          data?.organization?.teams.map(team => team.settings.storageBucketInfo)
        ) ?? [];
      const uniqueOptions = _.uniqBy(options, o => o.ID);

      return [false, uniqueOptions, refetch];
    } else {
      const {data, loading, refetch} = storageBucketInfosQueryResult;
      if (loading) {
        return [true, [], refetch];
      }
      const options =
        compact(
          data?.entities?.edges.map(
            edge => edge.node?.settings.storageBucketInfo
          )
        ) ?? [];
      const uniqueOptions = _.uniqBy(options, o => o.ID);

      return [false, uniqueOptions, refetch];
    }
  }, [
    organizationStorageBucketInfosQueryResult,
    storageBucketInfosQueryResult,
  ]);
}

export interface StorageBucketInfoProps {
  /**
   * If this is false, the rest of the storage bucket input form will be hidden.
   */
  usingExternalStorage: boolean;
  setUsingExternalStorage: (val: boolean) => void;

  /**
   * Whether to enable the external storage option
   */
  isExternalStorageEnabled: boolean;
  setIsExternalStorageEnabled: (val: boolean) => void;

  formIsReady: boolean;

  /**
   * The bucket info seen by the parent -- might be an existing bucket (with an ID)
   * or a new bucket (without one)
   */
  bucketInfo: StorageBucketInfoFormValue;
  setBucketInfo: (val: StorageBucketInfoFormValue) => void;
  getInitialBucketInfo: () => NonNullable<StorageBucketInfoFormValue>;

  cloudProviderOptions: Array<{
    value: CloudProvider;
    text: string;
  }>;
  storageBucketInfoOptions: StorageBucketInfo[];

  isValidState: StorageBucketValidState;

  resetBucketInfo: () => void;

  /**
   * Overrides for storage option elements
   */
  storageLabelOptions?: React.ReactNode;
  setOrganizationId: (id: string | null) => void;
}

function useBucketValidState(
  bucketInfo: StorageBucketInfoFormValue,
  debouncedBucketInfo: StorageBucketInfoFormValue,
  organizationId: string | null,
  externalStorageIsEnabled: boolean
): StorageBucketValidState {
  const [valid, setValid] = useState<StorageBucketValidState>({state: 'unset'});

  const latestBucketInfo = useRef<StorageBucketInfoFormValue>(bucketInfo);
  const [testBucketStoreConnectionMutation] =
    useTestBucketStoreConnectionMutation();

  useEffect(() => {
    // we need this in a ref so we can check it in a callback *without*
    // invalidating the callback on changes
    latestBucketInfo.current = bucketInfo;
  }, [bucketInfo]);

  useEffect(() => {
    (async (validatingForBucketInfo: StorageBucketInfoFormValue) => {
      if (
        !validatingForBucketInfo ||
        validatingForBucketInfo.name === '' ||
        !externalStorageIsEnabled
      ) {
        return;
      }

      setValid({state: 'loading'});

      const {data} = await testBucketStoreConnectionMutation({
        variables: {
          input: {
            ...validatingForBucketInfo,
            organizationID: organizationId,
          },
        },
      });

      // this is a guard against out-of-order responses when rapidly changing bucketInfo
      const dataRepresentsCurrentBucketInfo =
        latestBucketInfo.current === validatingForBucketInfo;

      const hasErrorsOrWarnings =
        (data?.testBucketStoreConnection?.length ?? 0) > 0;

      if (dataRepresentsCurrentBucketInfo && hasErrorsOrWarnings) {
        const errors = data?.testBucketStoreConnection
          .filter(err => err.severity === ErrorSeverity.Error)
          .map(err => err.message);
        const warnings = data?.testBucketStoreConnection
          .filter(err => err.severity === ErrorSeverity.Warn)
          .map(err => err.message);
        const newValidState: StorageBucketValidState = {
          state: (errors?.length ?? 0) > 0 ? 'invalid' : 'valid',
          errors,
          warnings,
        };
        setValid(newValidState);
        return;
      } else if (dataRepresentsCurrentBucketInfo) {
        setValid({
          state: 'valid',
        });
      }
    })(debouncedBucketInfo);
  }, [
    debouncedBucketInfo,
    testBucketStoreConnectionMutation,
    organizationId,
    externalStorageIsEnabled,
  ]);

  if (bucketInfo !== debouncedBucketInfo) {
    return {state: 'loading'};
  }

  return valid;
}

export function useStorageBucketConfig(): StorageBucketInfoProps {
  const [usingExternalStorage, setUsingExternalStorage] = useState(false);
  const [externalStorageEnabled, setExternalStorageEnabled] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const [
    bucketInfo,
    debouncedBucketInfo,
    setBucketInfo,
    setBucketInfoAndDebouncedBucketInfo,
  ] = useDebounceState<StorageBucketInfoFormValue>(null, 500);

  const [
    storageBucketInfoOptionsLoading,
    storageBucketInfoOptions,
    storageBucketInfoOptionsRefetch,
  ] = useStorageBucketInfoOptions(organizationId);

  const [
    cloudProviderOptionsLoading,
    cloudProviderOptions,
    cloudProviderOptionsRefetch,
  ] = useCloudProviderOptions();

  const formIsReady =
    !storageBucketInfoOptionsLoading && !cloudProviderOptionsLoading;

  const getInitialBucketInfo = useCallback(() => {
    if (storageBucketInfoOptions.length > 0) {
      setBucketInfoAndDebouncedBucketInfo(storageBucketInfoOptions[0]);
    }

    if (cloudProviderOptions.length < 1) {
      throw new Error(
        `No cloud providers available for BYOB. This is an invalid state; please contact support.`
      );
    }

    return {
      name: '',
      provider: cloudProviderOptions[0].value,
    };
  }, [
    cloudProviderOptions,
    setBucketInfoAndDebouncedBucketInfo,
    storageBucketInfoOptions,
  ]);

  // when the form has loaded, select the first available bucket if there
  // is one; otherwise populate a new bucket with an empty name and the
  // first available cloud provider
  useEffect(() => {
    if (!formIsReady || bucketInfo !== null) {
      return;
    }

    setBucketInfoAndDebouncedBucketInfo(getInitialBucketInfo());
  }, [
    bucketInfo,
    formIsReady,
    getInitialBucketInfo,
    setBucketInfoAndDebouncedBucketInfo,
  ]);

  const reset = useCallback(() => {
    setBucketInfoAndDebouncedBucketInfo(null);
    cloudProviderOptionsRefetch();
    storageBucketInfoOptionsRefetch();
  }, [
    cloudProviderOptionsRefetch,
    setBucketInfoAndDebouncedBucketInfo,
    storageBucketInfoOptionsRefetch,
  ]);

  const validState = useBucketValidState(
    bucketInfo,
    debouncedBucketInfo,
    organizationId,
    externalStorageEnabled
  );

  return {
    usingExternalStorage,
    setUsingExternalStorage,

    isExternalStorageEnabled: externalStorageEnabled,
    setIsExternalStorageEnabled: setExternalStorageEnabled,

    formIsReady,

    bucketInfo: usingExternalStorage ? bucketInfo : null,
    setBucketInfo,
    getInitialBucketInfo,

    cloudProviderOptions,
    storageBucketInfoOptions,

    isValidState: validState,

    resetBucketInfo: reset,
    setOrganizationId,
  };
}

export function generateOrgName(teamName: string): string {
  return `${teamName}-org`;
}

const MAX_ORG_LENGTH = 64;
const NUM_RANDOM_DIGITS = 4;
export function useGenerateTeamName(
  username: string,
  companyName: string
): {loading: boolean; generatedTeamName: string | null} {
  // Generate pre-filled name of slugified username-company name
  let teamName = slugFormat(`${username}-${companyName}`);

  const generatedOrgName = generateOrgName(teamName);
  const generatedOrgNameSuffixLength =
    generatedOrgName.length - teamName.length; // Assumes that generatedOrgName is longer than teamName because we're adding a suffix
  const isTooLong = generatedOrgName.length > MAX_ORG_LENGTH;
  // If it is too long, truncate it to the max length (subtract from max length to make room for random numbers and generated org suffix if necessary).
  teamName = isTooLong
    ? teamName.substring(
        0,
        MAX_ORG_LENGTH - NUM_RANDOM_DIGITS - generatedOrgNameSuffixLength
      )
    : teamName;

  // If it is unavailable, add (1-1000) random numbers to it. Chances are that the random numbers won't collide, and it'll be available.
  const {loading, isEntityAvailable, isOrgAvailable} = useAvailability({
    entityName: teamName,
    orgName: generateOrgName(teamName),
  });
  teamName = useMemo(
    () =>
      !loading && !(isEntityAvailable && isOrgAvailable)
        ? `${teamName}${_.random(1, Math.pow(10, NUM_RANDOM_DIGITS))}`
        : teamName,
    [teamName, loading, isEntityAvailable, isOrgAvailable]
  );
  const {
    loading: loadingRandom,
    isEntityAvailable: isRandomEntityAvailable,
    isOrgAvailable: isRandomOrgAvailable,
  } = useAvailability({
    entityName: teamName,
    orgName: generateOrgName(teamName),
  });
  if (loadingRandom || loading) {
    // Don't show skip button until we know we have generated an available team name
    return {loading: true, generatedTeamName: null};
  }

  if (!(isRandomEntityAvailable && isRandomOrgAvailable)) {
    // If we haven't been able to generate an available team name, then don't show the skip button
    return {loading: false, generatedTeamName: null};
  }

  return {loading: false, generatedTeamName: teamName};
}

type UseAvailabilityParams = {
  entityName: string | null;
  orgName: string | null;
  skip?: boolean;
};

export function useAvailability({
  entityName,
  orgName,
  skip,
}: UseAvailabilityParams): {
  loading: boolean;
  isEntityAvailable: boolean;
  isOrgAvailable: boolean;
} {
  // do not cache, gorilla currently has non-existent entities return
  // a static id, which seems to break apollo caching, possibly by making
  // it think all non-existent entities are the same
  const {loading: entityLoading, data: entityData} = useAvailableTeamQuery({
    variables: {
      teamName: entityName ?? '',
    },
    skip: !entityName || skip === true,
    fetchPolicy: 'no-cache',
  });

  const {loading: entityOrgNameLoading, data: entityOrgNameData} =
    useAvailableTeamQuery({
      variables: {
        teamName: orgName != null ? slugFormat(orgName) : '',
      },
      skip: !orgName || skip === true || envIsLocal, // skip on server since there's no orgs to check against,
      fetchPolicy: 'no-cache',
    });

  const {loading: orgLoading, data: orgData} = useAvailableOrgQuery({
    variables: {
      name: orgName ?? '',
    },
    skip: !orgName || skip === true || envIsLocal, // skip on server since there's no orgs to check against
    fetchPolicy: 'no-cache',
    // https://github.com/apollographql/apollo-client/issues/11328 Fix for tests
    // which type quickly and cause a lack of re-renders when this query is complete
    // because the results are the same...
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading: entityLoading || orgLoading,
    isEntityAvailable:
      !!entityName && !entityLoading && entityData?.entity?.available !== false,
    isOrgAvailable:
      !!orgName &&
      !orgLoading &&
      orgData?.organization?.available !== false &&
      !entityOrgNameLoading &&
      entityOrgNameData?.entity?.available !== false,
  };
}
