import _ from 'lodash';
import {toast} from 'react-toastify';
import {createAction} from 'typesafe-actions';

import {
  PanelBankConfigState,
  PanelBankSettings,
  SectionPanelSorting,
} from '../../../components/PanelBank/types';
import {getDefaultPanelSectionConfig} from '../../../util/panelbankConfigs';
import {
  findNextPanelLoc,
  GRID_COLUMN_COUNT,
  GRID_ITEM_DEFAULT_HEIGHT,
  GRID_ITEM_DEFAULT_WIDTH,
} from '../../../util/panelbankGrid';
import * as PanelsUtil from '../../../util/panels';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as Normalize from '../normalize';
import * as PanelTypes from '../panel/types';
import * as PanelBankConfigActionsInternal from '../panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import * as PanelSettings from '../panelSettings/types';
import {
  deleteParts,
  removeHistoryForObject,
  ViewReducerState,
} from '../reducerSupport';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Types from './types';

interface Ordering {
  seqNum: number;
}

export const diffAndInitPanels = createAction(
  '@view/panelBankConfig/diffAndInitPanels',
  action =>
    (
      ref: Types.Ref,
      expectedPanels: ExpectedPanelsState,
      /**
       * Pass workspaceSettings ref instead of actual shouldAutoGeneratePanels value
       * because we don't want to trigger this action whenever the setting is updated.
       * We just want to read the setting value when this action *is* triggered.
       */
      workspaceSettingsRef: WorkspaceSettingsTypes.Ref
    ) =>
      action({ref, expectedPanels, workspaceSettingsRef})
);

export const putSection = createAction(
  '@view/panelBankConfig/putSection',
  action =>
    (
      ref: Types.Ref,
      sectionRef: PanelBankSectionConfigTypes.Ref,
      sectionNorm: PanelBankSectionConfigTypes.PanelBankSectionConfigNormalized,
      prevIndex: number,
      panelsToCreate: Array<PanelTypes.Panel & Ordering>,
      hiddenPanelsToMove: Array<PanelTypes.Ref & Ordering>,
      localPanelSettings: PanelSettings.PanelSettings,
      localPanelSettingsRef: PanelSettings.Ref
    ) =>
      action({
        ref,
        sectionRef,
        sectionNorm,
        prevIndex,
        panelsToCreate,
        hiddenPanelsToMove,
        localPanelSettings,
        localPanelSettingsRef,
      })
);

export const putPanel = createAction(
  '@view/panelBankConfig/putPanel',
  action =>
    (
      panelRef: PanelTypes.Ref,
      prevIndex: number,
      sectionRef: PanelBankSectionConfigTypes.Ref, // the section where we're putting the panel
      panel?: PanelTypes.Panel,
      panelBankConfigRef?: Types.Ref
    ) =>
      action({panelBankConfigRef, panel, panelRef, prevIndex, sectionRef})
);

// This completely overwrites panelBankConfig.sections
export const setAllSections = createAction(
  '@view/panelBankConfig/setAllSections',
  action =>
    (
      ref: Types.Ref,
      newSectionsNormalized: PanelBankSectionConfigTypes.PanelBankSectionConfigNormalized[]
    ) =>
      action({ref, newSectionsNormalized})
);

export const undoMovePanelToNewSection = createAction(
  '@view/panelBankConfig/undoMovePanelToNewSection',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref,
      fromSectionRef: PanelBankSectionConfigTypes.Ref,
      toSectionRef: PanelBankSectionConfigTypes.Ref,
      toIndex: number,
      newSectionName: string
    ) =>
      action({
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
        newSectionName,
      })
);

export const undoUpdateSettingsAndSortPanels = createAction(
  '@view/panelBankConfig/undoUpdateSettingsAndSortPanels',
  action =>
    (
      ref: Types.Ref,
      panelBankSettings: PanelBankSettings,
      sectionRefs: PanelBankSectionConfigTypes.Ref[],
      panelRefs: PanelTypes.Ref[][]
    ) =>
      action({
        ref,
        panelBankSettings,
        sectionRefs,
        panelRefs,
      })
);

/* --- REDUCER HELPERS --- */

// Add a section in PanelBank
export const addPanelBankSectionInternal = (
  state: ViewReducerState,
  ref: Types.Ref,
  sectionRef: PanelBankSectionConfigTypes.Ref, // the existing section that you're inserting before or after
  options: {
    addAfter?: boolean;
    newSectionName?: string;
  }
) => {
  const {addAfter, newSectionName} = options;
  const newSectionRef = Normalize.addObj(
    state.parts,
    'panel-bank-section-config',
    ref.viewID,
    {...getDefaultPanelSectionConfig({name: newSectionName}), isOpen: true}
  );
  const sectionIndex = state.parts[ref.type][ref.id].sectionRefs.findIndex(
    sr => sr.id === sectionRef.id
  );
  // If the user adds a section, make sure we set state to ready, so
  // that the newly added section is still there upon reload.
  state.parts[ref.type][ref.id].state = PanelBankConfigState.Ready;
  state.parts[ref.type][ref.id].sectionRefs.splice(
    addAfter ? sectionIndex + 1 : sectionIndex,
    0,
    newSectionRef
  );
  toast('Section added');
  return newSectionRef;
};

export const deletePanelBankSectionInternal = (
  state: ViewReducerState,
  ref: Types.Ref,
  sectionRef: PanelBankSectionConfigTypes.Ref // the section that you're deleting
) => {
  const panelRefs = state.parts[sectionRef.type][sectionRef.id].panelRefs;
  const panelsWithRefs = Normalize.denormalize(
    state.parts,
    sectionRef
  ).panels.map((p, i) => ({...p, ref: panelRefs[i]}));
  // Before deleting the section, we need to:
  // a) Move all basic panels (single key) to the 'Hidden Panels' section
  // b) Delete all other panels
  // NOTE: this assumes Hidden Panels is the last section
  const hiddenSectionRef =
    state.parts[ref.type][ref.id].sectionRefs.slice(-1)[0];
  const hiddenSection = Normalize.denormalize(state.parts, hiddenSectionRef);
  const existingHiddenKeys = hiddenSection.panels.map(PanelsUtil.getKey);
  const panelRefsToHide: Array<PanelTypes.Ref & {seqNum: number}> = [];
  const panelRefsToDelete: Array<PanelTypes.Ref & {seqNum: number}> = [];
  panelsWithRefs.forEach((p, i) => {
    const key = PanelsUtil.getKey(p);
    if (key && !_.includes(existingHiddenKeys, key)) {
      panelRefsToHide.push({...p.ref, seqNum: i});
    } else {
      panelRefsToDelete.push({...p.ref, seqNum: i});
    }
  });
  // Hide panels
  state.parts[hiddenSectionRef.type][hiddenSectionRef.id].panelRefs = [
    ...panelRefsToHide,
    ...state.parts[hiddenSectionRef.type][hiddenSectionRef.id].panelRefs,
  ];
  // Remove newly-hidden panelRefs from the section
  state.parts[sectionRef.type][sectionRef.id].panelRefs = panelRefsToDelete;

  // get localpanelsettingsref
  const localPanelSettingsRef =
    state.parts[sectionRef.type][sectionRef.id].localPanelSettingsRef;
  const localPanelSettings = Normalize.denormalize(
    state.parts,
    localPanelSettingsRef
  );

  // Remove the sectionRef from panelBankConfig
  const sectionIndex = state.parts[ref.type][ref.id].sectionRefs.findIndex(
    s => s.id === sectionRef.id
  );
  state.parts[ref.type][ref.id].sectionRefs.splice(sectionIndex, 1);

  // Set up inverse action before we delete everything.
  const sectionNorm = state.parts[sectionRef.type][sectionRef.id];
  const deletedPanelsWhole = panelRefsToDelete.map(pr => ({
    ...state.parts[pr.type][pr.id],
    seqNum: pr.seqNum,
  }));
  const undoAction = PanelBankConfigActionsInternal.putSection(
    ref,
    sectionRef,
    sectionNorm,
    sectionIndex,
    deletedPanelsWhole,
    panelRefsToHide,
    localPanelSettings,
    localPanelSettingsRef
  );

  // Delete the section
  removeHistoryForObject(state, sectionRef);
  deleteParts(state, sectionRef);
  return undoAction;
};

// Move a panel in PanelBank
export const movePanelInternal = (
  state: ViewReducerState,
  args: {
    ref: Types.Ref;
    panelRef: PanelTypes.Ref;
    fromSectionRef: PanelBankSectionConfigTypes.Ref;
    toSectionRef: PanelBankSectionConfigTypes.Ref;
    toIndex: number;
    inactivePanelRefIDs?: Set<string>;
  }
) => {
  const {
    ref,
    fromSectionRef,
    toSectionRef,
    panelRef,
    toIndex,
    inactivePanelRefIDs = new Set(),
  } = args;

  // set manual sorting to section chart was moved to
  // TODO: improve this by checking if the panel was placed
  // in a position respecting or automatically placing it in
  // the sorted position and showing a tool tip
  state.parts[toSectionRef.type][fromSectionRef.id].sorted =
    SectionPanelSorting.Manual;

  const panelRefs =
    state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs;
  // The panel's index in the fromSection
  const fromIndex = panelRefs.findIndex(pRef => pRef.id === panelRef.id);
  // Remove the panelRef from the old position
  state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs.splice(
    fromIndex,
    1
  );
  // Insert the panelRef in the new position
  // HAX: The toIndex provided does not account for inactive (hidden) panels.
  // We need to splice it to the index as if hidden panels are shown.
  let toIndexOffset = 0;
  let activePanelI = 0;
  for (const pr of state.parts[toSectionRef.type][toSectionRef.id].panelRefs) {
    if (activePanelI >= toIndex) {
      break;
    }
    if (inactivePanelRefIDs.has(pr.id)) {
      toIndexOffset++;
    } else {
      activePanelI++;
    }
  }
  state.parts[toSectionRef.type][toSectionRef.id].panelRefs.splice(
    toIndex + toIndexOffset,
    0,
    panelRef
  );
  // If we're moving the panel to a Grid section, we need to add layout to the panel
  if (state.parts[toSectionRef.type][toSectionRef.id].type === 'grid') {
    state.parts[panelRef.type][panelRef.id].layout = {
      ...findNextPanelLoc(
        Normalize.denormalize(state.parts, toSectionRef)
          .panels.map(p => p.layout)
          .filter(p => p),
        GRID_COLUMN_COUNT,
        GRID_ITEM_DEFAULT_WIDTH
      ),
      w: GRID_ITEM_DEFAULT_WIDTH,
      h: GRID_ITEM_DEFAULT_HEIGHT,
    };
  }

  // Save the toSection as the new defaultMoveToSectionName
  const toSectionName = state.parts[toSectionRef.type][toSectionRef.id].name;
  const prevSettings = state.parts[ref.type][ref.id].settings;
  state.parts[ref.type][ref.id].settings = {
    ...prevSettings,
    defaultMoveToSectionName: toSectionName,
  };
};
