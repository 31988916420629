import Drawer from '@material-ui/core/Drawer';
import {toast} from '@wandb/weave/common/components/elements/Toast';
import {Button} from '@wandb/weave/components/Button';
import {TextField} from '@wandb/weave/components/Form/TextField';
import {Icon} from '@wandb/weave/components/Icon';
import {ExecutionResult} from 'graphql';
import * as Prism from 'prismjs';
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useHistory} from 'react-router';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Loader} from 'semantic-ui-react';

import {
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../components/Banner';
import {BannerVariant} from '../../../components/Banner/Banner.styles';
import {FixedSizeTextArea} from '../../../components/Form/FixedSizeTextArea';
import {ResizableDrawer} from '../../../components/Launch/ResizableDrawer';
import {
  PROJECTS_BY_TEAM_QUERY,
  PROJECTS_BY_USER_QUERY,
} from '../../../components/PaginatedList/projectQuery.query';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {
  CreateProjectMembersMutation,
  DeleteProjectMembersMutation,
  useAllProjectNamesQuery,
  useCreateProjectMembersMutation,
  useDeleteProjectMembersMutation,
  useProjectMembersQuery,
  UserAccountType,
  useRenameProjectMutation,
  useUpsertModelMutation,
  WorkflowsAdminType,
} from '../../../generated/graphql';
import {PROJECT_MEMBERS_QUERY} from '../../../graphql/projects';
import {
  TEAM_PAGE_PROJECTS_QUERY,
  TEAM_PAGE_QUERY,
} from '../../../graphql/teamPage';
import {
  PROJECT_PAGE_QUERY,
  useProjectPageQuery,
} from '../../../state/graphql/projectPageQuery';
import {useViewer} from '../../../state/viewer/hooks';
import {Team} from '../../../types/graphql';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../../util/errors';
import {navigateTo} from '../../../util/history';
import {safeLocalStorage} from '../../../util/localStorage';
import {trackPageViewed} from '../../../util/navigation';
import {
  AccessOption,
  AccessOptions,
  getProjectAccess,
} from '../../../util/permissions';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import * as C from './CommonDrawer.styles';
import * as S from './CreateProjectDrawer.styles';
import {ProjectAccessOptions} from './ProjectAccessOptions';
import {ProjectOwnerOptions} from './ProjectOwnerOptions';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {TeamOptions} from './TeamOptions';
import {useAccountTeamsList} from './useAccountTeamsList';
import {useHandleScroll} from './useHandleScroll';
import {useIsRestrictedAccessOptionEnabled} from './useIsRestrictedAccessOptionEnabled';
import {UserInviteOptions, UserRoleItem} from './UserInviteOptions';

type CreateProjectDrawerProps = {
  open: boolean;
  entityName?: string;
  editProjectName?: string;
  toggleProjectDrawer: () => void;
};

const CREATE_PROJECT_FORM_LOCAL_STORAGE_KEY = 'create_project';
const FRIENDLY_ERROR_MESSAGES = ['reserved name'];
// Creating a set of protected project names to disallow their manual creation by the user
// On the backend, a list of reserved projects exists in query.go to disallow any means of
// their creation. In the future, this hierarchy needs to be managed better.
export const PROTECTED_PROJECT_NAMES = new Set(['model-registry']);

export interface TeamOption {
  value: string;
  label: string;
}

const DEFAULT_ACCESS_OPTIONS = [AccessOption.Private];

// Export dumb component for testing purposes
export const CreateProjectDrawerComp = ({
  open,
  entityName,
  editProjectName,
  toggleProjectDrawer,
}: CreateProjectDrawerProps) => {
  useEffect(() => {
    trackPageViewed('Create Project'); // for page view tracking
  }, []);

  useEffect(() => {
    Prism.highlightAll();
  });

  const viewer = useViewer();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [projectName, setProjectName] = useState<string>(editProjectName ?? '');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [selectedTeamOwner, setSelectedTeamOwner] = useState<string>(
    entityName ?? ''
  );
  const [selectedProjectAccess, setSelectedProjectAccess] =
    useState<AccessOption>();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedProjectOwner, setSelectedProjectOwner] = useState<string>();

  const isEditingProject = editProjectName != null;

  const {data: allProjects, refetch: refetchAllProjects} =
    useAllProjectNamesQuery({
      // Refetch this component's query so we have an updated list to check
      // against when user tries to create another project for same entity
      fetchPolicy: 'cache-and-network',
      variables: {
        entityName: selectedTeamOwner,
        first: 1000,
      },
      skip: !selectedTeamOwner,
    });

  const projectQuery = useProjectPageQuery(
    {
      entityName: selectedTeamOwner,
      projectName: editProjectName ?? '',
    },
    {
      skip: !isEditingProject,
    }
  );

  const projects = useMemo(
    () => allProjects?.projects?.edges.map(p => p.node) ?? [],
    [allProjects]
  );
  const editProjectData =
    isEditingProject && !projectQuery.loading
      ? projectQuery.data?.project
      : null;

  const {data: projectMembers, refetch: refetchProjectMembers} =
    useProjectMembersQuery({
      variables: {
        entityName: selectedTeamOwner,
        projectName,
      },
      skip: !isEditingProject,
    });

  const [renameProjectMutation] = useRenameProjectMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    refetchQueries: [
      {
        // this is necessary to prevent the user from seeing a 404. Unfortunately we have to refetch all these queries
        // because when the project name changes, the GraphQL id also changes, since it is based on the project name
        query: PROJECT_PAGE_QUERY,
        variables: {entityName, projectName},
      },
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName},
      },
    ],
  });

  const [upsertProjectMutation] = useUpsertModelMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    refetchQueries: [
      // Ideally we should try to just have one query to refetch.
      {
        query: PROJECTS_BY_USER_QUERY,
        variables: {userName: viewer?.username ?? ''},
      },
      {
        // Query handles updating Team page Projects tab
        query: PROJECTS_BY_TEAM_QUERY,
        variables: {entityName: selectedTeamOwner},
      },
      {
        // Query handles updating Team Overview tab when project is added
        query: TEAM_PAGE_PROJECTS_QUERY,
        variables: {entityName: selectedTeamOwner},
      },
      {
        // Query used to create project prompt on team page and project cards
        // Handles re-rendering prompt if new project is created
        query: TEAM_PAGE_QUERY,
        variables: {
          entityName: selectedTeamOwner,
          workflowsAdminType: WorkflowsAdminType.Model,
        },
      },
    ],
  });

  const [createProjectMembers] = useCreateProjectMembersMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    refetchQueries: [
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName: selectedTeamOwner},
      },
    ],
  });

  const [deleteProjectMembers] = useDeleteProjectMembersMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    refetchQueries: [
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName: selectedTeamOwner},
      },
    ],
  });

  const currentProjectAccess = editProjectData
    ? getProjectAccess(editProjectData.access as AccessOptions)
    : null;
  const getIsProjectNameTaken = useCallback(() => {
    const existingProjectNames = new Set(
      projects.map(p => p!.name).filter(p => p !== editProjectData?.name)
    );
    return existingProjectNames.has(projectName);
  }, [projectName, projects, editProjectData]);

  const getIsProtectedProjectName = PROTECTED_PROJECT_NAMES.has(projectName);
  const getIsSubmitDisabled = useCallback(() => {
    if (
      getIsProjectNameTaken() ||
      getIsProtectedProjectName ||
      selectedTeamOwner === '' ||
      !selectedProjectAccess ||
      (isEditingProject && !selectedProjectOwner)
    ) {
      return true;
    }
    return !projectName || isSubmitting;
  }, [
    getIsProjectNameTaken,
    projectName,
    isSubmitting,
    getIsProtectedProjectName,
    selectedTeamOwner,
    selectedProjectAccess,
    selectedProjectOwner,
    isEditingProject,
  ]);

  const {accountTeams, loading: accountTeamsLoading} = useAccountTeamsList();
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);

  const isPersonalEntity =
    selectedTeamOwner === viewer?.username ||
    (enableAccountSelector &&
      selectedAccount?.accountType === AccountType.Personal);

  const teams: Team[] | undefined = useMemo(() => {
    const teamsList = accountTeams?.edges.map(e => e.node as Team);
    const personalEntity = viewer?.teams?.edges?.find(
      t => t.node.name === viewer?.username
    )?.node as unknown as Team;

    if (enableAccountSelector && isPersonalEntity) {
      return [personalEntity];
    }

    if (accountTeamsLoading) {
      return [];
    }

    if (enableAccountSelector) {
      return teamsList;
    }

    return personalEntity ? teamsList.concat([personalEntity]) : teamsList;
  }, [
    enableAccountSelector,
    accountTeams,
    accountTeamsLoading,
    viewer?.teams,
    isPersonalEntity,
    viewer?.username,
  ]);

  const teamOptions: TeamOption[] | undefined = useMemo(() => {
    if (isEditingProject) {
      return;
    }
    return teams
      ?.filter(
        team =>
          team != null &&
          (!viewer?.personalEntityDisabled || team.name !== viewer?.username)
      )
      .map(t => ({
        value: t.name,
        label: t.name,
      }));
  }, [
    teams,
    isEditingProject,
    viewer?.username,
    viewer?.personalEntityDisabled,
  ]);

  const selectedTeam = useMemo(
    () => teams?.find(t => t.name === selectedTeamOwner) ?? null,
    [teams, selectedTeamOwner]
  );

  const teamMembers = useMemo(
    () => selectedTeam?.members?.filter(m => !m.pending) ?? [],
    [selectedTeam]
  );
  const viewerMember = teamMembers.find(m => m.id === viewer?.id);
  const viewerIsProjectOwner = editProjectData?.user?.id === viewer?.id;

  useEffect(() => {
    if (isEditingProject) {
      setProjectName(editProjectName ?? '');
      setProjectDescription(editProjectData?.description ?? '');
    } else {
      setSelectedTeamOwner(entityName ?? '');
      setProjectName('');
      setProjectDescription('');
    }
  }, [
    isEditingProject,
    editProjectName,
    editProjectData,
    selectedAccount,
    entityName,
  ]);

  const {isRestrictedOptionEnabled, loading: accessOptionsLoading} =
    useIsRestrictedAccessOptionEnabled(selectedTeam?.organizationId);
  const accessOptions = useMemo(() => {
    const projectAccessOptions: AccessOption[] = [];

    projectAccessOptions.push(...DEFAULT_ACCESS_OPTIONS);

    if (
      (isPersonalEntity || !selectedTeam?.privateOnly) &&
      // restricted projects can only be updated to private
      currentProjectAccess !== AccessOption.Restricted
    ) {
      projectAccessOptions.push(AccessOption.Public, AccessOption.Open);
    }

    projectAccessOptions.push(AccessOption.Restricted);

    return projectAccessOptions;
  }, [selectedTeam, isPersonalEntity, currentProjectAccess]);

  useEffect(() => {
    if (teamOptions == null || selectedTeamOwner !== '') {
      return;
    }
    if (teamOptions.length === 1) {
      setSelectedTeamOwner(teamOptions[0].value);
    }
  }, [teamOptions, selectedTeamOwner]);

  useEffect(() => {
    if (!selectedProjectAccess && currentProjectAccess) {
      setSelectedProjectAccess(currentProjectAccess ?? AccessOption.Private);
    } else {
      if (!accessOptionsLoading && accessOptions?.length === 1) {
        setSelectedProjectAccess(accessOptions[0]);
      } else if (
        selectedProjectAccess &&
        !accessOptions.includes(selectedProjectAccess)
      ) {
        setSelectedProjectAccess(AccessOption.Private);
      }
    }
  }, [
    accessOptions,
    accessOptionsLoading,
    editProjectData,
    selectedProjectAccess,
    currentProjectAccess,
  ]);

  useEffect(() => {
    setSelectedUserIds(
      projectMembers?.project?.members
        ?.map(m => m.id)
        .filter(id => id !== viewer?.id) ?? []
    );
  }, [projectMembers, viewer?.id]);

  const projectOwnerMember = teamMembers.find(
    m => m.id && m.id === editProjectData?.user?.id
  );

  const projectOwnerOptions = useMemo(() => {
    const humanMembers =
      teamMembers.filter(m => m.accountType !== UserAccountType.Service) ?? [];
    if (selectedProjectAccess === AccessOption.Restricted) {
      const optionsFromSelected = humanMembers.filter(
        m => m.id && selectedUserIds.includes(m.id)
      );
      if (viewerMember) {
        optionsFromSelected.push(viewerMember);
      }
      return optionsFromSelected;
    }
    return humanMembers;
  }, [selectedProjectAccess, viewerMember, teamMembers, selectedUserIds]);

  useEffect(() => {
    if (isPersonalEntity) {
      setSelectedProjectOwner(editProjectData?.user?.id);
    } else if (projectOwnerMember?.id) {
      setSelectedProjectOwner(projectOwnerMember.id);
    }
  }, [projectOwnerMember, isPersonalEntity, editProjectData]);

  const onCreateClick = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const upsertModelRes = await upsertProjectMutation({
        variables: {
          description: projectDescription,
          access: selectedProjectAccess,
          name: projectName,
          entityName: selectedTeamOwner,
        },
      });
      const inserted = upsertModelRes.data?.upsertModel?.inserted;
      const project = upsertModelRes.data?.upsertModel?.project;
      if (!inserted || !project?.id) {
        setIsSubmitting(false);
        toast('Error creating project. Please refresh and try again.');
        return;
      }

      if (selectedUserIds.length > 0) {
        const createProjectMembersRes = await createProjectMembers({
          variables: {
            projectId: project.id,
            userIds: selectedUserIds,
          },
        });
        if (!createProjectMembersRes.data?.createProjectMembers?.success) {
          setIsSubmitting(false);
          toast('Error adding members to project.');
          return;
        }
      }

      // Remove saved form data after successful creation of project
      safeLocalStorage.removeItem(CREATE_PROJECT_FORM_LOCAL_STORAGE_KEY);

      setIsSubmitting(false);
      toggleProjectDrawer();

      const projectPath: string = `/${project?.entityName}/${project?.name}`;
      navigateTo({pathname: projectPath});
    } catch (err) {
      const errMsg = extractErrorMessageFromApolloError(err);
      console.error(errMsg, err);
      if (
        errMsg != null &&
        FRIENDLY_ERROR_MESSAGES.some(x => errMsg.includes(x))
      ) {
        toast(`Error creating project: ${errMsg}`);
      } else {
        toast('Error creating project');
      }
      setIsSubmitting(false);
    }
  }, [
    upsertProjectMutation,
    projectDescription,
    selectedProjectAccess,
    projectName,
    selectedTeamOwner,
    selectedUserIds,
    createProjectMembers,
    toggleProjectDrawer,
  ]);

  const onEditClick = useCallback(async () => {
    setIsSubmitting(true);

    const currentProjectMembers =
      projectMembers?.project?.members?.map(m => m.id) ?? [];

    const usersToAdd: string[] =
      selectedProjectAccess === AccessOption.Restricted
        ? selectedUserIds.filter(u => !currentProjectMembers?.includes(u))
        : [];

    const usersToRemove =
      selectedProjectAccess === AccessOption.Restricted
        ? currentProjectMembers.filter(
            m =>
              !selectedUserIds.includes(m) &&
              viewer?.id !== m &&
              selectedProjectOwner !== m
          )
        : [];

    const isRenamed =
      projectName !== editProjectData?.name && editProjectData?.entityName;

    try {
      let projectId = editProjectData?.id;
      if (isRenamed) {
        const renameProjectRes = await renameProjectMutation({
          variables: {
            entityName: editProjectData.entityName,
            oldProjectName: editProjectData.name,
            newProjectName: projectName,
          },
        });
        projectId = renameProjectRes.data?.renameProject?.project?.id;
      }
      if (
        editProjectData?.description !== projectDescription ||
        currentProjectAccess !== selectedProjectAccess ||
        editProjectData?.user?.id !== selectedProjectOwner
      ) {
        await upsertProjectMutation({
          variables: {
            description: projectDescription,
            access: selectedProjectAccess,
            name: projectName,
            owner: selectedProjectOwner,
            entityName: editProjectData?.entityName,
          },
        });
        // refetch project members if switching between restricted projects
        if (
          currentProjectAccess === AccessOption.Restricted ||
          selectedProjectAccess === AccessOption.Restricted
        ) {
          refetchProjectMembers();
        }
      }

      const promises: Array<
        Promise<
          ExecutionResult<
            CreateProjectMembersMutation | DeleteProjectMembersMutation
          >
        >
      > = [];
      if (usersToAdd.length > 0 && projectId) {
        promises.push(
          createProjectMembers({
            variables: {
              projectId,
              userIds: usersToAdd,
            },
          })
        );
      }
      if (usersToRemove.length > 0 && projectId) {
        promises.push(
          deleteProjectMembers({
            variables: {
              projectId,
              userIds: usersToRemove,
            },
          })
        );
      }
      await Promise.all(promises);

      // Remove saved form data after successful creation of project
      safeLocalStorage.removeItem(CREATE_PROJECT_FORM_LOCAL_STORAGE_KEY);

      setIsSubmitting(false);
      toggleProjectDrawer();

      if (isRenamed) {
        refetchAllProjects();
        history.push(`/${editProjectData.entityName}/${projectName}/overview`);
      }
    } catch (err) {
      const errMsg = extractErrorMessageFromApolloError(err);
      console.error(errMsg, err);
      if (
        errMsg != null &&
        FRIENDLY_ERROR_MESSAGES.some(x => errMsg.includes(x))
      ) {
        toast(`Error updating project: ${errMsg}`);
      } else {
        toast(`Error updating project`);
      }
      setIsSubmitting(false);
    }
  }, [
    upsertProjectMutation,
    renameProjectMutation,
    projectDescription,
    selectedProjectAccess,
    currentProjectAccess,
    projectName,
    selectedUserIds,
    createProjectMembers,
    deleteProjectMembers,
    editProjectData,
    viewer?.id,
    toggleProjectDrawer,
    history,
    projectMembers,
    refetchAllProjects,
    selectedProjectOwner,
    refetchProjectMembers,
  ]);

  const handleProjectNameInputChange = useCallback((value: string) => {
    const invalidUrlChars = '/\\#?%:'.split('');
    if (invalidUrlChars.some(c => value.includes(c))) {
      return;
    }
    setProjectName(value);
  }, []);
  const {scrolled} = useHandleScroll();
  const [width, setWidth] = useState(500);
  const onClose = (e: React.MouseEvent) => {
    toggleProjectDrawer();
  };

  if (viewer == null) {
    return <Loader name="new-project-no-viewer-loader" />;
  }

  const updatingToRestrictedAccess =
    currentProjectAccess &&
    currentProjectAccess !== AccessOption.Restricted &&
    selectedProjectAccess === AccessOption.Restricted;
  const updatingFromRestrictedAccess =
    currentProjectAccess &&
    currentProjectAccess === AccessOption.Restricted &&
    selectedProjectAccess !== AccessOption.Restricted;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <ResizableDrawer startWidth={width} minWidth={400} onSetWidth={setWidth}>
        <C.DrawerContents scrolled={scrolled}>
          <C.TopPanel>
            <C.HeaderRow>
              <C.Header>
                {isEditingProject ? 'Edit project' : 'Create a new project'}
              </C.Header>
              <C.Close>
                <Button
                  onClick={onClose}
                  icon="close"
                  size="large"
                  variant="ghost"
                />
              </C.Close>
            </C.HeaderRow>
            <C.UnderLine />
            {!isEditingProject && selectedAccount && enableAccountSelector && (
              <S.Description>
                This project will be created inside your{' '}
                {selectedAccount.accountType.toLowerCase() + ' '}account.
              </S.Description>
            )}
            {!isEditingProject && (
              <S.FormRow>
                <TeamOptions
                  selectedTeam={
                    selectedTeamOwner
                      ? {
                          value: selectedTeamOwner,
                          label: selectedTeamOwner,
                        }
                      : null
                  }
                  setSelectedTeam={setSelectedTeamOwner}
                  teamOptions={teamOptions}
                  isTeamProject={!isPersonalEntity}
                />
              </S.FormRow>
            )}
            <S.FormRow>
              <C.Label>Name</C.Label>
              <TextField
                placeholder="Give your project a name"
                value={projectName}
                dataTest="project-drawer-name"
                onChange={(value: string) =>
                  handleProjectNameInputChange(value)
                }
              />
            </S.FormRow>
            {editProjectData && editProjectData.name !== projectName && (
              <CreateProjectBanner bannerType="warning">
                <BannerBoldText>
                  Renaming breaks all existing links to your projects, runs, and
                  reports.{' '}
                </BannerBoldText>
                You will need to update any links you've shared as well as calls
                to wandb.init(project="{editProjectData.name}" in your code.
              </CreateProjectBanner>
            )}
            {projectName !== '' && getIsProjectNameTaken() && (
              <CreateProjectBanner bannerType="error">
                <BannerBoldText>
                  A project named "{projectName}" already exists.
                </BannerBoldText>{' '}
                Try another name, or{' '}
                <S.ProjectLink to={`/${selectedTeamOwner}/${projectName}`}>
                  visit your existing project.
                </S.ProjectLink>
              </CreateProjectBanner>
            )}
            {projectName !== '' && getIsProtectedProjectName && (
              <CreateProjectBanner bannerType="error">
                <BannerBoldText>
                  Cannot create project with protected name, "{projectName}
                  ".{' '}
                </BannerBoldText>
                Try another name.
              </CreateProjectBanner>
            )}
            <S.FormRow>
              <C.Label>Project visibility</C.Label>
              <ProjectAccessOptions
                selectedProjectAccess={selectedProjectAccess}
                setSelectedProjectAccess={setSelectedProjectAccess}
                projectAccessOptions={accessOptions}
                isRestrictedProjectEnabled={isRestrictedOptionEnabled ?? false}
                isTeamProject={!isPersonalEntity}
              />
            </S.FormRow>
            {updatingToRestrictedAccess && (
              <CreateProjectBanner bannerType="warning">
                <BannerBoldText>
                  Changing project visibility to Restricted.{' '}
                </BannerBoldText>
                All uninvited team members will lose access to this project.
              </CreateProjectBanner>
            )}
            {updatingFromRestrictedAccess && (
              <CreateProjectBanner bannerType="warning">
                <BannerBoldText>
                  Changing project visibility to Team.{' '}
                </BannerBoldText>
                This project will be accessible by all members of{' '}
                {editProjectData?.entityName} team.
              </CreateProjectBanner>
            )}
            {selectedProjectAccess === AccessOption.Restricted && (
              <S.FormRow>
                <C.Label>Invite team members</C.Label>
                <UserInviteOptions
                  selectedUsers={selectedUserIds}
                  setSelectedUsers={setSelectedUserIds}
                  users={
                    teamMembers.filter(
                      m => m.id !== editProjectData?.user?.id
                    ) ?? []
                  }
                />
                {viewerMember && (
                  <UserRoleItem
                    entityName={entityName ?? ''}
                    user={viewerMember}
                    isOwner={viewerIsProjectOwner}
                  />
                )}
                {projectOwnerMember && !viewerIsProjectOwner && (
                  <UserRoleItem
                    user={projectOwnerMember}
                    isOwner={true}
                    entityName={entityName ?? ''}
                  />
                )}
                {selectedUserIds.map(u => {
                  const user = teamMembers.find(
                    m => m.id === u && m.id !== projectOwnerMember?.id
                  );
                  return (
                    user && (
                      <UserRoleItem
                        key={user.id}
                        user={user}
                        entityName={entityName ?? ''}
                      />
                    )
                  );
                })}
              </S.FormRow>
            )}
            {isEditingProject && !isPersonalEntity && !projectOwnerMember && (
              <>
                <S.FormRow>
                  <C.Label>Change owner</C.Label>
                  <ProjectOwnerOptions
                    selectedOwner={selectedProjectOwner ?? ''}
                    setSelectedOwner={setSelectedProjectOwner}
                    users={projectOwnerOptions ?? []}
                  />
                </S.FormRow>
                {!selectedProjectOwner && (
                  <CreateProjectBanner bannerType="warning">
                    The project owner has been removed as a member and no longer
                    has access to the project. Please select a new owner.
                  </CreateProjectBanner>
                )}
              </>
            )}
            <S.Description>
              <S.EditRow>
                <C.Label>Description</C.Label>
                <C.OptionalLabel>(optional)</C.OptionalLabel>
              </S.EditRow>
              <FixedSizeTextArea
                placeholder=""
                value={projectDescription}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  setProjectDescription(event.target.value)
                }
              />
            </S.Description>
          </C.TopPanel>
          <Button
            className="w-full"
            variant="primary"
            size="large"
            data-test="create-project"
            disabled={getIsSubmitDisabled()}
            onClick={isEditingProject ? onEditClick : onCreateClick}>
            {isEditingProject ? 'Save' : 'Create project'}
          </Button>
        </C.DrawerContents>
      </ResizableDrawer>
    </Drawer>
  );
};

const CreateProjectDrawer = memo(CreateProjectDrawerComp);
export default CreateProjectDrawer;

const CreateProjectBanner: React.FC<{
  bannerType: BannerVariant;
  children: React.ReactNode;
}> = ({bannerType, children}) => {
  return (
    <S.BannerWrapper
      variant={bannerType}
      eventData={{location: 'create project home page drawer'}}>
      <BannerFlexWrapper>
        <div className="text-xs">
          <Icon name="warning" />
        </div>
        <BannerTextWrapper>
          <span>{children}</span>
        </BannerTextWrapper>
      </BannerFlexWrapper>
    </S.BannerWrapper>
  );
};
