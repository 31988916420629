import {ID} from '@wandb/weave/common/util/id';
import * as _ from 'lodash';

import YAML from '../../services/yaml';
import {JobDetails} from '../components/Launch/JobDetail/utilTypes';
import {JsonObject, LaunchSpec} from '../components/Launch/utilTypes';
import * as PanelParallelCoord from '../components/PanelParallelCoord';
import {useRunsData} from '../state/runs/hooks';
import * as Types from '../state/runs/types';
import {RunSetConfig} from '../state/views/runSet/types';
import {RunKeyInfo, RunKeyInfoInfo, RunKeyType} from '../types/run';
import * as Filter from './filters';
import * as Panels from './panels';
import * as Query from './queryts';
import * as Runs from './runs';
import {SelectionState} from './selectionmanager';

// If sweep.displayName is not set, it'll use the name field from sweep config json
export function getSweepDisplayName(
  sweep?: {
    name: string;
    displayName?: string | null;
    config?: string;
  } | null
): string {
  if (sweep == null) {
    return '';
  }
  if (sweep.displayName != null) {
    return sweep.displayName;
  }
  if (sweep.config != null) {
    const config = YAML.parse(sweep.config);
    const nameFromConfig = config.name?.value || config.name;
    if (nameFromConfig && typeof nameFromConfig === 'string') {
      return nameFromConfig;
    }
  }
  return sweep.name;
}

export type SweepState =
  | 'PENDING'
  | 'RUNNING'
  | 'PAUSED'
  | 'FINISHED'
  | 'CANCELED'
  | 'FLAPPING'
  | 'UNKNOWN';

export const descriptionBySweepState: {[s in SweepState]: string} = {
  PENDING:
    'The sweep is ready. Launch agents on your machines to start kicking off runs.',
  RUNNING: 'Any active agents will receive parameters to run.',
  PAUSED:
    'The sweep server is paused and not giving out any new jobs for agents to run.',
  CANCELED: 'The sweep was manually stopped.',
  FINISHED: 'The sweep completed all of the runs.',
  FLAPPING:
    'The sweep was paused automatically due to a high number of crashed runs. Please update your sweep with a working configuration before resuming.',
  UNKNOWN:
    'The sweep is in an invalid state. Please contact W&B support and let us know.',
};

export function sweepStateDescription(state: SweepState): string {
  return descriptionBySweepState[state];
}

export function sweepStateDisplayName(state: SweepState) {
  if (state === 'CANCELED') {
    // Fixing this is probably too much effort in the backend. Lol.
    return 'Stopped';
  } else if (state === 'FLAPPING') {
    // Makes state more user friendly
    return 'Crashed';
  }
  return state[0] + state.slice(1).toLowerCase();
}

export interface SweepYamlSettingsChoice {
  distribution: 'choice';
  values: string[];
}

export interface SweepYamlSettingsUniform {
  distribution: 'uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsIntUniform {
  distribution: 'int_uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsQUniform {
  distribution: 'q_uniform';
  min: number;
  max: number;
  q: number;
}

export interface SweepYamlSettingsLogUniform {
  distribution: 'log_uniform';
  min: number;
  max: number;
}

export interface SweepYamlSettingsQLogUniform {
  distribution: 'q_log_uniform';
  min: number;
  max: number;
  q: number;
}

export interface SweepYamlSettingsNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
}

export interface SweepYamlSettingsQNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
  q: number;
}

export interface SweepYamlSettingsLogNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
}

export interface SweepYamlSettingsQLogNormal {
  distribution: 'normal';
  mu: number;
  sigma: number;
  q: number;
}

export interface SweepYamlLeafParameters {
  distribution: string;
  value?: number | string;
  values?: Array<number | string>;
  min?: number;
  max?: number;
  mu?: number;
  sigma?: number;
  q?: number;
}

export interface SweepYamlNestedParameters {
  parameters: SweepYamlParameters;
}

type SweepYamlSettings = SweepYamlLeafParameters | SweepYamlNestedParameters;

export interface SweepYamlParameters {
  [name: string]: SweepYamlSettings;
}

export type SweepArgs = {
  sweep_id: string;
  queue: string;
  project: string;

  author?: string;
  job?: string;
  image_uri?: string;
};

export type SchedulerRunConfigOverrides = {
  settings?: Record<string, any>;
  scheduler?: LaunchSchedulerArgs;
  sweep_args?: SweepArgs;
};

export type LaunchSchedulerArgs = {
  job?: string;
  num_workers?: number;
  name?: string;
  resource?: string;
  resource_args?: any;

  settings?: any;
};

export interface SweepYamlConfig {
  program?: string;
  job?: string;
  image_uri?: string;
  method?: 'bayes' | 'grid' | 'random' | 'custom';
  metric?: {
    name: string;
    goal: 'minimize' | 'maximize';
  };
  parameters?: SweepYamlParameters;
  early_terminate?: {
    type?: 'hyperband';
    min_iter?: number;
    max_iter?: number;
    s?: number;
    eta?: number;
  };
  description?: string;

  // launch-sweep special scheduler params
  scheduler?: LaunchSchedulerArgs;
  launch?: LaunchSpec;
}

export const distributions = [
  'constant',
  'categorical',
  'uniform',
  'int_uniform',
  'q_uniform',
  'log_uniform',
  'q_log_uniform',
  'normal',
  'q_normal',
  'log_normal',
  'q_log_normal',
];

export const gridDistributions = ['constant', 'categorical'];

export const distributionToFields = {
  constant: ['value'],
  categorical: ['values'],
  uniform: ['min', 'max'],
  int_uniform: ['min', 'max'],
  q_uniform: ['min', 'max', 'q'],
  log_uniform: ['min', 'max'],
  q_log_uniform: ['min', 'max', 'q'],
  normal: ['mu', 'sigma'],
  q_normal: ['mu', 'sigma', 'q'],
  log_normal: ['mu', 'sigma'],
  q_log_normal: ['mu', 'sigma', 'q'],
};

export const methods = ['grid', 'random', 'bayes'];

function isInt(val: string) {
  const intRegex = /^-?\d+$/;
  if (!intRegex.test(val)) {
    return false;
  }

  const intVal = parseInt(val, 10);
  return parseFloat(val) === intVal && !isNaN(intVal);
}

export function getDefaultSweepSettingsFromKeyInfo(keyInfo: RunKeyInfoInfo) {
  if (keyInfo.majorType === 'number') {
    const numberStrings: string[] = Object.keys(keyInfo.valueCount);
    if (numberStrings.length === 0) {
      return undefined;
    }
    const allIntegers = numberStrings.every(value => isInt(value));

    const numbers = numberStrings.map(numberStr => Number(numberStr));
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);
    const distMin = min > 0 ? min / 2 : min * 2;
    const distMax = max > 0 ? max * 2 : max / 2;

    // This can only happen when all values are 0. In this case, don't include
    // this metric since we don't have a good guess for min/max values.
    if (distMin === distMax) {
      return undefined;
    }

    return {
      distribution: allIntegers ? 'int_uniform' : 'uniform',
      min: allIntegers ? Math.round(distMin) : distMin,
      max: allIntegers ? Math.round(distMax) : distMax,
    };
  } else if (keyInfo.majorType === 'boolean') {
    return {
      distribution: 'categorical',
      values: ['true', 'false'],
    };
  } else if (keyInfo.majorType === 'string') {
    return {
      distribution: 'categorical',
      values: Object.keys(keyInfo.valueCount),
    };
  } else {
    return undefined;
  }
}

export type Column = {
  accessor: string;
  log?: boolean;
};

function isSweepYamlNestedParameters(
  param: SweepYamlSettings
): param is SweepYamlNestedParameters {
  return 'parameters' in param;
}

/* Create a list of columns (one sweep param per) for the parallel coords plot. */
export function getColumnsFromSweepParams(
  parameters: SweepYamlParameters | undefined,
  prefix = '',
  depth = 0
): Column[] {
  if (parameters == null) {
    return [];
  }
  const columns: Column[] = [];
  for (const [param, paramSettings] of Object.entries(parameters)) {
    if (isSweepYamlNestedParameters(paramSettings)) {
      // This is a nested parameter, recurse one level deeper.
      // HACK: The first level of nesting depth requires everything to be behind "value" keys
      const delimiter = depth === 0 ? '.value.' : '.';
      columns.push(
        ...getColumnsFromSweepParams(
          paramSettings.parameters,
          `${prefix}${param}${delimiter}`,
          depth + 1
        )
      );
      continue;
    }
    // This is a leaf parameter, add it to the list of columns.
    if (paramSettings.value != null) {
      // This is a constant, skip it.
      continue;
    }
    columns.push({
      accessor: Runs.fixConfigKeyString(`config:${prefix}${param}`),
      // enable log scale if the distribution string contains 'log'
      log:
        paramSettings.distribution != null &&
        paramSettings.distribution.indexOf('log') !== -1,
    });
  }
  return columns;
}

// Gets the default panels for a sweep workspace, given a sweep config
export function getDefaultPanels(sweep: {
  name: string;
  config?: string;
}): Panels.PanelGroupConfig {
  if (sweep.config == null) {
    return [];
  }
  const config = YAML.parse(sweep.config) as SweepYamlConfig;
  const metric = config.metric;
  const parameters = config.parameters;

  const haveMetric = metric != null && metric.name != null;

  const panels: Panels.PanelGroupConfig = [];

  if (haveMetric && metric != null) {
    // Add a scatter plot panel with envelope for our metric
    const scatterPanel = Panels.layedOutPanel({
      __id__: ID(),
      viewType: 'Scatter Plot',
      layout: {x: 0, y: 0, w: 12, h: 10},
      config: {
        xAxis: 'run:createdAt',
        yAxis: 'summary:' + metric.name,
      },
    });
    if (metric.goal === 'maximize') {
      scatterPanel.config.showMaxYAxisLine = true;
    }
    if (metric.goal === 'minimize') {
      scatterPanel.config.showMinYAxisLine = true;
    }
    panels.push(scatterPanel);

    // Add a parameter importance panel targetting our metric
    const importancePanel = Panels.layedOutPanel({
      __id__: ID(),
      viewType: 'Parameter Importance',
      layout: {x: 12, y: 0, w: 12, h: 10},
      config: {
        targetKey: metric.name,
      },
    });
    panels.push(importancePanel);
  }

  ///// Make an automatic parallel cooradinates plot for this sweep
  // One column for each parameter
  const columns = getColumnsFromSweepParams(parameters);

  // If we have a metric, add it as the last column.
  if (metric != null && metric.name !== null) {
    columns.push({accessor: 'summary:' + metric.name});
  }
  const pcPanel = Panels.layedOutPanel({
    __id__: ID(),
    viewType: PanelParallelCoord.PANEL_TYPE,
    layout: {x: 0, y: haveMetric ? 10 : 0, w: 24, h: 10},
    config: {columns},
  });
  panels.push(pcPanel);
  return panels;
}

export interface SweepConfig {
  columns?: SweepColumn[];
  method?: 'grid' | 'random' | 'bayes';
  metricName?: string;
  program?: string;
  job?: string;
  goal?: 'minimize' | 'maximize';
  earlyTerminate?: {
    type: 'hyperband';
    minIter: string;
    eta: string;
  };
}

export type SweepColumnLeaf = {
  accessor?: string; // should be something like loss.value
  variableName?: string; // should be something like loss
  distribution?: string; // for example categorical
  value?: string;
  params?: {[key: string]: string};
  _kind: 'leaf';
};

export type SweepColumnNode = {
  accessor?: string;
  variableName?: string;
  columns: SweepColumn[];
  _kind: 'node';
};

// enables nested parameter support
export type SweepColumn = SweepColumnLeaf | SweepColumnNode;

export function useAllRunsData(entityName: string, projectName: string) {
  return useRunsData({
    entityName,
    projectName,
    keysLoading: false,
    fullConfig: true,
    fullSummary: true,
    queries: [
      {
        // LB: What does id do?
        id: 'test-id-unused',
        entityName,
        projectName,
        filters: Filter.EMPTY_FILTERS,
        sort: Query.CREATED_AT_ASC,
      },
    ],
  });
}

export function getConfigFromRunsData(runsData: Types.Data): SweepConfig {
  const program = getProgramFromRunsData(runsData);
  const columns = loadColumnsFromRunsData(runsData);
  const metrics = getMetricNameOptions(runsData.keyInfo);
  const metricName = metrics.length > 0 ? metrics[0].value : '';

  return {
    program: program ?? 'train.py',
    columns,
    method: 'bayes',
    metricName,
    goal: 'minimize',
  };
}

function getProgramFromRunsData(runsData: Types.Data): string | null {
  for (let i = runsData.filtered.length - 1; i >= 0; i--) {
    const run = runsData.filtered[i];
    const path = (run.config._wandb as any)?.value?.code_path;
    if (path != null) {
      return path.replace(/^code\//, '');
    }
  }
  return null;
}

export function getMetricNameOptions(keyInfo: RunKeyInfo) {
  return _.chain(keyInfo)
    .toPairs()
    .filter(
      ([k, ki]) =>
        ki.majorType === 'number' &&
        k.split(':')[0] === 'summary' &&
        !Runs.isReservedKey(k.split(':')[1]) &&
        !(k.split(':')[1] === 'graph')
    )
    .map(([k, ki]) => {
      return {
        text: Runs.keyStringDisplayName(k),
        key: Runs.keyStringDisplayName(k),
        value: Runs.keyStringDisplayName(k),
      };
    })
    .value();
}

/**
 * Constructs a SweepColumn structure from a given ConfigKeyTree, RunKeyInfo, and path.
 * This function traverses the key tree, creating a hierarchical representation of sweep columns.
 * At each leaf of the tree, it generates a SweepColumnLeaf based on the key information and sweep settings.
 * At non-leaf nodes, it recursively builds SweepColumnNodes.
 *
 * @param {Runs.ConfigKeyTree} keyTree - The tree structure representing nested configuration keys.
 * @param {RunKeyInfo} keyInfo - Flattened keyInfo for the root config, used to extract sweep settings for leaf nodes.
 * @param {string[]} [path=[]] - The current path through the key tree, represented as an array of strings.
 * @returns {SweepColumn | null} - A SweepColumn representing either a leaf or a node in the sweep configuration,
 *                                 or null if the current tree is empty.
 */
function makeSweepColumn(
  keyTree: Runs.ConfigKeyTree,
  keyInfo: RunKeyInfo,
  path: string[] = []
): SweepColumn | null {
  // Determines the position offset for extracting the variable name from the path.
  // When path.length is exactly 2, it indicates a structure like 'someparameter.value',
  // and we need to omit '.value' to obtain the root name. In this case, we set the offset to 2.
  // Otherwise, the offset is set to 1, giving us the name of the last element of path as the
  // variable name.
  const pathLengthOffset = path.length === 2 ? 2 : 1;

  if (keyTree == null) {
    // Handle leaf node
    const fullKey = path.join('.');
    const value = keyInfo['config:' + fullKey];
    if (value != null) {
      const settings = getDefaultSweepSettingsFromKeyInfo(value);

      if (settings != null) {
        const params: {[key: string]: string} = {};
        if (settings.min != null) {
          params.min = settings.min.toString();
        }
        if (settings.max != null) {
          params.max = settings.max.toString();
        }
        if (settings.values != null) {
          params.values = settings.values.join(',');
        }

        return {
          _kind: 'leaf',
          accessor: fullKey,
          variableName: path[path.length - pathLengthOffset],
          params,
          distribution: settings.distribution,
        };
      }
    }
    return null;
  } else {
    // Handle non-leaf node
    const columns = Object.keys(keyTree)
      .map(key => {
        const keySubTree = keyTree[key];
        const newPath = [...path, key];
        return makeSweepColumn(keySubTree, keyInfo, newPath);
      })
      .filter((c): c is SweepColumn => c != null);

    if (columns.length === 0) {
      return null;
    }

    return {
      _kind: 'node',
      accessor: path.join('.'),
      variableName: path[path.length - pathLengthOffset],
      columns,
    };
  }
}

function loadColumnsFromRunsData(data: Types.Data): SweepColumn[] {
  if (!data.keyInfo) {
    return [];
  }

  const mergedKeyTree: Runs.ConfigKeyTree = data.filtered
    // This is a hack to filter out the scheduler runs.
    // Users can rename scheduler runs but this should work in most cases.
    // TODO(DG): filter out any hidden runs sweep-controller on the query side
    .filter(r => !r.displayName.startsWith('Scheduler'))
    .map(d => d._nestedConfigKeys ?? {})
    .reduce((acc: Runs.ConfigKeyTree, val) => _.merge(val, acc), {});

  const topLevelColumn = makeSweepColumn(mergedKeyTree, data.keyInfo);
  if (topLevelColumn == null) {
    return [];
  }

  const topLevelColumns = (topLevelColumn as SweepColumnNode).columns;

  return topLevelColumns
    .filter((c): c is SweepColumnNode => c != null)
    .map(c => c.columns[0]);
}

const SWEEP_CONFIG_KEY_ORDER = [
  'program',
  'job',
  'method',
  'metric',
  'early_terminate',
  'parameters',
];

interface YAMLPair {
  key: {value: string};
}

function sweepConfigKeySort(a: YAMLPair, b: YAMLPair): number {
  const ai = SWEEP_CONFIG_KEY_ORDER.indexOf(a.key.value);
  const bi = SWEEP_CONFIG_KEY_ORDER.indexOf(b.key.value);
  if (bi === -1) {
    return -1;
  }
  if (ai === -1) {
    return 1;
  }
  return ai - bi;
}

/**
 * Converts a SweepColumn object into a SweepYamlSettings object. This function processes
 * the SweepColumn structure, differentiating between leaf and node types. For leaf columns,
 * it generates sweep settings directly from the column. For node columns, it recursively
 * constructs the settings object by aggregating the settings of its subcolumns.
 *
 * @param {SweepColumn} col - The SweepColumn object to be converted into sweep settings.
 * @returns {SweepYamlSettings} - A SweepYamlSettings object derived from the input column,
 *                                containing the configuration parameters for a sweep.
 */
function settingsObjectFromColumn(col: SweepColumn): SweepYamlSettings {
  if (col.variableName) {
    if (col._kind === 'leaf') {
      return generateSweepSettingsFromColumn(col);
    } else {
      return {
        parameters: col.columns.reduce((acc, val) => {
          if (val.variableName) {
            acc[val.variableName] = settingsObjectFromColumn(val);
          }
          return acc;
        }, {} as SweepYamlParameters),
      };
    }
  }
  return {parameters: {}};
}

export function generateSweepYamlFromConfig(
  config: SweepConfig,
  includeProgram: boolean = true
) {
  const parameters: SweepYamlParameters = {};
  if (config.columns) {
    config.columns.forEach(col => {
      if (col.variableName) {
        parameters[col.variableName] = settingsObjectFromColumn(col);
      }
    });
  }
  const earlyTerminate = config.earlyTerminate
    ? {
        type: config.earlyTerminate.type,
        eta: Number(config.earlyTerminate.eta),
        min_iter: Number(config.earlyTerminate.minIter),
      }
    : undefined;

  const sweepConfig: SweepYamlConfig = {
    method: config.method || 'grid',
    metric: {
      name: config.metricName || '',
      goal: config.goal || 'minimize',
    },
    parameters,
  };

  if (includeProgram) {
    sweepConfig.program = config.program && 'train.py';
  }

  if (earlyTerminate) {
    sweepConfig.early_terminate = earlyTerminate;
  }

  return YAML.stringify(sweepConfig, {sortMapEntries: sweepConfigKeySort});
}

export function generateSweepSettingsFromColumn(col: SweepColumnLeaf) {
  const settings: SweepYamlSettings = {
    distribution: col.distribution || 'uniform',
  };

  if (col.params) {
    for (const [key, value] of Object.entries(col.params)) {
      const val = value.trim();
      if (key === 'min') {
        settings.min = Number(val);
      } else if (key === 'max') {
        settings.max = Number(val);
      } else if (key === 'q') {
        settings.q = Number(val);
      } else if (key === 'mu') {
        settings.mu = Number(val);
      } else if (key === 'sigma') {
        settings.sigma = Number(val);
      } else if (key === 'value') {
        settings.value = validNumOrString(val);
      } else if (key === 'values') {
        settings.values = _.compact(val.split(',')).map(v =>
          validNumOrString(v.trim())
        );
      }
    }
  }

  return settings;
}

export function getConfigFromJobData(
  job: JobDetails | null
): SweepConfig | null {
  if (!job || !job.runs || job.runs.length === 0) {
    return null;
  }
  const keys = job.configKeys;
  const configs = job.runs.map(x => x.config);
  const mergedConfigKeyTree: Runs.ConfigKeyTree = configs
    .map((c, i) => Runs.parseConfig(c, job.runs[i].name)?._nestedConfigKeys)
    .filter((c): c is Runs.ConfigKeyTree => c != null)
    .reduce((acc: Runs.ConfigKeyTree, val) => _.merge(val, acc), {});

  const rawValueCounts: Record<string, Record<string, number>> = {};
  configs.forEach(x => {
    keys.forEach(key => {
      if (x[key] == null) {
        return;
      }
      const keyVal = x[key] as JsonObject;
      const value = keyVal.value as string | number | undefined;
      if (value == null) {
        return;
      }
      if (!(key in rawValueCounts)) {
        rawValueCounts[key] = {};
      }

      if (!(value in rawValueCounts[key])) {
        rawValueCounts[key][value] = 0;
      }
      rawValueCounts[key][value] += 1;
    });
  });

  const valueCounts: RunKeyInfo = {};
  Object.keys(rawValueCounts).forEach(key => {
    const uniqueKeys = Object.keys(rawValueCounts[key]);
    const info: RunKeyInfoInfo = {
      valueCount: rawValueCounts[key],
      distinctCount: uniqueKeys.length,
      // majorType is the type plurality for a given param
      // however, since type for all non-categorical params
      // should be the same for all values, we take the first
      majorType: typeof validNumOrString(uniqueKeys[0]) as RunKeyType,
    };
    valueCounts[key] = info;
  });

  const topLevelColumns = (
    makeSweepColumn(mergedConfigKeyTree, valueCounts) as SweepColumnNode
  ).columns;

  const columns = topLevelColumns
    .filter((c): c is SweepColumnNode => c != null)
    .map(c => c.columns[0]);

  return {
    columns,
    method: 'bayes',
    metricName: '',
    goal: 'minimize',
  };
}

function validNumOrString(str: string) {
  const num = Number(str);
  return _.isFinite(num) ? num : stripQuotes(str);
}

function stripQuotes(str: string) {
  return str.replace(/"/g, '');
}

export interface CreateSweepArgs {
  runSet: RunSetConfig;
  tempSelections: SelectionState;
}
