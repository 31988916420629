import _ from 'lodash';

import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {panelSortingKeyFromPanel} from '../../../util/panelbank';
import * as PanelsUtil from '../../../util/panels';
import {setInShallowClone} from '../../../util/utility';
import * as Normalize from '../normalize';
import {StateType} from '../normalizerSupport';
import * as PanelTypes from '../panel/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as Actions from './actions';
import {PanelBankSectionConfigNormalized, Ref} from './types';

export const toggleIsOpen = (
  state: ViewReducerState,
  ref: Ref
): [ViewReducerState, ActionType] => {
  const curSectionConfig = state.parts[ref.type][ref.id];
  const newState = setInShallowClone(
    state,
    ['parts', ref.type, ref.id, 'isOpen'],
    !curSectionConfig.isOpen
  );
  const inverseAction = Actions.toggleIsOpen(ref);
  return [newState, inverseAction];
};

const movePanelAlphabeticallyKey = (key: string | undefined) => {
  if (key === undefined) {
    return '0';
  } else {
    return '1' + key;
  }
};

export function insertPanelsAlphabetically(
  parts: StateType,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  panelsAndRefs: Array<{
    panel: PanelsUtil.LayedOutPanel | PanelsUtil.LayedOutPanelWithRef;
    panelRef: PanelTypes.Ref;
  }>
) {
  const panelKeys = normalizedSectionConfig.panelRefs.map(oldPanelRef => {
    const sectionPanel = parts[oldPanelRef.type][oldPanelRef.id];
    return panelSortingKeyFromPanel(sectionPanel);
  });

  const combinedPanels = panelsAndRefs.map(({panel, panelRef}) => ({
    panelRef,
    panelKey: panelSortingKeyFromPanel(panel),
  }));

  const allPanels = normalizedSectionConfig.panelRefs
    .map((oldPanelRef, i) => ({
      panelRef: oldPanelRef,
      panelKey: panelKeys[i],
    }))
    .concat(combinedPanels);

  allPanels.sort((a, b) =>
    movePanelAlphabeticallyKey(a.panelKey).localeCompare(
      movePanelAlphabeticallyKey(b.panelKey)
    )
  );

  normalizedSectionConfig.panelRefs = allPanels.map(item => item.panelRef);
}

export function movePanelAlphabeticallyInSection(
  state: ViewReducerState,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  panelRef: PanelTypes.Ref,
  panel: PanelsUtil.LayedOutPanel
) {
  const panelKeys = normalizedSectionConfig.panelRefs
    .filter(oldPanelRef => oldPanelRef.id !== panelRef.id)
    .map(oldPanelRef => {
      const sectionPanel = Normalize.denormalize(state.parts, oldPanelRef);
      return panelSortingKeyFromPanel(sectionPanel);
    });
  const newPanelIndex = _.sortedIndexBy(
    panelKeys,
    panelSortingKeyFromPanel(panel),
    movePanelAlphabeticallyKey
  );
  const origPanelIndex = normalizedSectionConfig.panelRefs.findIndex(
    sectionPanelRef => sectionPanelRef.id === panelRef.id
  );
  normalizedSectionConfig.panelRefs.splice(origPanelIndex, 1);
  normalizedSectionConfig.panelRefs.splice(newPanelIndex, 0, panelRef);
}

export const updateLinePlotSectionSettings = (
  state: ViewReducerState,
  ref: Ref,
  settings: Partial<LinePlotSettings> | undefined
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
      },
    },
  };

  const prevSectionSettings =
    newState.parts['panel-bank-section-config'][ref.id].sectionSettings;

  newState.parts['panel-bank-section-config'][ref.id] = {
    ...newState.parts['panel-bank-section-config'][ref.id],
    sectionSettings: {
      ...prevSectionSettings,
      linePlot: {
        ...(prevSectionSettings?.linePlot ?? {}),
        ...settings,
      },
    },
  };

  const inverseAction = Actions.updateLinePlotSectionSettings(
    ref,
    prevSectionSettings?.linePlot
  );
  return [newState, inverseAction];
};
