import {isNil as _isNil} from 'lodash';
import React, {FC, useState} from 'react';

import * as Filter from '../../util/filters';
import * as Run from '../../util/runs';
import {EditableFilterContextProvider} from './FilterContext';
import {FilterEditable} from './FilterEditable';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';
import {handleFilterOpChange} from './logic';

interface FilterListProps {
  filters: Filter.RootFilter<Filter.FilterKey>['filters'][0];
  index: number;
  canAdd: boolean;
  pushFilter(filter: Filter.Filter<Filter.FilterKey>): void;
  deleteFilter(index: number): void;
  setFilter(
    index: number,
    filter: Filter.IndividualFilter<Filter.FilterKey>
  ): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: FilterValueSelectorCreatorProps<Filter.FilterKey>
  ): React.ReactNode;
}

const FilterList: FC<FilterListProps> = React.memo(
  ({
    filters: rootFilters,
    canAdd,
    pushFilter,
    deleteFilter,
    setFilter,
    filterKeySelector,
    filterValueSelector,
  }) => {
    const [addedFilterIndex, setAddedFilterIndex] = useState(-1);
    const filters = rootFilters.filters;
    let counter = 0;
    /**
     * Question: what is EditableFiltersContextProvider and why does it duplicate a bunch of functionality in FilterEditable?
     *
     * Answer: It currently exists only to support the Infinite Loading Tags filter. It's written with an eye toward eventually being the single source of truth for all editable filters.
     *
     * What I like about the previous pattern: a good design aspect of the previous pattern is that the functions defined as props take a powerful tool (the filters "library" which exists as a pretty base level abstraction in our code) and hide some of the complexity behind a more semantically informative set of functions that protect downstream code from having to know about the exact internals of the filter library. I also like that it pre-applies information (in this case the filter out of the map iteration) so that downstream we don't have to do a bunch of redundant data passing.
     *
     * What I don't love is that if you don't know exactly how filters work there's pretty heavy lift to go upstream through N layers of components which all drill these functions down in order to find out what's going on. There's also a minimum of explanation here at the root to help someone make educated decisions about how/where to modify code. My thoughts for the context provider is that it can be a single point of translation between the root filter library code and any components that need to express changes in terms of operations built out of that root level library. I would like it to be accessible _without_ prop drilling so that it's easier to uncover and reason about.
     *
     * WARNING: the types are (maybe) a lie! I'm operating a narrow slice of the complexity described by the underlying filter types and I don't know that they're constructed such that narrowing them is easy to do (which is why the previous code casts liberally). It will need to be revisited if more use cases come to be included in EditableFiltersContextProvider
     */
    return (
      <div className="filter-list">
        <EditableFilterContextProvider
          actions={{
            deleteFilter,
            setFilter,
          }}
          filters={
            filters.filter(f => Filter.isIndividual(f)) as Array<
              Filter.IndividualFilter<Filter.FilterKey>
            >
          }>
          {filters.map((filter, i) => {
            // TODO: display compound filters
            if (!Filter.isIndividual(filter)) {
              return null;
            }
            const currentCounter = counter++;
            return (
              <FilterEditable
                key={`filter=${currentCounter}`}
                filterIndex={currentCounter}
                justAdded={addedFilterIndex === currentCounter}
                filter={filter}
                setFilterDisabled={(disabled: boolean) => {
                  const f: Filter.IndividualFilter<Filter.FilterKey> =
                    filter as Filter.IndividualFilter<Filter.FilterKey>;
                  setFilter(currentCounter, {...f, disabled});
                }}
                setFilter={filt => setFilter(currentCounter, filt)}
                setFilterOp={(newFilterOp: Filter.IndividualOp) => {
                  setFilter(
                    currentCounter,
                    handleFilterOpChange(filter, newFilterOp)
                  );
                }}
                setFilterValue={(filterValue: Run.Value) => {
                  setFilter(currentCounter, {
                    ...filter,
                    value: filterValue,
                    disabled: _isNil(filterValue),
                  } as Filter.ValueFilter<Filter.FilterKey> & Filter.ToggleableFilter);
                }}
                setFilterMeta={(meta: any) => {
                  setFilter(currentCounter, {
                    ...filter,
                    meta,
                  } as Filter.ValueFilter<Filter.FilterKey>);
                }}
                setFilterMultiValue={(filterValue: Run.Value[]) => {
                  setFilter(currentCounter, {
                    ...filter,
                    value: filterValue,
                    disabled: _isNil(filterValue),
                  } as Filter.MultiValueFilter<Filter.FilterKey> & Filter.ToggleableFilter);
                }}
                deleteFilter={() => deleteFilter(currentCounter)}
                filterKeySelector={filterKeySelector}
                filterValueSelector={filterValueSelector}
              />
            );
          })}

          {canAdd && (
            <span
              style={{display: 'flex', alignSelf: 'start'}}
              className="fake-link filter__new"
              data-test="filter-add"
              // onClick={() => pushFilter(Filter.defaultNewFilter)}>
              onClick={() => {
                // pushFilter(defaultNewRunFilter);
                // TODO: check if it's ok to add this as an empty filter
                setAddedFilterIndex(filters.length);
                pushFilter({
                  key: {section: 'run', name: ''},
                  op: '=',
                  value: '',
                  disabled: true,
                });
              }}>
              + Add filter
            </span>
          )}
        </EditableFilterContextProvider>
      </div>
    );
  }
);

export default FilterList;
