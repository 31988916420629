import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panels';
import * as Query from '../../util/queryts';
import {MarkdownConfig} from './common';
export type {MarkdownConfig};

const PANEL_TYPE = 'Markdown Panel';

const transformQuery = (query: Query.Query): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

export const Spec: PanelSpec<typeof PANEL_TYPE, MarkdownConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
  noEditMode: true,
  icon: 'panel-text',
};
