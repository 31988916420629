import produce, {setAutoFreeze} from 'immer';
import * as _ from 'lodash';
import {getType} from 'typesafe-actions';

import {cyrb53} from '../../util/hash';
import {startPerfTimer} from '../../util/profiler';
import {keyBitmapToKeys} from '../../util/runs';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {HistoryKeyInfoQueryVars} from '../graphql/historyKeysQuery';
import * as Actions from './actions';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as Lib from './lib';
import {Transform} from './serverQuery';
import * as ServerQueryServerDelta from './serverQuery_serverDelta';
import * as Types from './types';

export interface HistoryKeyInfoState {
  queryVars: HistoryKeyInfoQueryVars;
  historyKeySets: Array<{setID: string; set: {[key: string]: true}}>;
}

export interface RunsReducerState {
  queries: {[id: string]: Types.CachedQuery};
  queryErrors: {[id: string]: any};
  runs: {[id: string]: Types.CachedRun};

  // We store historyKeyInfo results here, which we use for query
  // merging
  historyKeyInfos: {
    [id: string]: HistoryKeyInfoState;
  };
}

// Globally turn off immer's auto-freeze. It's only on in dev, but it causes
// lots of perf slow downs in dev.
setAutoFreeze(false);

// This needs to be generic to handle different server query
// strategies. This is because we want to do the conversion from server
// result to user result as a reducer step. This way the actions coming
// into to redux will be server results (which are typically delta updates)
// as opposed to user query results (which are always the full result of a
// given query). This is much better for debugging when using the redux dev
// tools.

export const keySetID = (keys: string[]) => {
  return cyrb53(keys.sort().join('')).toString();
};

export function makeRunsReducer<SQ, SR>(
  serverQueryStrategy: Transform<SQ, SR>
) {
  const runsLowReducer = (
    outerState: RunsReducerState = {
      queries: {},
      queryErrors: {},
      runs: {},
      historyKeyInfos: {},
    },
    action: Actions.FullActionType<SR>
  ) => {
    const {endPerfTimer} = startPerfTimer(
      `runs-low reducer, action: ${action.type}`
    );

    try {
      let result = produce(outerState, draft => {
        switch (action.type) {
          case getType(Actions.queryErrors): {
            action.payload.ids.forEach((ids, i) => {
              ids.forEach(id => {
                draft.queryErrors[id] = action.payload.errors[i];
              });
            });
            break;
          }
          case getType(Actions.deleteRun): {
            const {runName} = action.payload;
            const run = outerState.runs[runName];
            if (run) {
              delete draft.runs[runName];
              // must also remove references to this run across all queries
              Object.keys(outerState.queries).forEach(queryId => {
                const prevResults = outerState.queries[queryId].result;
                draft.queries[queryId].result = prevResults.filter(
                  r => r !== runName
                );
              });
            }
            break;
          }
          case getType(Actions.updateRun): {
            const {id, vars} = action.payload;
            const run = draft.runs[id];
            if (run == null) {
              break;
            }
            run.identity.data.displayName = vars.displayName;
            if (run.basic) {
              run.basic.data.notes = vars.notes;
            }
            break;
          }
          case getType(Actions.updateRunTags): {
            const {tagMap} = action.payload;
            Object.keys(tagMap).forEach(id => {
              const run = draft.runs[id];
              if (run != null && run.basic) {
                run.basic.data.tags = tagMap[id];
              }
            });
            break;
          }
          case getType(Actions.clearHistoryKeyInfo): {
            const {id} = action.payload;
            delete draft.historyKeyInfos[id];
            break;
          }
        }
      });

      // actions ejected from immer due to poor performance
      if (action.type === Actions.QUERY_RESULTS_TYPE) {
        // applyQueryResults previously performed mutations and was applied
        // using immer. immer was taking > 300ms to process heavy results,
        // so applyQueryResults now does an immutable update.
        result = Lib.applyQueryResults(
          serverQueryStrategy,
          result,
          action.payload.mergedQueries,
          action.payload.results
        );
        result = produce(result, draft => Lib.collectGarbage(draft));
      } else if (action.type === getType(Actions.updateHistoryKeyInfo)) {
        // Don't use immer for this, it's super slow.

        const {id, queryVars, historyKeyInfo} = action.payload;
        const prevInfo = outerState.historyKeyInfos[id];
        let prevSets: HistoryKeyInfoState['historyKeySets'] = [];
        // As long as the project remains the same, we keep growing the list of
        // keysets we know about. The more we know about, the less aggressive we'll
        // be about merging.
        if (
          prevInfo != null &&
          prevInfo.queryVars.entityName === queryVars.entityName &&
          prevInfo.queryVars.projectName === queryVars.projectName
        ) {
          prevSets = _.cloneDeep(prevInfo.historyKeySets);
        }
        const prevSetsLookup = _.fromPairs(prevSets.map(s => [s.setID, true]));
        const allKeys = Object.keys(historyKeyInfo.keys).sort();
        for (const ks of historyKeyInfo.sets) {
          const keys = keyBitmapToKeys(allKeys, ks.keyBitmap);
          const setID = keySetID(keys);
          if (prevSetsLookup[setID] == null) {
            const set: {[key: string]: true} = {};
            for (const k of keys) {
              set[k] = true;
            }
            prevSets.push({
              setID,
              set,
            });
          }
        }
        result = {
          ...result,
          historyKeyInfos: {
            ...result.historyKeyInfos,
            [id]: {queryVars, historyKeySets: prevSets},
          },
        };
      } else if (action.type === getType(Actions.registerQueryWithID)) {
        result = Object.assign({}, result);
        result.queries = Object.assign({}, result.queries, {
          [action.payload.id]: {
            id: action.payload.id,
            query: action.payload.query,
            loading: true,
            totalRuns: 0,
            result: [],
            generation: 0,
            lastUpdatedAt: new Date(0).toISOString(),
          },
        });
        result.queryErrors = Object.assign({}, result.queryErrors, {
          [action.payload.id]: null,
        });
      } else if (action.type === getType(Actions.updateQuery)) {
        const curQuery = result.queries[action.payload.id];
        if (Lib.queryNeedsUpdate(action.payload.query, curQuery)) {
          const newQuery = Object.assign({}, curQuery);
          result = Object.assign({}, result);
          result.queries = Object.assign({}, result.queries);
          result.queries[action.payload.id] = newQuery;
          result.runs = Object.assign({}, result.runs);
          Lib.updateQuery(action.payload.query, newQuery);
          Lib.collectGarbage(result);
        }
      } else if (action.type === getType(Actions.unregisterQuery)) {
        result = Object.assign({}, result);
        result.queries = Object.assign({}, result.queries);
        result.queryErrors = Object.assign({}, result.queryErrors);
        result.runs = Object.assign({}, result.runs);
        delete result.queries[action.payload.id];
        delete result.queryErrors[action.payload.id];
        Lib.collectGarbage(result);
      }

      return result;
    } finally {
      endPerfTimer();
    }
  };

  return runsLowReducer;
}

export const STRATEGY = ServerQueryServerDelta.STRATEGY_GRAPHQL;

export default makeRunsReducer(STRATEGY);
