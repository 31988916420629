import {Button} from '@wandb/weave/components';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {useContext, useState} from 'react';

import {AccountStatusBanner} from '../../../../components/AccountStatusBanner';
import {
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../components/Banner';
import {GlobalBannerContext} from '../../../../components/GlobalBanners';
import {AccountSelectorContext} from '../../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {OrganizationDataForFailedPaymentBanner} from '../../../../generated/graphql';
import {Analytics} from '../../../../services/analytics';
import {StripeElementsComp} from '../../../../util/accounts/pricing';
import {createGraphqlOrgId} from '../../../../util/id';
import {useRampFlagAccountSelector} from '../../../../util/rampFeatureFlags';
import {AddPaymentMethodModal} from '../../Checkout/AddPaymentMethodModal';

export const PaymentFailedBanner = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const {states} = useContext(GlobalBannerContext);

  if (states == null || !enableAccountSelector || selectedAccount == null) {
    return null;
  }

  const currentOrgName = selectedAccount.name;

  // Only show banner if data found for currently selected account
  const organizationStatesToShow = states
    .filter(s => s?.failedPaymentOrgData?.orgName === currentOrgName)
    .map(s => s.failedPaymentOrgData as OrganizationDataForFailedPaymentBanner);

  // If there are no orgs that have failed payments then don't display banners
  if (organizationStatesToShow.length === 0) {
    return null;
  }

  return (
    <div>
      {organizationStatesToShow.map((org, index) => {
        const bannerText = `Your payment method failed for ${org.orgName}.`;
        let buttonContent = null;
        let contactInfo;
        if (org?.isBillingUser) {
          buttonContent = (
            <Button
              variant="ghost"
              className="bg-transparent outline hover:bg-transparent hover:text-moon-600"
              onClick={() => {
                setIsPaymentModalOpen(true);
                Analytics.track('Add Payment Method Clicked', {
                  location: 'payment failed warning banner',
                  organizationName: org,
                });
              }}>
              Add Payment Method
            </Button>
          );
          contactInfo = `Update your payment method to continue using Weights & Biases without disruption.`;
        } else {
          contactInfo = `Contact your admin, ${org.billingEmail}, to update your payment method to continue using Weights & Biases without disruption.`;
        }

        return (
          <React.Fragment key={index}>
            <Tailwind>
              <AccountStatusBanner
                data-test="failed-payment-warning-banner"
                eventData={{
                  location: 'failed payment warning banner',
                  organizationName: selectedAccount?.name,
                }}>
                <BannerFlexWrapper>
                  <Icon name="warning" />
                  <BannerTextWrapper>
                    <BannerBoldText>{bannerText}</BannerBoldText>
                    {contactInfo}
                    {org?.daysUntilCancellation != null &&
                      org?.daysUntilCancellation > 0 && (
                        <>
                          {' '}
                          Otherwise, your account will be suspended in{' '}
                          {org.daysUntilCancellation} days.
                        </>
                      )}
                  </BannerTextWrapper>
                </BannerFlexWrapper>
                {buttonContent}
              </AccountStatusBanner>
            </Tailwind>
            {isPaymentModalOpen && (
              <StripeElementsComp>
                <AddPaymentMethodModal
                  billingUserEmail={org.billingEmail}
                  organizationName={org.orgName}
                  organizationID={createGraphqlOrgId(org.orgId)}
                  shouldDefault={true}
                  shouldRetryOpenInvoices={true}
                  onTransactionCompleted={() => setIsPaymentModalOpen(false)}
                  onClose={() => setIsPaymentModalOpen(false)}
                />
              </StripeElementsComp>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
