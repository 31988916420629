import gql from 'graphql-tag';

import {AlertSubscription} from '../generated/graphql';

export const fragments = {
  view: gql`
    fragment ViewFragment on View {
      id
      type
      name
      displayName
      description
      user {
        id
        username
        photoUrl
        admin
      }
      project {
        id
        name
        entityName
      }
      spec
      updatedAt
    }
  `,
  viewMetadata: gql`
    fragment ViewFragmentMetadata on View {
      id
      type
      name
      displayName
      description
      updatedBy {
        id
        username
        name
      }
      user {
        id
        username
        photoUrl
        admin
      }
      project {
        id
        name
        entityName
      }
      updatedAt
      createdAt
      starred
      starCount
      parentId
      locked
      viewCount
      alertSubscription {
        id
      }
    }
  `,
  // benchmarkRun: gql`
  //   fragment BenchmarkRunFragment on BenchmarkRun {
  //     id
  //     user {
  //       id
  //       username
  //       photoUrl
  //       admin
  //     }
  //     details
  //     benchmark {
  //       name
  //     }
  //   }
  // `,
};

export interface ViewMetadata {
  id: string;
  name: string;
  displayName: string;
  description: string;
  updatedBy?: {
    id: string;
    username: string;
    name: string;
  };
  user: {
    id: string;
    username: string;
    photoUrl: string;
    admin: boolean;
  };
  project: {
    id: string;
    name: string;
  };
  type: string;
  spec: string;
  createdAt: string;
  updatedAt: string;
  starCount: number;
  starred: boolean;
  parentId: string;
  locked: boolean;
  alertSubscription: AlertSubscription;
}

export const VIEW_UPSERT = gql`
  mutation upsertView(
    $id: ID
    $entityName: String
    $projectName: String
    $type: String
    $name: String
    $description: String
    $spec: String!
  ) {
    upsertView(
      input: {
        id: $id
        entityName: $entityName
        projectName: $projectName
        name: $name
        description: $description
        type: $type
        spec: $spec
      }
    ) {
      view {
        id
        ...ViewFragment
      }
      inserted
    }
  }
  ${fragments.view}
`;

export const VIEW_DELETE = gql`
  mutation deleteView($id: ID) {
    deleteView(input: {id: $id}) {
      success
    }
  }
`;

export const VIEW_CREATE_SHARED = gql`
  mutation createSharedView(
    $entityName: String!
    $name: String!
    $type: String!
    $description: String
    $spec: String!
  ) {
    upsertSharedView(
      input: {
        entityName: $entityName
        name: $name
        type: $type
        description: $description
        spec: $spec
      }
    ) {
      view {
        id
        ...ViewFragment
      }
    }
  }
  ${fragments.view}
`;
