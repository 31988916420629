import React, {createContext, FC, ReactNode, useContext, useMemo} from 'react';

import {Ref as CustomRunColorsViewRef} from '../../state/views/customRunColors/types';
import {usePart} from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import type {Ref as PanelBankConfigRef} from '../../state/views/panelBankConfig/types';
import type {Ref as PanelBankSectionConfigRef} from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsViewTypes from '../../state/views/panelSettings/types';
import type {Ref as RunSetViewRef} from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {useDeepEqualValue} from '../../util/hooks';
// eslint-disable-next-line import/no-cycle
import {LayedOutPanel} from '../../util/panels';
import {Settings} from '../../util/panelsettings';
import {PanelLoadingState} from '../PanelBank/PanelBankTiming';

// TODO - as panels gets refactored some more, consider separating some of these out to a PanelBankContextProvider

interface PanelContextProps {
  customRunColorsRef: CustomRunColorsViewRef;
  historyKeyInfo?: RunHistoryKeyInfo & {sortedKeys: string[]}; // Used in PanelRunsLinePlot
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigRef;
  panelBankSectionConfigRef: PanelBankSectionConfigRef;
  panelRef: PanelTypes.Ref;
  inheritedSettings?: PanelSettingsViewTypes.PanelSettings;
  runSetRefs: RunSetViewRef[];
  searchQuery?: string;
  loadingState?: PanelLoadingState;
}

type PanelContextType = PanelContextProps & {
  panel: LayedOutPanel;
};
export const PanelContext = createContext<PanelContextType>({
  customRunColorsRef: {id: '', type: 'run-colors', viewID: ''},
  historyKeyInfo: undefined,
  panelBankSectionConfigRef: {
    id: '',
    type: 'panel-bank-section-config',
    viewID: '',
  },
  panelRef: {
    id: '',
    type: 'panel',
    viewID: '',
  },
  panel: {} as LayedOutPanel,
  runSetRefs: [
    {
      id: '',
      type: 'runSet',
      viewID: '',
    },
  ],
});
PanelContext.displayName = 'PanelContext';

interface PanelContextProviderProps {
  children: ReactNode;
  customRunColorsRef: CustomRunColorsViewRef;
  historyKeyInfo?: RunHistoryKeyInfo; // Used in PanelRunsLinePlot
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigRef; // the panelbank config, used for PanelMover
  panelBankSectionConfigRef: PanelBankSectionConfigRef; // the panelbank section that contains this panel, used for PanelMover
  panelRef: PanelTypes.Ref;
  inheritedSettings?: Settings;
  runSetRefs: RunSetViewRef[];
  loadingState?: PanelLoadingState;
}

export const PanelContextProvider: FC<PanelContextProviderProps> = ({
  children,
  customRunColorsRef,
  historyKeyInfo,
  isReadOnly,
  panelBankConfigRef,
  panelBankSectionConfigRef,
  panelRef,
  inheritedSettings,
  runSetRefs,
  loadingState,
}) => {
  const panel = usePart(panelRef);
  const deepEqualPanel = useDeepEqualValue(panel);

  const historyKeys = useMemo(() => {
    if (!historyKeyInfo) {
      return undefined;
    }

    return {
      ...historyKeyInfo,
      /** later on we need the keys sorted. Sorting keys (that might number in the 10k+ range) can get expensive so we do it up top to take the hit once and not risk calling it multiple times in a downstream loop */
      sortedKeys: Object.keys(historyKeyInfo.keys).sort(),
    };
  }, [historyKeyInfo]);

  const state = useMemo(
    () => ({
      customRunColorsRef,
      historyKeyInfo: historyKeys,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      panel: deepEqualPanel,
      inheritedSettings,
      runSetRefs,
      loadingState,
    }),
    [
      customRunColorsRef,
      historyKeys,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      deepEqualPanel,
      inheritedSettings,
      runSetRefs,
      loadingState,
    ]
  );

  return (
    <PanelContext.Provider value={state}>{children} </PanelContext.Provider>
  );
};

export const usePanelContext = (): PanelContextType => {
  const value = useContext(PanelContext);

  if (value == null) {
    throw new Error(
      'usePanelContext must be used within a PanelContextProvider'
    );
  }

  return value;
};
