import _ from 'lodash';

import {
  QueryToRunsDataQueryParams,
  RunsDataQuery,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {DEFAULT_MAX_GROUP_RUNS} from '../../features/workspaceSettings';
import {CHART_SAMPLES} from '../../util/constants';
import {Expression, metricsInExpression} from '../../util/expr';
import * as Filters from '../../util/filters';
import * as PanelHelpers from '../../util/panelHelpers';
import {
  ChartAggOption,
  ChartAreaOption,
  LegendPosition,
  Mark,
  PlotType,
} from '../../util/plotHelpers';
import {PlotFontSizeOrAuto} from '../../util/plotHelpers/plotFontSize';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
import {SmoothingTypeValues} from '../elements/SmoothingConfig';
import {DEFAULT_X_AXIS_SETTINGS} from '../WorkspaceDrawer/Settings/defaults';
import {PointVisualizationOptions} from '../WorkspaceSettingsModal/controls/PointVisualizationTypes';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getExtraFields} from './queryConstructor/getSectionFields';

// see notes on handling time plots
// Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
// Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
export const X_AXIS_LABELS: {[key: string]: string} = {
  _step: 'Step',
  _absolute_runtime: 'Relative Time (Wall)',
  _runtime: 'Relative Time (Process)',
  _timestamp: 'Wall Time',
};

/**
 * This is a comprehensive bucket of all the editable options for a PanelRunsLinePlot. Each value is optional because a panel isn't required to have any or all of these. Rather, each subcomponent of the panel editor will choose its own default value.
 */
export interface RunsLinePlotConfig {
  // basic config
  chartTitle?: string;
  expressions?: string[];
  fontSize?: PlotFontSizeOrAuto;
  ignoreOutliers?: boolean;
  limit?: number; // max number of runs or groups to show
  plotType?: PlotType;
  pointVisualizationMethod?: PointVisualizationOptions;
  showMinMaxOnHover?: boolean;

  // axis options
  startingXAxis?: string;
  xAxis?: string;
  xExpression?: string; // expression for x axis
  xAxisMax?: number;
  yAxisMax?: number;
  xAxisMin?: number;
  yAxisMin?: number;
  xAxisTitle?: string;
  yAxisTitle?: string;
  yAxisAutoRange?: boolean;
  xLogScale?: boolean;
  yLogScale?: boolean;

  // grouping
  aggregate?: boolean; // user selected grouping
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  groupRunsLimit?: number;
  groupBy?: string; // key to group by
  isGrouped?: boolean;
  useRunsTableGroupingInPanels?: boolean; // respect the runs table grouping in the chart

  // legend
  legendFields?: string[];
  legendPosition?: LegendPosition;
  legendTemplate?: string; // used to generate the default legend
  showLegend?: boolean; // Display legend in chart (default true)

  // metrics
  aggregateMetrics?: boolean; // aggregate metrics into single metric
  colorEachMetricDifferently?: boolean; // if we have multiple metrics, override the run colors
  metrics?: string[]; // names of yAxis metrics
  metricRegex?: string; // can choose yAxis metrics by regex
  metricRegexMaxNumMatches?: number;
  useMetricRegex?: boolean;

  // Overrides
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  overrideMarks?: {[key: string]: Mark};
  overrideLineWidths?: {[key: string]: number};
  overrideSeriesTitles?: {[key: string]: string}; // For setting the specific names of lines

  // smoothing
  smoothingWeight?: number;
  smoothingType?: SmoothingTypeValues;
  showOriginalAfterSmoothing?: boolean; // Show the line and the smoothed line
  useLocalSmoothing?: boolean;
  useGlobalSmoothingWeight?: boolean;
}

export type RunsLinePlotConfigDefaults = Required<
  Pick<
    RunsLinePlotConfig,
    | 'aggregateMetrics'
    | 'legendFields'
    | 'groupAgg'
    | 'groupArea'
    | 'metrics'
    | 'plotType'
    | 'showOriginalAfterSmoothing'
    | 'smoothingType'
    | 'smoothingWeight'
    | 'useRunsTableGroupingInPanels'
  >
>;

/**
 * This is not an exhaustive list of defaults, but rather a WIP list of values that were defaulted in one or more places through PanelRunsLinePlot. Moving the defaults to a shared value for consistency.
 */
export const runsLinePlotConfigDefaults: RunsLinePlotConfigDefaults = {
  aggregateMetrics: false,
  legendFields: [],
  groupAgg: 'mean',
  groupArea: 'minmax',
  metrics: [],
  plotType: 'line',
  showOriginalAfterSmoothing: true,
  smoothingType: 'exponential',
  smoothingWeight: 0.0,
  useRunsTableGroupingInPanels: true,
};

export interface Range {
  min: number | null;
  max: number | null;
}

export function runsLinePlotTransformQuery(
  query: Query.Query,
  runsLinePlotConfig: RunsLinePlotConfig,
  xStepRange: Range | null,
  parsedExpressions: {
    expressions: Expression[] | undefined;
    xExpression: Expression | undefined;
  },
  defaultMaxRuns: number,
  numberOfPoints: number = CHART_SAMPLES
): RunsDataQuery {
  const singleRun = Boolean(query.runName);
  const isGrouped = PanelHelpers.isGrouped(query, runsLinePlotConfig);

  let result;
  if (singleRun) {
    result = toRunsDataQuery(query);
  } else {
    const queryToDataQueryParams: QueryToRunsDataQueryParams = {
      selectionsAsFilters: true,
    };

    if (
      runsLinePlotConfig.metrics != null &&
      runsLinePlotConfig.metrics.length > 0
    ) {
      const filters: Array<Filters.Filter<Run.Key>> = runsLinePlotConfig.metrics
        .slice(0, 50)
        .map(metric => ({
          key: {section: 'keys_info', name: metric},
          op: '!=',
          value: null,
        }));
      queryToDataQueryParams.mergeFilters = Filters.Or(filters);
    }

    result = toRunsDataQuery(query, queryToDataQueryParams);
  }

  if (runsLinePlotConfig.useRunsTableGroupingInPanels === false) {
    result.queries = result.queries.map(q => ({...q, grouping: []}));
  }

  result.disabled = true;

  const extraFields = getExtraFields(
    runsLinePlotConfig,
    parsedExpressions,
    query
  );

  result.configKeys = extraFields
    .filter(key => key.section === 'config')
    .map(key => key.name);
  result.summaryKeys = extraFields
    .filter(key => key.section === 'summary')
    .map(key => key.name);

  if (runsLinePlotConfig.metrics != null) {
    // We load the history data for the graphs
    // We sample values where an individual metric and the xAxis has values
    // Some users might prefer to sample history rows where all of the metrics
    // and the xAxis has a value, but this will cause some graphs to not display
    // if for example a user logs test data in one step and training data in another
    // and then wants to plot the two metrics in a single graph.
    result.disabled = false;
    result.history = true;

    const derivedXAxis =
      getDerivedXAxis(runsLinePlotConfig) ?? DEFAULT_X_AXIS_SETTINGS.xAxis;

    const yAxisExprKeys: string[] = [];
    for (const expr of parsedExpressions.expressions ?? []) {
      yAxisExprKeys.push(...metricsInExpression(expr));
    }
    const xAxisExprKeys: string[] =
      parsedExpressions.xExpression != null
        ? metricsInExpression(parsedExpressions.xExpression)
        : [];

    const xAxisHistoryKeys: string[] = []; // history keys we need besides the metric
    if (!getHasSystemMetrics(runsLinePlotConfig)) {
      xAxisHistoryKeys.push('_step');
    }
    if (derivedXAxis === '_absolute_runtime') {
      // we calculate absolute wall time from the start of the run
      // we need both timestamp and runtime to do this
      xAxisHistoryKeys.push('_timestamp');
      xAxisHistoryKeys.push('_runtime');
    } else {
      // this is the normal case
      xAxisHistoryKeys.push(derivedXAxis);
    }
    xAxisHistoryKeys.push(...xAxisExprKeys);

    result.historySpecs = _.uniq([
      ...runsLinePlotConfig.metrics,
      ...yAxisExprKeys,
      ...xAxisExprKeys,
    ]).map(metricKey => ({
      keys: _.uniq([...xAxisHistoryKeys, metricKey]),
      samples: numberOfPoints,
      ...(xStepRange != null
        ? {
            minStep: xStepRange.min,
            maxStep: xStepRange.max,
          }
        : {}),
    }));

    // order of specificity:
    // if an explicit limit is on the config (such as if a user has set the max runs value in the UI), use that
    // otherwise default to the defaultMaxRuns value which can vary by custom default
    result.page = {
      size: singleRun ? 1 : runsLinePlotConfig.limit ?? defaultMaxRuns,
    };

    if (isGrouped) {
      // We need the metadata for grouping because we do it locally
      // TODO: move grouping to server
      // result.disableMeta = false;

      // optionally compute group statistics over all runs instead of sub-sampling
      result.page.size =
        runsLinePlotConfig.groupRunsLimit ?? DEFAULT_MAX_GROUP_RUNS;
      // Disable grouping for this query, we'll do it ourselves.
      result.queries = result.queries.map(q => ({...q, grouping: []}));
    }
  }

  return result;
}

export function defaultTitle(
  runsLinePlotConfig: Pick<RunsLinePlotConfig, 'metrics' | 'expressions'>
): string {
  // eslint-disable-next-line no-extra-boolean-cast
  const metricsInUse = Boolean(runsLinePlotConfig.expressions?.[0])
    ? runsLinePlotConfig.expressions
    : runsLinePlotConfig.metrics;

  return metricsInUse?.join(', ') ?? '';
}

export function getHasSystemMetrics(
  runsLinePlotConfig: Pick<RunsLinePlotConfig, 'metrics'>
): boolean {
  if (runsLinePlotConfig.metrics == null) {
    return false;
  }
  return runsLinePlotConfig.metrics.some(metric =>
    metric.startsWith('system/')
  );
}
// system metrics charts can't have _step or null xAxes, so for those charts we fall back to _runtime as the defaul
export function getDerivedXAxis(
  runsLinePlotConfig: Pick<RunsLinePlotConfig, 'metrics' | 'xAxis'>
): string {
  if (getHasSystemMetrics(runsLinePlotConfig)) {
    // system metrics have no '_step' to log by, so we use '_runtime'
    // as the default instead
    if (
      runsLinePlotConfig.xAxis == null ||
      runsLinePlotConfig.xAxis === '_step'
    ) {
      return '_runtime';
    }

    return runsLinePlotConfig.xAxis;
  }

  // all non system-metrics charts should use '_step' as the default
  if (runsLinePlotConfig.xAxis == null) {
    return '_step';
  }

  return runsLinePlotConfig.xAxis;
}

export function getTitleFromConfig(
  runsLinePlotConfig: RunsLinePlotConfig
): string {
  return runsLinePlotConfig.chartTitle || defaultTitle(runsLinePlotConfig);
}
