import {isNotNullOrUndefined} from '@wandb/weave/common/util/types';
import * as _ from 'lodash';

import * as Run from '../../../util/runs';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RunsLinePlotConfig, runsLinePlotConfigDefaults} from '../common';
import {getExpressionFields} from './../../../components/PanelExpressionOptions';
import {Expression} from './../../../util/expr';
import {legendTemplateFieldNames} from './../../../util/legend';
import {Query} from './../../../util/queryts';

export function getDisplayFieldsFromLegendFields(legendFields: string[]) {
  return legendFields.map(Run.keyFromString).filter(isNotNullOrUndefined);
}

export function getExtraFields(
  config: RunsLinePlotConfig,
  parsedExpressions: {
    expressions: Expression[] | undefined;
    xExpression: Expression | undefined;
  },
  query: Query
) {
  const legendFields = getLegendFields(config);

  const displayFields = [
    ...getDisplayFieldsFromLegendFields(legendFields),
    ...(query.grouping ?? []),
    ...(config.aggregate && config.groupBy
      ? [Run.key('config', config.groupBy)]
      : []),
  ];
  // We need to query grouping values for runsets if the runsets have grouping
  // since we do the grouping locally.
  for (const rs of query.runSets ?? []) {
    if (rs.grouping != null) {
      displayFields.push(...rs.grouping);
    }
  }

  const expressionFields = getExpressionFields(parsedExpressions);

  return _.uniq([...displayFields, ...expressionFields]);
}

export function getLegendFields(config: RunsLinePlotConfig) {
  const legendFields =
    config.legendFields || runsLinePlotConfigDefaults.legendFields;

  if (config.legendTemplate === null) {
    return legendFields;
  }

  const names = legendTemplateFieldNames(config.legendTemplate ?? '');

  const dedupedFields = new Set(names.concat(legendFields));

  return Array.from(dedupedFields);
}
