import {createAction} from 'typesafe-actions';

import {
  PanelBankFlowSectionConfig,
  SectionPanelSorting,
} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {GridLayout} from '../../../util/panelbankGrid';
import {LayedOutPanel} from '../../../util/panels';
import * as PanelTypes from '../panel/types';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as Types from './types';

// Toggle between 'flow' and 'grid' modes
export const toggleType = createAction(
  '@view/panelBankSectionConfig/toggleType',
  action => (ref: Types.Ref) => action({ref})
);

// Toggle expanded/collapsed state
export const toggleIsOpen = createAction(
  '@view/panelBankSectionConfig/toggleIsOpen',
  action => (ref: Types.Ref) => action({ref})
);

// Rename the section
export const updateName = createAction(
  '@view/panelBankSectionConfig/updateName',
  action => (ref: Types.Ref, newName: string) => action({ref, newName})
);

// Handles box-resizing and changing rows per page in flow sections ('standard layout')
export const updateFlowConfig = createAction(
  '@view/panelBankSectionConfig/updateFlowConfig',
  action =>
    (ref: Types.Ref, newFlowConfig: Partial<PanelBankFlowSectionConfig>) =>
      action({ref, newFlowConfig})
);

// Handles saving box layout in grid sections ('custom layout')
export const setGridLayout = createAction(
  '@view/panelBankSectionConfig/setGridLayout',
  action => (ref: Types.Ref, newGridLayout: GridLayout) =>
    action({ref, newGridLayout})
);

// Add a panel to this section by ref
export const addPanel = createAction(
  '@view/panelBankSectionConfig/addPanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're adding
      fatPanel?: boolean,
      callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
    ) =>
      action({ref, panelRef, fatPanel, callbackFn})
);

// Add a panel to this section by panel
export const addPanelWithoutRef = createAction(
  '@view/panelBankSectionConfig/addPanelWithoutRef',
  action =>
    (
      ref: Types.Ref,
      panel: LayedOutPanel, // the panel we're adding
      fatPanel?: boolean,
      callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
    ) =>
      action({ref, panel, fatPanel, callbackFn})
);

export const deletePanel = createAction(
  '@view/panelBankSectionConfig/deletePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're deleting
      panelBankConfigRef?: PanelBankConfigTypes.Ref // the panel bank config ref (optional, only neccessary if we're dealing with hidden panels)
    ) =>
      action({ref, panelRef, panelBankConfigRef})
);

export const duplicatePanel = createAction(
  '@view/panelBankSectionConfig/duplicatePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref // the panel we're duplicating
    ) =>
      action({ref, panelRef})
);

export const sortPanels = createAction(
  '@view/panelBankSectionConfig/sortPanels',
  action => (refs: Types.Ref[]) => action(refs)
);

export const setSectionPanelRefsAndUndoSortingSetting = createAction(
  '@view/panelBankSectionConfig/setSectionPanelRefsAndUndoSortingSetting',
  action =>
    (
      refs: Types.Ref[],
      orderedPanelRefs: PanelTypes.Ref[][],
      sectionSortings: SectionPanelSorting[]
    ) =>
      action({refs, orderedPanelRefs, sectionSortings})
);

export const insertUpdatedPanel = createAction(
  '@view/panelBankSectionConfig/insertUpdatedPanel',
  action =>
    (ref: Types.Ref, fromPanelRef: PanelTypes.Ref, panelRef: PanelTypes.Ref) =>
      action({ref, fromPanelRef, panelRef})
);

export const undoInsertUpdatedPanel = createAction(
  '@view/panelBankSectionConfig/undoInsertUpdatedPanel',
  action =>
    (ref: Types.Ref, panel: PanelTypes.Panel, panelRef: PanelTypes.Ref) =>
      action({ref, panel, panelRef})
);

export const updateLinePlotSectionSettings = createAction(
  '@view/sectionSettings/linePlot/update',
  action => (ref: Types.Ref, settings: Partial<LinePlotSettings> | undefined) =>
    action({ref, settings})
);
