import {capitalizeFirst} from '@wandb/weave/common/util/string';
import {DateFromUTCString} from '@wandb/weave/common/util/time';
import {format} from 'date-fns';
import React, {memo, useMemo} from 'react';
import {CellInfo} from 'react-table';

import WBReactTable from '../../../../components/WBReactTable';
import {
  StripeInvoiceStatus,
  useOrganizationSubscriptionInfoQuery,
} from '../../../../generated/graphql';
import * as S from './InvoicesTable.styles';

const InvoicesUnpaidTableComp = ({orgID}: {orgID: string}) => {
  const organizationQuery = useOrganizationSubscriptionInfoQuery({
    variables: {organizationId: orgID},
  });

  const organization = organizationQuery.data?.organization;

  const unpaidStripeInvoices = organization?.stripeInvoices
    ?.filter(invoice => invoice.status !== StripeInvoiceStatus.Paid)
    ?.sort(
      (a, b) =>
        DateFromUTCString(b.created).getTime() -
        DateFromUTCString(a.created).getTime()
    );

  const moreThanOnePendingInvoice =
    unpaidStripeInvoices && unpaidStripeInvoices.length > 1;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Date',
        accessor: 'date',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const date = cellInfo.value;
          try {
            const formatted = format(DateFromUTCString(date), 'MM/dd/yyyy');
            return (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                {formatted}
              </div>
            );
          } catch (e) {
            console.error(e);
            return null;
          }
        },
      },
      {
        Header: 'Plan',
        accessor: 'planNames',
        minWidth: 130,
        Cell: (cellInfo: CellInfo) => {
          const planNames = cellInfo.value;
          const displayPlanNames = planNames.join(', ');
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {displayPlanNames}
            </div>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const {total, currency} = cellInfo.value;
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
          });

          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {formatter.format(total)} {currency.toUpperCase()}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const status = cellInfo.value;
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <S.InvoiceStatusTag status={status}>
                {capitalizeFirst(status)}
              </S.InvoiceStatusTag>
            </div>
          );
        },
      },
      {
        Header: 'Download',
        accessor: 'actions',
        minWidth: 60,
        Cell: (cellInfo: CellInfo) => {
          const {invoicePDF, organizationName} = cellInfo.value;

          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <a
                href={invoicePDF}
                onClick={() => {
                  window.analytics?.track('Download Invoice Button Clicked', {
                    location: 'invoices table',
                    organizationName,
                  });
                }}
                download>
                <S.ActionIcon name="download-new" />
              </a>
            </div>
          );
        },
      },
    ];

    return baseColumns;
  }, []);

  const rows =
    unpaidStripeInvoices?.map(invoice => {
      const {
        number: invoiceNumber,
        created,
        total,
        status,
        currency,
        invoicePDF,
        subscription,
      } = invoice;

      return {
        searchString: '',
        row: {
          date: created,
          planNames:
            subscription?.organizationSubscriptions.map(
              organizationSubscription =>
                organizationSubscription.plan.displayName
            ) ?? [],
          amount: {total, currency},
          status: status ?? '',
          actions: {
            invoicePDF,
            organizationName: organization?.name,
          },
        },
      };
    }) ?? [];

  if (organizationQuery.loading) {
    return <S.LoadingInvoicesTable />;
  }

  if (
    organization?.stripeInvoices == null ||
    organization?.stripeInvoices.length === 0
  ) {
    return <span>No invoices on file.</span>;
  }

  return (
    <div style={{color: 'black', width: '100%'}}>
      <S.Header>
        <S.Title>
          Failed {moreThanOnePendingInvoice ? 'Payments' : 'Payment'} to be
          retried
        </S.Title>
      </S.Header>
      <WBReactTable
        dataTest="unpaid-invoices-table"
        data={rows}
        columns={columns}
        noSearch={true}
        noHeader={true}
      />
    </div>
  );
};

export const InvoicesUnpaidTable = memo(InvoicesUnpaidTableComp);
