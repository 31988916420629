import {produce} from 'immer';
import {uniqBy} from 'lodash';
import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {RunDiff} from '../../generated/graphql';
import {fromServerResult, getLastUpdatedWithWindow} from '../../util/runDeltas';
import * as Run from '../../util/runs';
import {parseGqlDeltaOp} from '../runs-low/api';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as Actions from './actions';

type GroupedData = {
  lastUpdatedAt: string;
  runs: Run.Run[];
  totalRuns: number;
};

export const EMPTY_GROUPED_DATA: GroupedData = {
  lastUpdatedAt: new Date(0).toISOString(),
  runs: [],
  totalRuns: 0,
};

export const ROOT_GROUP_ID = '__ROOT__';

export type StateType = {
  /**
   * Run selector supports grouping, so each key in this state object represents
   * the results of an expanded grouped run. The root level results are saved
   * under a special ROOT_GROUP_ID, and will be the only "group" present if no
   * sub-groups are expanded, or if no grouping is applied at all.
   */
  [groupId: string]: GroupedData;
};

export type ActionType = TSActionType<typeof Actions>;

export function runSelectorRuns(state: StateType = {}, action: ActionType) {
  switch (action.type) {
    case getType(Actions.processQueryResults): {
      const {groupId, data} = action.payload;
      const existing = state[groupId] ?? EMPTY_GROUPED_DATA;
      const incoming = {
        delta: data.delta.map(d => parseGqlDeltaOp(d as RunDiff)),
        totalRuns: data.totalCount,
      };
      const combined = fromServerResult(incoming, existing);
      combined.runs = uniqBy(combined.runs, 'id');
      combined.lastUpdatedAt = getLastUpdatedWithWindow(combined.lastUpdatedAt);
      return {
        ...state,
        [groupId]: combined,
      };
    }
    case getType(Actions.removeGroup): {
      const {groupId} = action.payload;
      if (!state[groupId]) {
        return state;
      }
      const nextState = Object.assign({}, state);
      delete nextState[groupId];
      return nextState;
    }
    case getType(Actions.deleteRun): {
      const {runName} = action.payload;
      // search each group for the deleted run
      for (const groupId of Object.keys(state)) {
        const group = state[groupId];
        const runIdx = group.runs.findIndex(run => run.name === runName);
        if (runIdx > -1) {
          // run was found; remove immutably and return new state
          const runsBefore = group.runs.slice(0, runIdx);
          const runsAfter = group.runs.slice(runIdx + 1);
          const runsWithoutTarget = runsBefore.concat(runsAfter);
          const newState = Object.assign({}, state);
          newState[groupId] = Object.assign({}, group);
          newState[groupId].runs = runsWithoutTarget;
          return newState;
        }
      }
      // run was not found; return state as-is
      return state;
    }
    case getType(Actions.updateRun): {
      const {id: runName, vars} = action.payload;
      // search each group for the updated run
      for (const groupId of Object.keys(state)) {
        const group = state[groupId];
        const runIdx = group.runs.findIndex(run => run.name === runName);
        if (runIdx > -1) {
          // run was found; apply immutable updates and return new state
          const run = group.runs[runIdx];
          const updatedRun = Object.assign({}, run, vars);
          const runsBefore = group.runs.slice(0, runIdx);
          const runsAfter = group.runs.slice(runIdx + 1);
          return Object.assign({}, state, {
            [groupId]: Object.assign({}, group, {
              runs: runsBefore.concat(updatedRun).concat(runsAfter),
            }),
          });
        }
      }
      // run was not found; return state as-is
      return state;
    }
    case getType(Actions.updateRunTags): {
      const {tagMap} = action.payload;
      return produce(state, draft => {
        Object.keys(tagMap).forEach(runName => {
          const tags = tagMap[runName];
          Object.values(draft).forEach(draftGroup => {
            const draftRun = draftGroup.runs.find(run => run.name === runName);
            if (draftRun) {
              draftRun.tags = tags;
            }
          });
        });
      });
    }
    default: {
      return state;
    }
  }
}
