import {xAxisLabels, XAxisValues} from '../../../util/plotHelpers';

export const DEFAULT_ORGANZED_SETTINGS = {};

export const DEFAULT_X_AXIS_OPTION = {
  value: XAxisValues.Step,
  label: xAxisLabels[XAxisValues.Step],
};

export const DEFAULT_X_AXIS_SETTINGS = {
  xAxis: XAxisValues.Step,
  xAxisMin: undefined,
  xAxisMax: undefined,
};

export const DEFAULT_X_AXIS_OPTION_VALUES: string[] = [
  XAxisValues.RunTime,
  XAxisValues.AbsoluteRunTime,
  XAxisValues.Timestamp,
  XAxisValues.Step,
];
