// These are utils shared to parse workspace and report view specs

import {ID} from '@wandb/weave/common/util/id';

import {
  PanelBankConfig,
  PanelBankConfigState,
  PanelBankSectionConfig,
  PanelBankSettings,
} from '../components/PanelBank/types';
import {OrganizedSettings} from '../components/WorkspaceDrawer/Settings/types';
import {getOrganizedSettings} from '../components/WorkspaceDrawer/Settings/utils';
import * as Filter from './filters';
import {
  EMPTY_PANEL_BANK_CONFIG,
  EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
  getDefaultPanelSectionConfig,
} from './panelbankConfigs';
import {GRID_COLUMN_COUNT} from './panelbankGrid';
import {PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME} from './panelbankTypes';
import * as Panels from './panels';
import * as PanelSettings from './panelsettings';
import {CREATED_AT_DESC, DEFAULT_RUNS_SORT, Sort, SortKey} from './queryts';
import * as RunFeed from './runfeed';
import * as Run from './runs';
import * as Section from './section';
import * as SelectionManager from './selectionmanager';
import * as TableCols from './tablecols';

export function runSetConfigFromJSON(
  runsetNamePrefix: string,
  json: any,
  i: number
): Section.RunSetConfig | undefined {
  if (json != null) {
    // TODO: Call respective fromJSON methods to validate all these fields.

    // WARNING!!: If you change how this works it will probably reset all grouped run colors everywhere.
    // This is because, for the purposes of run coloring, the runsetID is part of the run name.
    // Consider a migration instead, or decide if resetting group colors isn't a big deal.
    const runsetID = Section.runsetIdFromNameParts(
      runsetNamePrefix,
      `Subsection ${i + 1}`
    );
    const filters = filtersFromJSON(json.filters);
    return {
      ...SelectionManager.fromJSON(json),
      id: json.id || runsetID,
      project: json.project,
      filters,
      runFeed:
        (json.runFeed && TableCols.maybeTruncateTableSettings(json.runFeed)) ||
        RunFeed.EMPTY_CONFIG,
      search: json.search ?? {query: ''},
      sort: migrateToMultipleSortKeys(json.sort) ?? CREATED_AT_DESC,
      name: json.name || `Run set ${i + 1}`,
      enabled: json.enabled != null ? json.enabled : true,
    };
  }
  return undefined;
}

function filtersFromJSON(json: any): Filter.RootFilter<Run.Key> {
  const filters = Filter.fixRunFilter(json);
  if (Filter.isRootFilter(filters)) {
    return filters;
  }

  // Otherwise, this is an old format, let's see if we can
  // rescue it.
  const simplified = Filter.simplifiedFiltersToValidFilters(
    Filter.simplify(filters)
  );
  if (Filter.isIndividual(simplified)) {
    return Filter.rootFilterSingle(simplified);
  } else if (Filter.isGroup(simplified)) {
    if (simplified.op === 'AND') {
      if (simplified.filters.every(Filter.isIndividual)) {
        // We have one and filter with no additional nesting
        return {
          op: 'OR',
          filters: [{op: 'AND', filters: simplified.filters}],
        };
      } else if (
        simplified.filters.length > 0 &&
        Filter.isGroup(simplified.filters[0]) &&
        simplified.filters[0].op === 'OR' &&
        simplified.filters.slice(1).every(Filter.isIndividual)
      ) {
        // This case is encountered production, and comes from our
        // old "spam filters" feature. The first child filter can be
        // an OR. To recover we drop the first child filter.
        return {
          op: 'OR',
          filters: [{op: 'AND', filters: simplified.filters.slice(1)}],
        };
      }
    }
  }
  // If we get here, we've encountered data that we don't know how to
  // convert. Inspect the user data that caused this error and consider
  // deleting it, it's probably very old.
  throw new Error('encountered invalid filters');
}

function migrateToMultipleSortKeys(s: Sort | SortKey | null): Sort | null {
  if (s == null) {
    return null;
  }
  if ('keys' in s) {
    if (s.keys.length === 0) {
      return DEFAULT_RUNS_SORT;
    }
    return s;
  }
  // we unintentionally store old client-side refs in the view spec
  delete (s as any).ref;
  return {keys: [s]};
}

export const migratePanelBankSettingsToPanelSettings = (
  curPanelBankSettings: PanelBankSettings,
  curPanelSettings?: PanelSettings.Settings
) => {
  if (!curPanelSettings) {
    // no op if no panel settings
    return {
      panelBankSettings: curPanelBankSettings,
      panelSettings: curPanelSettings,
    };
  }

  const newPanelBankSettings = {...curPanelBankSettings};
  const newPanelSettings = {...curPanelSettings};

  // for now, we'll just write the values onto panelSettings without
  // deleting them from panelBankSettings. once we're confident the
  // migration is going well and things are working smoothly, we can
  // come back and remove the deprecated values from PBS

  if (
    curPanelBankSettings.colorRunNames !== undefined &&
    curPanelSettings.colorRunNames === undefined
  ) {
    newPanelSettings.colorRunNames = curPanelBankSettings.colorRunNames;
  }
  if (curPanelBankSettings.maxRuns && !curPanelSettings.maxRuns) {
    newPanelSettings.maxRuns = curPanelBankSettings.maxRuns;
  }
  if (
    curPanelBankSettings.pointVisualizationMethod &&
    !curPanelSettings.pointVisualizationMethod
  ) {
    newPanelSettings.pointVisualizationMethod =
      curPanelBankSettings.pointVisualizationMethod;
  }
  if (
    curPanelBankSettings.suppressLegends !== undefined &&
    curPanelSettings.suppressLegends === undefined
  ) {
    newPanelSettings.suppressLegends = curPanelBankSettings.suppressLegends;
  }
  if (
    curPanelBankSettings.tooltipNumberOfRuns &&
    !curPanelSettings.tooltipNumberOfRuns
  ) {
    newPanelSettings.tooltipNumberOfRuns =
      curPanelBankSettings.tooltipNumberOfRuns;
  }

  return {
    panelBankSettings: newPanelBankSettings,
    panelSettings: newPanelSettings,
  };
};

// Converts the legacy "Custom Visualizations" (pinned panels) section to a PanelBank section
// Returns the new config
export function upgradeToPanelBank(
  legacyConfig?: Panels.Config,
  singlePanelBankSection?: boolean // this is true for reports
): PanelBankConfig | PanelBankSectionConfig {
  const panelBankConfig = EMPTY_PANEL_BANK_CONFIG;
  // If there's a custom viz section in state.panels, convert it to a PanelBank section (in state.panelBankConfig)
  const oldPanels = legacyConfig && legacyConfig.views[0].config;
  if (oldPanels && oldPanels.length > 0) {
    // create an empty section
    const newSection: PanelBankSectionConfig = {
      ...getDefaultPanelSectionConfig(),
      name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
      type: 'grid',
    };
    // For each panel in oldPanels, create a new panel, copying the config and layout
    const newPanels = oldPanels.map(p => {
      return {
        __id__: ID(),
        config: {...p.config},
        layout: upgradePanelLayoutV0toV1(p.layout),
        viewType: p.viewType,
      } as Panels.LayedOutPanel;
    });
    return singlePanelBankSection
      ? // Only return a single section (PanelBankSectionConfig)
        {...newSection, panels: [...newPanels]}
      : // Return a full PanelBankConfig
        {
          ...panelBankConfig,
          sections: [
            {...newSection, panels: [...newPanels]},
            ...panelBankConfig.sections,
          ],
        };
  }
  return singlePanelBankSection
    ? EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT
    : panelBankConfig;
}

// Panel grid layout conversion from V0 to V1
function upgradePanelLayoutV0toV1(
  panelLayoutV0: Panels.LayoutParameters
): Panels.LayoutParameters {
  const xScale = GRID_COLUMN_COUNT / getV0GridColumnCount();
  // Panels go from height 150px to 32px, and 150/32 rounds to 5.
  // Doing 32px instead of 30px to maintain multiples of 4.
  // This means panels will become slightly taller.
  const yScale = 5;
  return {
    x: panelLayoutV0.x * xScale,
    y: panelLayoutV0.y * yScale,
    w: panelLayoutV0.w * xScale,
    h: panelLayoutV0.h * yScale,
  };
}

function getV0GridColumnCount() {
  return Panels.PANEL_GRID_WIDTH;
}

// This is run in the loadFinished reducer, for run and multi-run workspaces.
// It returns a full PanelBank.
// Put all migrations to the PanelBankConfig object here.
export const migrateWorkspaceToPanelBank = (
  viewType:
    | 'run-view'
    | 'group-view'
    | 'project-view'
    | 'sweep-view'
    | 'runs'
    | 'runs/draft',
  existingPanelBankConfig?: PanelBankConfig | null,
  legacyConfig?: Panels.Config | null
): PanelBankConfig => {
  const pbc = existingPanelBankConfig || EMPTY_PANEL_BANK_CONFIG;

  const panelBankConfig =
    pbc && pbc.state === PanelBankConfigState.Init && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig) as PanelBankConfig)
      : pbc;

  return {
    ...panelBankConfig,
    sections: panelBankConfig.sections.map(s => {
      // We merge this to migrate the object (i.e., add any new attributes that have been added to panel section config)
      return {
        ...getDefaultPanelSectionConfig(),
        ...panelBankSectionFromJSON(s, viewType),
      };
    }),
  };
};

export const panelBankSectionFromJSON = (
  panelBankSectionConfig: PanelBankSectionConfig,
  viewType:
    | 'run-view'
    | 'group-view'
    | 'project-view'
    | 'sweep-view'
    | 'runs'
    | 'runs/draft'
): PanelBankSectionConfig => {
  let sectionSettings: OrganizedSettings | undefined;
  if (viewType !== 'runs' && viewType !== 'runs/draft') {
    sectionSettings =
      Object.keys(panelBankSectionConfig?.sectionSettings ?? {}).length === 0
        ? getOrganizedSettings(panelBankSectionConfig.localPanelSettings)
        : panelBankSectionConfig.sectionSettings;
  }

  return {
    ...panelBankSectionConfig,
    sectionSettings,
    panels: panelBankSectionConfig.panels.map(p =>
      Panels.panelFromJSON(p as any)
    ) as any,
  };
};
