// eslint-disable-next-line import/no-cycle -- please fix if you can
import {Line, Point} from './types';

export const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];

export function isTimeKey(k: string) {
  return TIME_KEYS.includes(k);
}

export const xAxisTimeKeys = [
  '_runtime',
  '_absolute_runtime',
  '_timestamp',
] as const;

export type XAxisTimeKey = (typeof xAxisTimeKeys)[number];

export function isXAxisTimeKey(k: string): k is XAxisTimeKey {
  return (xAxisTimeKeys as readonly string[]).includes(k);
}
export type Timestep = 'seconds' | 'minutes' | 'hours' | 'days';

const second = 1;
const minute = 60;
const hour = 3600;
const day = 86400;

export const timeInSeconds = {
  second,
  minute,
  hour,
  day,
};

/**
 * Returns "seconds", "minutes", "hours", "days" depending on the
 * max values of lines.  Only appropriate if the x axis is relative time.
 */
export function appropriateTimestep(lines: Line[]) {
  if (!lines || lines.length === 0) {
    return 'seconds' as Timestep;
  }
  let maxTime = 0;
  lines.forEach(l => {
    const last = l.data.slice(-1)[0];
    if (last && last.x > maxTime) {
      maxTime = last.x;
    }
  });
  if (maxTime < minute * 10) {
    return 'seconds' as Timestep;
  } else if (maxTime < hour * 10) {
    return 'minutes' as Timestep;
  } else if (maxTime < day * 100) {
    return 'hours' as Timestep;
  } else {
    return 'days' as Timestep;
  }
}

/**
 * Mutates the x-values of the points on a line based on the x-axis key and what conversion is needed
 */
export const mutateLinesByTimeType: Record<XAxisTimeKey, (lines: any) => void> =
  {
    _runtime: convertSecondsToTimestep,
    _absolute_runtime: convertSecondsToTimestep,
    _timestamp: convertTimestampLinesToMilliseconds,
  };

const timestepToFactorMap = {
  seconds: second,
  minutes: minute,
  hours: hour,
  days: day,
};

/**
 * Returns the "factor" of the time step compared to seconds
 * e.g. "hours" is "60 x 60" which is a factor of 3600 seconds
 */
export function timestepToFactor(timestep: Timestep) {
  return timestepToFactorMap[timestep] ?? timestepToFactorMap.seconds;
}

export function convertScalarSecondsToTimestep(
  time: number,
  timestep: Timestep
) {
  const factor = timestepToFactor(timestep);
  return time / factor;
}

/**
 * Given a time as a number, return the time in seconds based on its factor
 */
export function convertTimestepToSeconds(time: number, timestep: Timestep) {
  const factor = timestepToFactor(timestep);
  return time * factor;
}
/**
 * Converts all the xAxis values to minutes hours or days by dividing by "factor"
 */
export function convertSecondsToTimestep(lines: Line[], timestep?: Timestep) {
  if (timestep == null) {
    timestep = appropriateTimestep(lines);
  }
  const factor = timestepToFactor(timestep);
  lines.forEach(l => {
    (l.data as Point[]).forEach(p => {
      p.x = p.x / factor;
    });
    l.timestep = timestep;
  });
}

/**
 * Mutates the `x` value on on each line point to be in milliseconds
 * point.x = point.x * 1000;
 */
export function convertTimestampLinesToMilliseconds(lines: Line[]) {
  lines.forEach(line => {
    line.data.forEach(points => {
      points.x = points.x * 1000;
    });
  });
}
