import {ID} from '@wandb/weave/common/util/id';
import gql from 'graphql-tag';
import _ from 'lodash';
import {useEffect, useMemo, useRef, useState} from 'react';

import * as Generated from '../../generated/graphql';
import {RunHistoryKeyInfo} from '../../types/run';
import * as Filter from '../../util/filters';
import {useOPFSCache} from '../../util/opfs';
import {perfStat} from '../../util/profiler';
import * as QueryTS from '../../util/queryts';
import {useRampFlagDisableUpdateHistoryKeysInfo} from '../../util/rampFeatureFlags';
import * as Run from '../../util/runs';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useDispatch} from '../hooks';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as RunsLowActions from '../runs-low/actions';
import {useQuery} from './query';

export interface HistoryKeyInfoQueryVars {
  entityName: string;
  projectName: string;
  filters: Filter.Filter<Run.Key>;
  sort: QueryTS.Sort;
}

export const HISTORY_KEYS_QUERY = gql`
  query HistoryKeys(
    $projectName: String!
    $entityName: String!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        historyKeys(format: PLAINTEXT)
        edges {
          node {
            id
            wandbConfig(keys: ["viz", "visualize"])
          }
        }
      }
    }
  }
`;

export const HISTORY_KEYS_QUERY_BY_PROJECT_ID = gql`
  query HistoryKeysByProjectId(
    $internalId: ID!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(internalId: $internalId) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        historyKeys(format: BITMAP)
        edges {
          node {
            id
            wandbConfig(keys: ["viz", "visualize"])
          }
        }
      }
    }
  }
`;

interface Data {
  project: {
    runs: {
      historyKeys: RunHistoryKeyInfo;
      edges: Array<{
        node: {
          wandbConfig?: string;
        };
      }>;
    };
  };
}

// For really big result sets, we set a much longer interval
const LONG_POLL_KEYS_THRESHOLD = 1000;

export interface VizMap {
  [key: string]: any;
}

function mergeViz(viz: Array<string | undefined>): VizMap {
  const res: VizMap = {};
  viz.forEach(vs => {
    if (vs == null) {
      return;
    }
    const parsed = JSON.parse(vs);
    if (parsed.viz == null && parsed.visualize == null) {
      return;
    }
    const v = parsed.viz;
    if (v != null) {
      Object.keys(v).forEach(k => {
        if (res[k] == null) {
          res[k] = v[k];
        }
      });
    }

    const visualize = parsed.visualize;
    if (visualize != null) {
      Object.keys(visualize).forEach(k => {
        if (res[k] == null) {
          res[k] = visualize[k];
        }
      });
    }
  });
  return res;
}

// WB-9640: A bug in the client is persisting inefficient user queries for custom charts
// Basically, summaryTable field is over-fetching because `tableColumns` arg is missing
// This function tries its best to find these malformed configs and adds the missing arg.
function patchVizMap(vizMap: VizMap): VizMap {
  const result = _.mapValues(vizMap, viz => {
    (function fixViz() {
      if (
        viz.panel_config?.panelDefId == null ||
        ![
          'wandb/bar/v0',
          'wandb/confusion_matrix/v1',
          'wandb/histogram/v0',
          'wandb/lineseries/v0',
          'wandb/line/v0',
          'wandb/area-under-curve/v0',
          'wandb/scatter/v0',
        ].includes(viz.panel_config?.panelDefId)
      ) {
        return;
      }

      // Collect used fields
      const fieldSettings = viz.panel_config?.fieldSettings;
      if (fieldSettings == null) {
        // Unexpected viz shape
        return;
      }
      const usedFields = Object.values(fieldSettings).filter(f => f != null);

      const queryFields = viz.panel_config?.userQuery?.queryFields[0]?.fields;
      if (queryFields == null) {
        // Unexpected viz shape
        return;
      }

      // Check above obviates null-chaining
      viz.panel_config.userQuery.queryFields[0].fields = queryFields.map(
        (field: any) => {
          (function fixQueryField() {
            if (field.name !== 'summaryTable') {
              // Only fix the summaryTable field's args
              return;
            }

            const args: Array<{name: string; value: any}> = field.args;
            if (args.find(arg => arg.name === 'tableColumns') != null) {
              // Already has a tableColumns arg
              return;
            }

            const tableKeyArg = args.find(arg => arg.name === 'tableKey');
            if (
              tableKeyArg == null ||
              !(tableKeyArg.value as string).endsWith('_table')
            ) {
              // This didn't have a tableKey arg, or it doesn't have the expected format!
              return;
            }

            field.args.push({name: 'tableColumns', value: usedFields});
          })();

          return field;
        }
      );
    })();

    return viz;
  });

  return result;
}

// For tests only
export {patchVizMap as __patchVizMap};

export type UseHistoryKeysQueryResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | ({
      loading: false;
      error: null;
    } & HistoryKeysResult);

type HistoryKeysResult = {
  historyKeyInfo: RunHistoryKeyInfo;
  viz: VizMap;
};

/*
How did we choose 500?
I did some quick tests with a large workspace to see what processing times were like:
no limits (~1300 keysets): 1250-1500ms
500 keySets takes about 500ms-750ms
100 keySets takes about 100-200ms

Results will vary across workspaces with different numbers of keys in keysets.

We're choosing to use a larger number here (meaning that we will run updateHistoryKeysInfo more
  frequently) because updateHistoryKeysInfo reduces load on servers by doing queryMerging.

If server load seems reasonable, then reducing the number of allowed key sets (or removing
  queryMerge altogether) is definitely an option.
*/
const UHKI_MAX_ALLOWED_KEY_SETS = 500;

// This query is polled, respecting the user polling settings and the page poll
// interval (stored in redux). If the query is expensive (determined by a simple
// threshold on the result size), we raise the poll interval to a much larger
// value, to reduce network overhead for users.
export function useHistoryKeysQuery(
  queryVars: HistoryKeyInfoQueryVars
): UseHistoryKeysQueryResult {
  const {entityName, projectName, filters, sort} = queryVars;
  const dispatch = useDispatch();
  const idRef = useRef(ID());
  const id = idRef.current;
  const [pollMultiplier, setPollMultiplier] = useState<number>(1);
  const [hasSeenLargeNumberOfKeys, setHasSeenLargeNumberOfKeys] =
    useState(false);

  const filtersStr = JSON.stringify(Filter.toMongo(filters));
  const orderStr = QueryTS.sortToOrderString(sort);
  const variables = useMemo(
    () => ({
      entityName,
      projectName,
      filters: filtersStr,
      order: orderStr,
    }),
    [entityName, projectName, filtersStr, orderStr]
  );
  const query = useQuery<Data, Generated.HistoryKeysQueryVariables>(
    Generated.HistoryKeysDocument,
    {
      variables,
      enablePolling: true,
      pollMultiplier,
    }
  );

  const initialLoading = query.initialLoading;
  const project = query.initialLoading ? null : query.data?.project;
  const historyKeyInfo = project?.runs.historyKeys;

  const resultFromServer: HistoryKeysResult | null = useMemo(() => {
    if (project == null || historyKeyInfo == null) {
      return null;
    }
    return {
      historyKeyInfo,
      viz: patchVizMap(
        mergeViz(project.runs.edges.map(e => e.node.wandbConfig))
      ),
    };
  }, [project, historyKeyInfo]);

  const result = useOPFSCache({
    namespace: `useHistoryKeysQuery`,
    key: variables,
    dataFromServer: resultFromServer,
    waitingForDataFromServer: initialLoading,
  });

  useEffect(() => {
    if (!initialLoading && historyKeyInfo != null) {
      const numKeys = Object.keys(historyKeyInfo.keys).length;
      setPollMultiplier(numKeys > LONG_POLL_KEYS_THRESHOLD ? 10 : 1);
    }
  }, [initialLoading, historyKeyInfo]);

  // Once we know a particular workspace has a large number of key sets,
  // we will no longer run updatehistorykeyinfo ever in this workspace,
  // regardlesss if the user changes the filter/etc
  if (
    (historyKeyInfo?.sets?.length ?? 0) > UHKI_MAX_ALLOWED_KEY_SETS &&
    !hasSeenLargeNumberOfKeys
  ) {
    setHasSeenLargeNumberOfKeys(true);
  }

  // Mirror history key info into redux so runs-low can use it for
  // query merging
  const shouldDisableUHKI = useRampFlagDisableUpdateHistoryKeysInfo();
  useEffect(() => {
    if (!shouldDisableUHKI && !initialLoading && historyKeyInfo != null) {
      // We don't always run updateHistoryKeyInfo since it can be an expensive
      // operation on the client - we've seen it take up to 1.5s in some large workspaces.
      // However, it does reduce server load by enabling query merging, so
      // we leave it on for workspaces with smaller numbers of keysets.
      const doUpdateHistoryKeyInfo =
        historyKeyInfo.sets.length <= UHKI_MAX_ALLOWED_KEY_SETS &&
        !hasSeenLargeNumberOfKeys;

      if (doUpdateHistoryKeyInfo) {
        dispatch(
          RunsLowActions.updateHistoryKeyInfo(id, queryVars, historyKeyInfo)
        );
      } else {
        // if we aren't updating history key info, ensure we don't have any old data
        dispatch(RunsLowActions.clearHistoryKeyInfo(id));
      }
    }
  }, [
    id,
    dispatch,
    queryVars,
    initialLoading,
    historyKeyInfo,
    hasSeenLargeNumberOfKeys,
    shouldDisableUHKI,
  ]);
  // remove history key info from redux on unmount
  useEffect(
    () => () => {
      dispatch(RunsLowActions.clearHistoryKeyInfo(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (historyKeyInfo == null) {
      return;
    }
    perfStat({
      name: 'workspace.keys_count',
      value: Object.keys(historyKeyInfo.keys ?? {}).length,
      interestingThreshold: 100,
    });
    perfStat({
      name: 'workspace.steps_count',
      value: historyKeyInfo.lastStep ?? 0,
      interestingThreshold: 1_000_000 /* p95 of this in Jan 2023 is 525k*/,
    });
    perfStat({
      name: 'workspace.keyset_count',
      value: historyKeyInfo.sets?.length,
      interestingThreshold: 500,
    });
  }, [historyKeyInfo]);

  return useMemo(() => {
    if (initialLoading && result == null) {
      return {loading: true, error: null};
    }
    if (!initialLoading && project == null) {
      return {
        loading: false,
        error: true,
      };
    }
    if (result == null) {
      throw new Error(`invalid state`);
    }
    return {
      loading: false,
      error: null,
      ...result,
    };
  }, [initialLoading, project, result]);
}
