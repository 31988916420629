import {zip} from '@wandb/weave/common/util/obj';
import * as _ from 'lodash';
import {createSelector} from 'reselect';

import {RootState} from '../../types/redux';
import {
  EMPTY_HISTORY_KEY_INFO,
  RunHistoryKeyInfo,
  RunHistoryRow,
  RunKeyInfo,
} from '../../types/run';
import {deepArrayEqual} from '../../util/compare';
import * as RunHelpers from '../../util/runhelpers';
import * as Run from '../../util/runs';
import {RunWithRunsetInfo} from '../runs/types';
import * as Aggregations from './aggregations';
import * as History from './history';
import * as Lib from './lib';
import * as Meta from './meta';
import * as Types from './types';

export const getRunsState = (state: RootState) => state.runs;

const getQueries = createSelector(getRunsState, runs => runs.queries);

const getQueryErrors = createSelector(getRunsState, runs => runs.queryErrors);

const getRuns = createSelector(getRunsState, runs => runs.runs);

const getPropsId = (state: RootState, props: {id: string}) => props.id;

export const getQueryResult = createSelector(
  getQueries,
  getQueryErrors,
  getRuns,
  getPropsId,
  (queries, queryErrors, runs, id) => {
    const query = getQueryByID(queries, queryErrors, id);
    return query != null ? query.result.map(runID => runs[runID]) : [];
  }
);

export const getTotalRuns = createSelector(
  getQueries,
  getQueryErrors,
  getPropsId,
  (queries, queryErrors, id) => {
    const query = getQueryByID(queries, queryErrors, id);
    return query != null ? query.totalRuns : 0;
  }
);

export const makeQueryListSelector = (queryIDs: string[]) => {
  let result: Array<ReturnType<typeof getQueryByID>>;
  return (state: RootState) => {
    const queries = getQueries(state);
    const queryErrors = getQueryErrors(state);
    const newResult = queryIDs.map(id => {
      const query = getQueryByID(queries, queryErrors, id);
      return query;
    });
    if (!deepArrayEqual(newResult, result)) {
      result = newResult;
    }
    return result;
  };
};

export const makeQueriesLoadingSelector = (queryIDs: string[]) => {
  return createSelector(
    makeQueryListSelector(queryIDs),
    // if a query is null it's because we're selecting it but the redux
    // action to add it hasn't been processed yet.
    queryList => queryList.some(q => q == null || q.loading)
  );
};

export const makeTotalRunsSelector = (queryIDs: string[]) => {
  return createSelector(makeQueryListSelector(queryIDs), queryList =>
    _.sum(queryList.map(q => (q != null ? q.totalRuns : 0)))
  );
};

type QueryFieldsForData = Pick<
  Types.Query,
  | 'runsetId'
  | 'runsetName'
  | 'historySpecs'
  | 'configKeys'
  | 'summaryKeys'
  | 'wandbKeys'
  | 'aggregationKeys'
>;

// Select/memoize just the query fields that we use to compute
// a RunsData result. This reduces rerendering. For example, if a
// user turns on visualize for a given run, we don't need to update
// any useRunsData hooks until the result comes back from the server>
// But if the makeRunsData selector depends on the entire query rather
// than just specific fields, it would update twice: once for the query
// change, and once for the data change.
export const makeQueryFieldsForDataSelector = (queryIDs: string[]) => {
  let result: Array<QueryFieldsForData | null>;
  return createSelector(makeQueryListSelector(queryIDs), queryList => {
    const newResult = queryList.map(q =>
      q != null
        ? {
            runsetId: q.query.runsetId,
            runsetName: q.query.runsetName,
            historySpecs: q.query.historySpecs,
            configKeys: q.query.configKeys,
            summaryKeys: q.query.summaryKeys,
            wandbKeys: q.query.wandbKeys,
            aggregationKeys: q.query.aggregationKeys,
          }
        : null
    );
    if (!_.isEqual(newResult, result)) {
      result = newResult;
    }
    return result;
  });
};

const makeQueryRunsFieldSelector = <
  K extends keyof Types.CachedRun,
  F extends Types.CachedRun[K]
>(
  queryIDs: string[],
  field: K,
  include: (q: Types.CachedQuery) => boolean
) => {
  let result: F[][];
  return (state: RootState) => {
    const queries = getQueries(state);
    const queryErrors = getQueryErrors(state);
    const allRuns = getRuns(state);
    const newResult = queryIDs.map(id => {
      const query = getQueryByID(queries, queryErrors, id);
      const shouldInclude = query != null ? include(query) : false;
      return (query != null ? query.result : []).map(runID =>
        shouldInclude ? allRuns[runID][field] : undefined
      );
    });
    if (!deepArrayEqual(newResult, result)) {
      result = newResult as F[][];
    }
    return result;
  };
};

const makeQueryRunsHistorySelector = (queryIDs: string[]) => {
  let result: RunHistoryRow[][][][];
  return (state: RootState) => {
    const queries = getQueries(state);
    const queryErrors = getQueryErrors(state);
    const allRuns = getRuns(state);
    const newResult = queryIDs.map(id => {
      const query = getQueryByID(queries, queryErrors, id);
      return (query != null ? query.result : []).map(runID =>
        query!.query.historySpecs
          ? History.getQueryHistoryResults(
              allRuns[runID].normalizedSampledHistory,
              query!.query.historySpecs
            )
          : []
      );
    });
    // the list of rows for a specific query+run+spec should be ref-equal, so
    // don't try to deep compare it
    if (!deepArrayEqual(newResult, result, 3)) {
      result = newResult;
    }
    return result;
  };
};

const makeQueryRunsAggregationsSelector = (
  queryIDs: string[],
  getKeys: (q: Types.CachedQuery) => string[] | undefined
) => {
  let result: Run.Aggregations[][];
  return (state: RootState) => {
    const queries = getQueries(state);
    const queryErrors = getQueryErrors(state);
    const allRuns = getRuns(state);
    const newResult = queryIDs.map(id => {
      const query = getQueryByID(queries, queryErrors, id);
      if (query == null) {
        return [];
      }
      const keys = getKeys(query);
      return query.result.map(runID =>
        Aggregations.getAggregationResult(
          allRuns[runID].normalizedAggregations,
          keys
        )
      );
    });
    if (!deepArrayEqual(newResult, result)) {
      result = newResult;
    }
    return result;
  };
};

const makeQueryRunsMetaSelector = (
  queryIDs: string[],
  field: 'normalizedConfig' | 'normalizedSummary' | 'normalizedWandb',
  getKeys: (q: Types.CachedQuery) => string[] | undefined
) => {
  let result: Run.KeyVal[][];
  return (state: RootState) => {
    const queries = getQueries(state);
    const queryErrors = getQueryErrors(state);
    const allRuns = getRuns(state);
    const newResult = queryIDs.map(id => {
      const query = getQueryByID(queries, queryErrors, id);
      if (query == null) {
        return [];
      }
      const keys = getKeys(query);
      return query.result.map(runID =>
        Meta.getMetaResult(allRuns[runID][field], keys)
      );
    });
    if (!deepArrayEqual(newResult, result)) {
      result = newResult;
    }
    return result;
  };
};

export interface RunsDataResult {
  base: RunWithRunsetInfo[];
  filtered: RunWithRunsetInfo[];
  filteredRunsById: {[key: string]: RunWithRunsetInfo};
  keyInfo: RunKeyInfo;
  histories: {
    data: Array<{
      name: string;
      displayName: string;
      history: RunHistoryRow[];
      keyInfo: RunHistoryKeyInfo;
    }>;
    keyInfo: RunHistoryKeyInfo;
  };
}

type RunsMemoArgs = [
  Types.RunIdentityFragment[][],
  Array<Array<Types.RunBasicFragment | undefined>>,
  Array<Array<Types.RunHistoryKeysFragment | undefined>>,
  Array<Array<Types.RunFullConfigFragment | undefined>>,
  Array<Array<Types.RunFullSummaryFragment | undefined>>,
  RunHistoryRow[][][][],
  Run.Aggregations[][],
  Run.KeyVal[][],
  Run.KeyVal[][],
  Run.KeyVal[][]
];

// Selects data in the format used by RunsDataLoader and friends.
export const makeRunsDataSelector = (queryIDs: string[]) => {
  let lastArgs: RunsMemoArgs;
  let result: RunsDataResult;
  return createSelector(
    makeQueryFieldsForDataSelector(queryIDs),
    makeQueryRunsFieldSelector(queryIDs, 'identity', () => true),
    makeQueryRunsFieldSelector(queryIDs, 'basic', q => !!q.query.enableBasic),
    makeQueryRunsFieldSelector(
      queryIDs,
      'historyKeys',
      q => !!q.query.enableHistoryKeyInfo
    ),
    makeQueryRunsFieldSelector(
      queryIDs,
      'fullConfig',
      q => !!q.query.fullConfig
    ),
    makeQueryRunsFieldSelector(
      queryIDs,
      'fullSummary',
      q => !!q.query.fullSummary
    ),
    makeQueryRunsHistorySelector(queryIDs),
    makeQueryRunsAggregationsSelector(queryIDs, q => q.query.aggregationKeys),
    makeQueryRunsMetaSelector(
      queryIDs,
      'normalizedConfig',
      q => q.query.configKeys
    ),
    makeQueryRunsMetaSelector(
      queryIDs,
      'normalizedSummary',
      q => q.query.summaryKeys
    ),
    makeQueryRunsMetaSelector(
      queryIDs,
      'normalizedWandb',
      q => q.query.wandbKeys
    ),
    (
      queryList,
      identityList,
      basicList,
      historyKeysList,
      fullConfigList,
      fullSummaryList,
      historyList,
      aggregationList,
      configList,
      summaryList,
      wandbList
    ) => {
      const currentArgs: RunsMemoArgs = [
        identityList,
        basicList,
        historyKeysList,
        fullConfigList,
        fullSummaryList,
        historyList,
        aggregationList,
        configList,
        summaryList,
        wandbList,
      ];
      if (!deepArrayEqual(lastArgs, currentArgs, 1)) {
        lastArgs = currentArgs;
        const runs: RunWithRunsetInfo[] = _.flatMap(
          queryList,
          (queryFields, i) => {
            if (queryFields == null) {
              return [];
            }
            const identities = identityList[i];
            const basics = basicList[i];
            const historyKeyss = historyKeysList[i];
            const fullConfigs = fullConfigList[i];
            const fullSummaries = fullSummaryList[i];
            const histories = historyList[i];
            const aggregations = aggregationList[i];
            const configs = configList[i];
            const summaries = summaryList[i];
            const wandbs = wandbList[i];

            const runsetInfo = {
              id: queryFields.runsetId,
              name: queryFields.runsetName,
            };

            // _.zip doesn't have typings for more than 5 lists, so use our
            // wrapped zip that just calls lodash but has the correct types.
            return zip(
              identities,
              basics,
              historyKeyss,
              fullConfigs,
              fullSummaries,
              histories,
              aggregations,
              configs,
              summaries,
              wandbs
            ).map(
              ([
                identity,
                basic,
                historyKey,
                fullConfig,
                fullSummary,
                history,
                aggs,
                config,
                summary,
                wandb,
              ]) => {
                return {
                  ...identity!.data,
                  ...Lib.fragmentData(basic, Lib.DEFAULT_BASIC_FRAGMENT),
                  ...Lib.fragmentData(
                    historyKey,
                    Lib.DEFAULT_HISTORY_KEYS_FRAGMENT
                  ),
                  ...Lib.fragmentData(fullConfig, {config}),
                  ...Lib.fragmentData(fullSummary, {summary}),
                  sampledHistory: history,
                  aggregations: aggs,
                  _wandb: wandb,
                  runsetInfo,
                };
              }
            );
          }
        );

        // LB: this isn't always unique in the case of grouping - I think we should change
        // the ID here from name to uniqueId
        const filteredRunsById: {[key: string]: RunWithRunsetInfo} = {};
        for (const run of runs) {
          filteredRunsById[run.name] = run;
        }

        const keyInfo = RunHelpers.runsToKeyInfo(runs);

        const runHistory = runs.map(run => {
          // sampledHistory is an array, each item in the array is the result for each
          // requested keySet passed in to sampledHistory. We just concatenate all these
          // arrays together here, since consumer code expects a single array. The items
          // in the concatenated array aren't properly ordered, but consumer code handles
          // this.
          const history =
            run.sampledHistory != null ? _.flatten(run.sampledHistory) : [];
          return {
            name: run.name,
            displayName: run.displayName,
            history,
            keyInfo: run.historyKeys || EMPTY_HISTORY_KEY_INFO,
          };
        });

        const runHistories = {
          data: runHistory,
          keyInfo: RunHelpers.mergeHistoryKeyInfos(
            runHistory
              .filter(rh => rh.keyInfo.keys && rh.keyInfo.sets)
              .map(rh => rh.keyInfo as RunHistoryKeyInfo) // Cast OK because of filter above.
          ),
        };

        result = {
          base: runs,
          filtered: runs, // duplicated because some consumers rely on this name
          filteredRunsById,
          keyInfo,
          histories: runHistories,
        };
      }
      return result;
    }
  );
};

function getQueryByID(
  queries: {[id: string]: Types.CachedQuery},
  queryErrors: {[id: string]: any},
  id: string
): Types.CachedQuery | null {
  const query = queries[id];
  if (query == null) {
    // Query doesn't exist yet
    return null;
  }
  const queryError = queryErrors[id];
  if (queryError != null) {
    if (queryError.networkError?.message !== 'Failed to fetch') {
      throw queryError;
    }
  }
  return query;
}
