import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panels';
import * as Query from '../../util/queryts';
import {CodeComparerConfig} from './common';

const PANEL_TYPE = 'Code Comparer';

const codeComparerTransformQuery = (query: Query.Query) => {
  const transformed = toRunsDataQuery(
    query,
    {selectionsAsFilters: true},
    {wandbKeys: ['code_path', 'session_history'], page: {size: 100}}
  );
  return transformed;
};

export const Spec: PanelSpec<typeof PANEL_TYPE, CodeComparerConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery: codeComparerTransformQuery,
  noEditMode: true,

  exportable: {
    api: true,
  },

  icon: 'panel-html',
};
