import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panels';
import * as QueryTS from '../../util/queryts';
import {PanelConfigSpecToProps} from '../property-editors/property-editors';
import {configSpec} from './common';

const PANEL_TYPE = 'Vega3';

function transformQuery(
  query: QueryTS.Query,
  config: PanelConfigSpecToProps<typeof configSpec>
) {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

export const Spec: PanelSpec<
  typeof PANEL_TYPE,
  PanelConfigSpecToProps<typeof configSpec>
> = {
  type: PANEL_TYPE,
  // eslint-disable-next-line import/no-cycle -- please fix if you can
  Component: React.lazy(() => import('./Component')),
  configSpec,
  useInspector: true,
  transformQuery,
};
