import {createAction} from 'typesafe-actions';

import {RunSelectorRunsQuery} from '../../generated/graphql';
// eslint-disable-next-line import/no-cycle -- please fix if you can
export {deleteRun, updateRun, updateRunTags} from '../runs-low/actions';

type QueryResultsData = NonNullable<
  NonNullable<RunSelectorRunsQuery['project']>['runs']
>;

export const processQueryResults = createAction(
  '@runSelectorRuns/processQueryResults',
  action => (groupId: string, data: QueryResultsData) => action({groupId, data})
);

export const removeGroup = createAction(
  '@runSelectorRuns/removeGroup',
  action => (groupId: string) => action({groupId})
);
