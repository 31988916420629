// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RootState} from '../../types/redux';
import {GroupPageNormalized} from '../views/groupPage/types';
import * as ViewsNormalize from '../views/normalize';
import {lookupPart} from '../views/normalizerSupport';
import * as PanelBankConfigTypes from '../views/panelBankConfig/types';
import * as PanelsTypes from '../views/panels/types';
import * as PanelSettingsTypes from '../views/panelSettings/types';
import {ProjectPageNormalized} from '../views/projectPage/types';
import {ReportPageNormalized} from '../views/report/types';
import {RunPageNormalized} from '../views/runPage/types';
import * as RunSetTypes from '../views/runSet/types';
import {SectionNormalized, SectionObjSchema} from '../views/section/types';
import {SweepPageNormalized} from '../views/sweepPage/types';
import * as ViewTypes from '../views/types';
import * as WorkspaceSettingTypes from '../views/workspaceSettings/types';
import * as Types from './types';

const INTERNAL_SERVER_ERROR = {
  errorCode: '500',
  userTitle: 'Error loading workspace',
  userMessage: 'This workspace could not be loaded.',
};

export type WorkspaceStateWithLoadingError<T> =
  | {
      status: 'loading' | 'error';
      error: Types.WorkspaceError | undefined;
      // having this be undefined seems unnecssary, but for useEffect
      // dependency lint checks, the prop needs to be present even if undefined
      data: undefined;
    }
  | {
      status: 'ready';
      data: T;
    };

export const getReadyData = <T>(
  state: WorkspaceStateWithLoadingError<T>
): T => {
  if (state.status !== 'ready' || state.data == null) {
    if (state.status === 'loading') {
      throw new Error('NW selectors invalid state - still loading');
    }
    if (state.status === 'error') {
      throw new Error(
        `NW selectors invalid state - error. ${state.error?.userTitle}(${
          state.error?.errorCode
        }): ${state.error?.userMessage} ${JSON.stringify(
          state.error?.debugContext
        )}}`
      );
    }
  }
  return state.data as T;
};

type ReduxWorkspaceStateWithLoadedView = Types.ReduxWorkspaceState & {
  viewRef: ViewTypes.ViewRef;
};
export function makeWorkspaceReduxStateSelector(workspaceID: string) {
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<ReduxWorkspaceStateWithLoadedView> => {
    const workspace = state.workspaces[workspaceID];
    // Order matters here - we don't have a loading field, so we are relying
    // on the error field to toggle off the "loading" status
    if (workspace?.error) {
      return {status: 'error', error: workspace.error, data: undefined};
    }
    if (workspace == null || workspace.viewRef == null) {
      return {status: 'loading', error: undefined, data: undefined};
    }
    return {
      status: 'ready',
      data: {...workspace, viewRef: workspace.viewRef},
    };
  };
}

export function makeWorkspaceViewSelector(workspaceID: string) {
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<{view: ViewTypes.LoadableView}> => {
    const workspaceState = workspaceReduxStateSel(state);
    if (workspaceState.status !== 'ready') {
      return workspaceState;
    }
    const workspace = workspaceState.data;

    const view = state.views.views[workspace.viewRef.id];
    if (view.loading) {
      return {status: 'loading', error: undefined, data: undefined};
    }
    if (!view) {
      console.error('invalid state - workspace view not found');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }
    return {
      status: 'ready',
      data: {view},
    };
  };
}

export function makeWorkspaceReduxStateWithViewSelector(workspaceID: string) {
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<
    {view: ViewTypes.LoadableView} & ReduxWorkspaceStateWithLoadedView
  > => {
    const workspaceState = workspaceReduxStateSel(state);
    if (workspaceState.status !== 'ready') {
      return workspaceState;
    }
    const workspace = workspaceState.data;

    const view = state.views.views[workspace.viewRef.id];
    if (view.loading) {
      return {status: 'loading', error: undefined, data: undefined};
    }
    if (!view) {
      console.error('invalid state - workspace view not found');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }
    return {
      status: 'ready',
      data: {...workspace, view},
    };
  };
}

export function makeWorkspaceSpecSelector<T extends Types.ViewTypes>(
  workspaceID: string,
  partType: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<{
    view: ViewTypes.LoadableView;
    fullSpec: ViewTypes.WholeFromTypeWithRef<
      'project-view' | 'group-view' | 'sweep-view' | 'run-view'
    >;
  }> => {
    const workspaceViewResult = workspaceViewSel(state);
    if (workspaceViewResult.status !== 'ready') {
      return workspaceViewResult;
    }

    const {view} = workspaceViewResult.data;
    const partRef = view.partRef;
    if (partRef == null || partRef.type !== partType) {
      console.error(
        'invalid state - unable to find normalized workspace data in redux'
      );
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }

    // coercing type since this is a workspace spec
    const fullSpec = ViewsNormalize.denormalize(
      state.views.parts,
      partRef
    ) as ViewTypes.WholeFromTypeWithRef<
      'project-view' | 'group-view' | 'sweep-view' | 'run-view'
    >;

    return {
      status: 'ready',
      data: {
        view,
        fullSpec,
      },
    };
  };
}

export function makeWorkspaceViewPartSelector<T extends Types.ViewTypes>(
  workspaceID: string,
  partType: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<{
    part:
      | ProjectPageNormalized
      | GroupPageNormalized
      | SweepPageNormalized
      | RunPageNormalized
      | ReportPageNormalized;
    partRef: ViewTypes.ViewPartRefs;
  }> => {
    const workspaceViewResult = workspaceViewSel(state);
    if (workspaceViewResult.status !== 'ready') {
      return workspaceViewResult;
    }

    const {view} = workspaceViewResult.data;
    const partRef = view.partRef;
    if (partRef == null || partType !== partRef.type) {
      console.error(
        'invalid state - unable to find normalized workspace data in redux'
      );
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }

    // asserting type here since we know what page types are allowed
    const part = lookupPart(state.views.parts, partRef);

    return {
      status: 'ready',
      data: {part, partRef},
    };
  };
}

// this doesn't seem like it adds much value, but combining the
const combineErrorAndLoading = <A, B>(
  stateOne: WorkspaceStateWithLoadingError<A>,
  stateTwo: WorkspaceStateWithLoadingError<B>
): WorkspaceStateWithLoadingError<A & B> => {
  // note that if they both have error set, we are dropping the second one and not trying to combine them
  if (stateOne.status !== 'ready') {
    return stateOne;
  }
  if (stateTwo.status !== 'ready') {
    return stateTwo;
  }
  return {status: 'ready', data: {...stateOne.data, ...stateTwo.data}};
};

export function makeMultiRunWorkspacePageSelector(
  workspaceID: string,
  type: 'project-view' | 'sweep-view' | 'group-view'
) {
  const workspaceViewPartSel = makeWorkspaceViewPartSelector(workspaceID, type);
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<
    MultiRunWorkspacePageData & GenericWorkspacePageData
  > => {
    const workspaceReduxState = workspaceReduxStateSel(state);
    const workspaceViewPart = workspaceViewPartSel(state);

    const combinedResult = combineErrorAndLoading(
      workspaceReduxState,
      workspaceViewPart
    );
    if (combinedResult.status !== 'ready') {
      return combinedResult;
    }
    const partRef = combinedResult.data.partRef as WorkspacePartRefTypes;
    const part = combinedResult.data.part as
      | ProjectPageNormalized
      | GroupPageNormalized
      | SweepPageNormalized;
    const sectionPart = lookupPart(state.views.parts, part.sectionRef);
    if (sectionPart.runSetRefs.length !== 1) {
      console.error('invalid state - no runset found for workspace');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }

    const runSetPart = lookupPart(state.views.parts, sectionPart.runSetRefs[0]);

    const panelsPart =
      sectionPart.panelsRef != null
        ? lookupPart(state.views.parts, sectionPart.panelsRef)
        : undefined;

    const panelBankConfigPart = lookupPart(
      state.views.parts,
      sectionPart.panelBankConfigRef
    );

    if (sectionPart.panelSettingsRef == null) {
      // We populate this by default, it should always be defined in
      // workspaces.
      console.error('invalid state - panels settings not found for workspace');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
        data: undefined,
      };
    }

    return {
      status: 'ready',
      data: {
        workspaceSettingsRef: sectionPart.workspaceSettingsRef,
        sectionRef: part.sectionRef,
        sectionPart,
        panelSettingsRef: sectionPart.panelSettingsRef,
        runSetRef: sectionPart.runSetRefs[0],
        runSetPart,
        panelsRef: sectionPart.panelsRef,
        panelsPart,
        panelBankConfigRef: sectionPart.panelBankConfigRef,
        panelBankConfigPart,
        part,
        partRef,
        ...calculateGenericWorkspacePageData(combinedResult.data),
      },
    };
  };
}

type WorkspacePartRefTypes =
  | ({
      type: 'project-view';
    } & ViewTypes.PartRefFields)
  | ({
      type: 'group-view';
    } & ViewTypes.PartRefFields)
  | ({
      type: 'sweep-view';
    } & ViewTypes.PartRefFields);

export type MultiRunWorkspacePageData = {
  sectionRef: ViewTypes.PartRefFromObjSchema<SectionObjSchema>;
  sectionPart: SectionNormalized;
  panelSettingsRef: PanelSettingsTypes.Ref;
  runSetRef: RunSetTypes.Ref;
  runSetPart: RunSetTypes.RunSetPart;
  panelsRef: PanelsTypes.Ref | undefined;
  panelsPart: PanelsTypes.PanelsNormalized | undefined;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankConfigPart: PanelBankConfigTypes.PanelBankConfigNormalized;
  part: ProjectPageNormalized | SweepPageNormalized | GroupPageNormalized;
  partRef: WorkspacePartRefTypes;
  workspaceSettingsRef: WorkspaceSettingTypes.Ref;
};

export type GenericWorkspacePageData = {
  nwId?: Types.NamedWorkspaceId;
};

const calculateGenericWorkspacePageData = (
  workspaceReduxState: Types.ReduxWorkspaceState
) => {
  const nwId = workspaceReduxState.nwId;
  return {
    nwId,
  };
};

type RunPagePartRef = {
  type: 'run-view';
} & ViewTypes.PartRefFields;

export type SingleRunWorkspacePageData = {
  workspaceSettingsRef: WorkspaceSettingTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelsRef: PanelsTypes.Ref | undefined;
  partRef: RunPagePartRef;
};
export function makeRunWorkspaceSelector(workspaceID: string) {
  const workspaceViewPartSel = makeWorkspaceViewPartSelector(
    workspaceID,
    'run-view'
  );
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStateWithLoadingError<
    SingleRunWorkspacePageData & GenericWorkspacePageData
  > => {
    const workspaceViewPart = workspaceViewPartSel(state);
    const workspaceReduxState = workspaceReduxStateSel(state);

    const combinedResult = combineErrorAndLoading(
      workspaceReduxState,
      workspaceViewPart
    );
    if (combinedResult.status !== 'ready') {
      return combinedResult;
    }
    // coerce type since we know what data we're querying
    const part = combinedResult.data.part as RunPageNormalized;

    return {
      status: 'ready',
      data: {
        workspaceSettingsRef: part.workspaceSettingsRef,
        panelBankConfigRef: part.panelBankConfigRef,
        panelsRef: part.panelsRef,
        partRef: combinedResult.data.partRef as RunPagePartRef,
        ...calculateGenericWorkspacePageData(combinedResult.data),
      },
    };
  };
}

export function makeWorkspaceFnwIdSelector(workspaceID: string) {
  return (state: RootState) => {
    const workspace = state.workspaces[workspaceID];

    return workspace?.nwId;
  };
}

export const makeWorkspaceErrorSelector = (workspaceId: string) => {
  return (state: RootState) => {
    const workspace = state.workspaces[workspaceId];

    return workspace?.error;
  };
};
