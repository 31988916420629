import type {BarChartConfig} from '../components/PanelBarChart';
import type {RunsLinePlotConfig} from '../components/PanelRunsLinePlot';
import {InjectedRunsDataProps} from '../containers/RunsDataLoader';
import type {Ref as RunSetViewRef} from '../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../types/run';
import * as Query from '../util/queryts';
import * as RunHelpers from '../util/runhelpers';
import * as SM from '../util/selectionmanager';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RunColorConfig} from './section';

export type PanelExportRef = {
  onDownloadSVG: (name: string) => Promise<void>;
  onDownloadPNG: (name: string) => Promise<void>;
  onDownloadPDF: (name: string) => Promise<void>;
};

export function isGrouped(
  query: Query.Query,
  config: RunsLinePlotConfig | BarChartConfig
) {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  if (
    config.useRunsTableGroupingInPanels ||
    config.useRunsTableGroupingInPanels == null
  ) {
    return (
      RunHelpers.isGrouped(query) || config.aggregate || config.aggregateMetrics
    );
  } else {
    return config.aggregate || config.aggregateMetrics;
  }
}

export function isSingleRun(props: {pageQuery: Query.Query}) {
  // runName is set in the query only if we are looking at a singleRun page
  return props.pageQuery.runName != null;
}

export type BasePanelProps = {
  configMode: boolean;
  initialConfigState?: {[key: string]: any};
  currentHeight: number;
  exportMode?: boolean;

  // This is undefined in render mode, but always defined in edit
  // mode
  // TODO: don't pass this in, let panels fetch it themselves when
  // they need it
  keyInfo?: RunHistoryKeyInfo;
  dimensions: {w: number; h: number};
  pageQuery: Query.Query;
  readOnly?: boolean;
  disableRunLinks?: boolean;
  customRunColors?: RunColorConfig;
  runSetRefs: RunSetViewRef[];
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  convertSelectionsToFilters?(
    selections: SM.PanelSelections,
    axes?: string[]
  ): void;
  onContentHeightChange?(h: number): void;
} & InjectedRunsDataProps;

export type PanelProps<ConfigType> = BasePanelProps & {
  config: ConfigType;
  updateConfig(config: Partial<ConfigType>): void;
};

export type PanelComponentType<ConfigType> = React.ComponentType<
  PanelProps<ConfigType>
>;
