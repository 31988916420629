import {Button, ButtonSize} from '@wandb/weave/components/Button';
import React, {AnchorHTMLAttributes, ReactNode} from 'react';

import {auth} from '../setup';
import {isUsingAccessToken} from '../util/projectAccess';

type LoginButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  size?: ButtonSize;
};

export const LoginButton: React.FC<LoginButtonProps> = ({size}) => {
  const isSignup = isUsingAccessToken();
  return (
    <Button
      data-test="login-button"
      className="night-mode"
      variant="ghost"
      size={size}
      onClick={() => auth.login({signup: isSignup})}>
      {isSignup ? `Sign up` : `Log in`}
    </Button>
  );
};

type SignupButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  text?: ReactNode;
  hideIcon?: boolean;
  size?: ButtonSize;
};

export const SignupButton: React.FC<SignupButtonProps> = ({hideIcon, size}) => {
  return (
    <Button
      data-test="signup"
      variant="primary"
      size={size}
      endIcon={hideIcon ? undefined : 'forward-next'}
      onClick={() => auth.login({signup: true})}>
      Sign up
    </Button>
  );
};
