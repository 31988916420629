import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panels';
import * as Query from '../../util/queryts';
import {VegaPanelConfig} from './common';
export type {VegaPanelConfig};

const PANEL_TYPE = 'Vega';

function transformQuery(query: Query.Query, config: VegaPanelConfig) {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

export const Spec: PanelSpec<typeof PANEL_TYPE, VegaPanelConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
};
