/**
 * Select a user, with autocompletion from backend.
 */

import {SelectAsync, SelectSize} from '@wandb/weave/components/Form/Select';
import {ApolloQueryResult} from 'apollo-client';
import * as _ from 'lodash';
import React, {useCallback} from 'react';
import {FormatOptionLabelMeta} from 'react-select';

import {apolloClient} from '../apolloClient';
import {UserEdge} from '../generated/graphql';
import {FIND_USERS_BY_USERNAME_QUERY, FIND_USERS_QUERY} from '../graphql/users';
import * as S from './SelectUser.styles';

export type UserOption = {
  value: string;
  label: string;
  photoUrl: string | null;
  name: string | null;
  username: string | null;
  email: string | null;
  deactivatedAt: Date | null;
  isDisabled: boolean;
};

const userEdgeToOption = (edge: UserEdge): UserOption => {
  const {node} = edge;
  const value = node?.id ?? '';
  const name = node?.name ?? null;
  const username = node?.username ?? null;
  const email = node?.email ?? null;
  const deactivatedAt = node?.deletedAt ?? null;
  const isDisabled = username ? deactivatedAt != null : true;
  return {
    value,
    label: name ?? value,
    photoUrl: node?.photoUrl ?? null,
    name,
    username,
    email,
    deactivatedAt,
    isDisabled,
  };
};

const responseToOptions = (result: ApolloQueryResult<any>): UserOption[] => {
  return result.data.users.edges.map(userEdgeToOption);
};

const loadOptions = (
  inputValue: string,
  callback: (options: UserOption[]) => void
) => {
  // Ideally the backend users query API would be more flexible or have
  // better ranking for these purposes. For now, just do two queries,
  // one query for an exact username match (make it the top result if found)
  // and one query for a substring match on username, name, or email.
  const searchText = inputValue.trim();
  const promises = [];
  if (searchText) {
    promises.push(
      apolloClient.query({
        query: FIND_USERS_BY_USERNAME_QUERY,
        variables: {
          usernames: [searchText],
          limit: 1,
        },
      })
    );
  }
  promises.push(
    apolloClient.query({
      query: FIND_USERS_QUERY,
      variables: {
        query: searchText,
        limit: 20,
      },
    })
  );
  Promise.all(promises).then((results: Array<ApolloQueryResult<any>>) => {
    const sorted = results
      .map(r => _.sortBy(responseToOptions(r), ['isDisabled', 'label']))
      .flat();
    const unique = _.uniqBy(sorted, 'value');
    callback(unique);
  });
};

const debouncedLoad = _.debounce(loadOptions, 300);

type SelectUserProps = {
  size?: SelectSize;
  onSelectUser?: (user: UserOption | null) => void;
};

export const SelectUser = ({
  size = 'medium',
  onSelectUser,
}: SelectUserProps) => {
  const formatOptionLabel = useCallback(
    (
      {photoUrl, name, username, email, deactivatedAt, isDisabled}: UserOption,
      meta: FormatOptionLabelMeta<UserOption>
    ) => {
      const isValue = meta.context === 'value'; // selected value, not menu
      return (
        <S.UserOption size={size} isValue={isValue}>
          <span style={{opacity: isDisabled ? 0.3 : 'inherit'}}>
            {photoUrl && (
              <S.AvatarImage size={size} $isValue={isValue} src={photoUrl} />
            )}
          </span>
          <div>
            {(name || username) && (
              <div>
                {name && <S.UserName>{name}</S.UserName>}
                {username && (
                  <S.UserUsername isDisabled={isDisabled}>
                    {username}
                  </S.UserUsername>
                )}
                {deactivatedAt && ' (deactivated)'}
              </div>
            )}
            {!isValue && (
              <S.UserEmail isDisabled={isDisabled}>
                {email}
                {!username && ' (incomplete signup)'}
              </S.UserEmail>
            )}
          </div>
        </S.UserOption>
      );
    },
    [size]
  );

  const onChange = useCallback(
    (option: UserOption | null) => {
      if (!onSelectUser) {
        return;
      }
      onSelectUser(option);
    },
    [onSelectUser]
  );

  return (
    <SelectAsync<UserOption>
      cacheOptions
      loadOptions={debouncedLoad}
      defaultOptions
      formatOptionLabel={formatOptionLabel}
      placeholder="Select user..."
      onChange={onChange}
      isClearable={true}
      size={size}
    />
  );
};
