import {PanelBankSectionConfig} from '../../components/PanelBank/types';
import * as Panels from '../panels';
import {panelBankSectionFromJSON, upgradeToPanelBank} from '../parseSpecUtils';
import {ReportSpecVersion} from './shared';

// This is run in the loadFinished reducer, for reports.
// (A report section's panels = a single PanelBankSection)
// It returns a single PanelBankSection
// Put all migrations to the PanelBankSectionConfig object here.
export const migrateReportToPanelBank = (
  viewType: 'runs' | 'runs/draft',
  existingPanelBankSectionConfig: PanelBankSectionConfig,
  legacyConfig: Panels.Config | null,
  specVersion: ReportSpecVersion
): PanelBankSectionConfig => {
  const panelBankSectionConfig =
    specVersion === ReportSpecVersion.V0 && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig, true) as PanelBankSectionConfig)
      : existingPanelBankSectionConfig; // no-op

  return panelBankSectionFromJSON(panelBankSectionConfig, viewType);
};
