import React, {createContext, useContext} from 'react';

import * as Filter from '../../util/filters';

type BaseFilterActions = {
  deleteFilter: (index: number) => void;
  setFilter: (
    index: number,
    newFilterValue: Filter.IndividualFilter<Filter.FilterKey>
  ) => void;
};

type ExtendedFilterActions = BaseFilterActions & {
  getFilter: (index: number) => Filter.IndividualFilter<Filter.FilterKey>;
};
export type TagOperators = '=' | '!=' | 'IN' | 'NIN';

// @ts-ignore
const EditableFilterContext = createContext<ExtendedFilterActions>({});

export const EditableFilterContextProvider = ({
  actions,
  children,
  filters,
}: {
  actions: BaseFilterActions;
  children: React.ReactNode;
  filters: Array<Filter.IndividualFilter<Filter.FilterKey>>;
}) => {
  return (
    <EditableFilterContext.Provider
      value={{
        ...actions,
        getFilter: (index: number) => filters[index],
      }}>
      {children}
    </EditableFilterContext.Provider>
  );
};

export function useEditableFilterContext(filterIndex: number) {
  const context = useContext(EditableFilterContext);
  if (!context) {
    console.log('Editable Filter Used Outside of Context Tree');
  }

  /**
   * If the context changes or the filter changes the render cycle will fire so we don't need internal state to handle the filter selection
   */
  const filter = React.useRef(context.getFilter(filterIndex));
  filter.current = context.getFilter(filterIndex);

  return [
    {
      filter: filter.current,
      isMultiValueFilter: Filter.isMultiValue(filter.current),
    },
    {
      changeFilterOperation: (
        newFilterOperation: keyof typeof Filter.FunctionalOperators
      ) => {
        /**
         * The logic here is complicated because the value type can change depending on if the Op is a single-or-multiple value operation.
         */
        const opsToValueMap: Record<TagOperators, boolean | string[]> = {
          '=': true,
          '!=': false,
          IN: [],
          NIN: [],
        };
        context.setFilter(filterIndex, {
          ...filter,
          key: {
            section: 'tags',
            name: '',
          },
          op: newFilterOperation as TagOperators,
          // @ts-ignore
          value: opsToValueMap[newFilterOperation],
        });
      },
      deleteFilter: () => context.deleteFilter(filterIndex),
      disableFilter: () => {
        // @ts-ignore ignoring this as this definitely exists but the types don't guarantee
        context.setFilter(filterIndex, {
          ...filter.current,
          disabled: !filter.current.disabled,
        });
      },
      setFilterValueIndividual: (newValue: string) => {
        // @ts-ignore ignoring this as this definitely exists but the types don't guarantee
        context.setFilter(filterIndex, {
          ...filter.current,
          key: {
            // @ts-ignore ignoring this as this definitely exists but the types don't guarantee
            section: filter.current.key.section,
            name: newValue,
          },
        } as Filter.Filter<Filter.FilterKey>);
      },
      // We strip out the "*" when reading in the filter value to the input to avoid rending an accidental tag
      // however we have to reattach it on the way back out as the view won't update without it
      setFilterValueMulti: (newValue: string[]) => {
        // @ts-ignore ignoring this as this definitely exists but the types don't guarantee
        context.setFilter(filterIndex, {
          ...filter.current,
          key: {
            section: 'tags',
            name: '*',
          },
          value: [...newValue],
        } as Filter.Filter<Filter.FilterKey>);
      },
    },
  ] as const;
}
