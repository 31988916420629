import {isEqual} from 'lodash';

import {Run} from '../../../util/runs';
import * as SM from '../../../util/selectionmanager';
import {setInShallowClone} from '../../../util/utility';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as ActionsInternal from './actionsInternal';
import * as Types from './types';

export const toggleSelection = (
  state: ViewReducerState,
  ref: Types.Ref,
  run: Run,
  depth: number
): [ViewReducerState, ActionType] => {
  const prevGroupSelections = state.parts[ref.type][ref.id];
  const newGroupSelections = SM.toggleSelection(
    prevGroupSelections,
    run,
    depth
  );
  const newState = setInShallowClone(
    state,
    ['parts', ref.type, ref.id],
    newGroupSelections
  );

  // If we're turning on an eyeball, ensure that the runSet is also
  // enabled. Users get confused when the runset is disabled, it's hard to figure
  // out why nothing shows up on charts.
  if (SM.getCheckedState(newGroupSelections, run, depth) === 'checked') {
    const runSetKeyToEnable = Object.keys(state.parts.runSet).find(key => {
      const runSet = state.parts.runSet[key];
      return !runSet.enabled && isEqual(runSet.groupSelectionsRef, ref);
    });
    if (runSetKeyToEnable) {
      newState.parts.runSet = setInShallowClone(
        newState.parts.runSet,
        [runSetKeyToEnable, 'enabled'],
        true
      );
    }
  }

  const inverseAction = ActionsInternal.setGroupSelections(
    ref,
    prevGroupSelections
  );

  return [newState, inverseAction];
};
