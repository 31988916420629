// Functions for querying runs
import {notEmpty} from '@wandb/weave/common/util/obj';
import * as _ from 'lodash';

import * as Generated from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import * as Filter from '../../util/filters';
import {sortToOrderString} from '../../util/queryts';
import {ServerDeltaOp, ServerResultDelta} from '../../util/runDeltas';
import * as Run from '../../util/runs';
import {ApolloClient} from '../types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ServerQueryDelta} from './serverQuery_serverDelta';
import * as Types from './types';

// Convert a query into the variables needed to make the graphql request.
export function queryToGQLVars(
  query: Types.Query,
  filters: Filter.Filter<Run.Key>
): Generated.RunsStateQueryQueryVariables {
  return {
    aggregationKeys:
      query.fullAggregations === true ? undefined : query.aggregationKeys,
    configKeys: query.fullConfig === true ? undefined : query.configKeys,
    enableArtifactCounts: query.enableArtifactCounts,
    enableAggregations:
      query.aggregationKeys != null || query.fullAggregations === true,
    enableBasic: query.enableBasic || false,
    enableConfig: query.configKeys != null || query.fullConfig === true,
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableSampledHistory: query.historySpecs != null,
    enableSummary: query.summaryKeys != null || query.fullSummary === true,
    enableTags: query.enableTags,
    enableWandb: query.wandbKeys != null,
    entityName: query.entityName,
    filters: JSON.stringify(Filter.toMongo(filters)),
    groupKeys: query.grouping.map(Run.keyToServerPath),
    groupLevel: 0,
    limit: query.limit,
    order: sortToOrderString(query.sort),
    projectName: query.projectName,
    sampledHistorySpecs:
      query.historySpecs != null
        ? query.historySpecs.map(hs => JSON.stringify(hs))
        : [],
    summaryKeys: query.fullSummary === true ? undefined : query.summaryKeys,
    wandbKeys: query.wandbKeys,
  };
}

export function queryToGQLVarsWithProjectId(
  query: Types.Query,
  filters: Filter.Filter<Run.Key>
): Generated.RunsStateQueryWithProjectIdQueryVariables {
  return {
    aggregationKeys:
      query.fullAggregations === true ? undefined : query.aggregationKeys,
    configKeys: query.fullConfig === true ? undefined : query.configKeys,
    enableArtifactCounts: query.enableArtifactCounts,
    enableAggregations:
      query.aggregationKeys != null || query.fullAggregations === true,
    enableBasic: query.enableBasic || false,
    enableConfig: query.configKeys != null || query.fullConfig === true,
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableSampledHistory: query.historySpecs != null,
    enableSummary: query.summaryKeys != null || query.fullSummary === true,
    enableTags: query.enableTags,
    enableWandb: query.wandbKeys != null,
    filters: JSON.stringify(Filter.toMongo(filters)),
    groupKeys: query.grouping.map(Run.keyToServerPath),
    groupLevel: 0,
    limit: query.limit,
    order: sortToOrderString(query.sort),
    internalId: query.internalProjectId ?? '',
    sampledHistorySpecs:
      query.historySpecs != null
        ? query.historySpecs.map(hs => JSON.stringify(hs))
        : [],
    summaryKeys: query.fullSummary === true ? undefined : query.summaryKeys,
    wandbKeys: query.wandbKeys,
  };
}

const EMPTY_SINGLE_RUNS_QUERY_RESULT = {
  totalRuns: 0,
  runs: [],
  lastUpdatedAt: new Date(0).toISOString(),
};

export const doSingleRunsQuery = (client: ApolloClient, query: Types.Query) => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    return Promise.resolve(EMPTY_SINGLE_RUNS_QUERY_RESULT);
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }
  return client
    .query<Generated.RunsStateQueryQuery>({
      query: Generated.RunsStateQueryDocument,
      fetchPolicy: 'no-cache',
      variables: queryToGQLVars(query, filters),
      context: propagateErrorsContext(),
    })
    .then(result => {
      const project = result.data.project;
      if (project == null || project.runs == null) {
        throw new Error('Runs query failed with invalid project');
      }
      const nodes = project.runs.edges.map(e => {
        const parsedRun = Run.fromJson(e.node);
        if (parsedRun == null) {
          console.warn("Couldn't parse run from server: ", e.node);
        }
        return parsedRun;
      });
      return {
        totalRuns: project.runs.totalCount,
        runs: nodes.filter(notEmpty),
        lastUpdatedAt:
          _.max(nodes.map(n => n && n.updatedAt)) || new Date(0).toISOString(),
      };
    });
};

function deltaQueryToGQLVars(
  query: ServerQueryDelta,
  filters: Filter.Filter<Run.Key>
): Generated.RunsStateDeltaQueryQueryVariables {
  return {
    ...queryToGQLVars(query, filters),
    currentRuns: query.prevResult.page,
    lastUpdated: new Date(query.prevResult.maxUpdatedAt),
  };
}

function deltaQueryToGQLVarsWithProjectId(
  query: ServerQueryDelta,
  filters: Filter.Filter<Run.Key>
): Generated.RunsStateDeltaQueryWithProjectIdQueryVariables {
  return {
    ...queryToGQLVarsWithProjectId(query, filters),
    currentRuns: query.prevResult.page,
    lastUpdated: new Date(query.prevResult.maxUpdatedAt),
  };
}

export function parseGqlDeltaOp(op: Generated.RunDiff): ServerDeltaOp {
  if (op.op === 'INSERT' || op.op === 'UPDATE') {
    const parsedRun = Run.fromJson(op.run);
    if (parsedRun == null) {
      throw new Error(
        `Couldn't parse run from server: ${JSON.stringify(op.run)}`
      );
    }
    // typescript really doesn't like this union type for some reason
    if (op.op === 'INSERT') {
      return {
        op: 'INSERT',
        index: op.index,
        run: parsedRun,
      };
    } else {
      return {
        op: 'UPDATE',
        index: op.index,
        run: parsedRun,
      };
    }
  } else if (op.op === 'DELETE') {
    return {
      op: op.op,
      index: op.index,
    };
  }
  throw new Error(`Couldn't parse run diff from server: ${JSON.stringify(op)}`);
}

export const doDeltaQuery = (
  client: ApolloClient,
  query: ServerQueryDelta
): Promise<ServerResultDelta> => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    return Promise.resolve({
      totalRuns: 0,
      delta: _.range(0, query.prevResult.page.length)
        .reverse()
        .map(i => ({op: 'DELETE', index: i})),
    });
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }

  const deltaQuery =
    query?.internalProjectId != null && query?.internalProjectId !== ''
      ? client.query<Generated.RunsStateDeltaQueryWithProjectIdQuery>({
          query: Generated.RunsStateDeltaQueryWithProjectIdDocument,
          fetchPolicy: 'no-cache',
          variables: deltaQueryToGQLVarsWithProjectId(query, filters),
          context: propagateErrorsContext(),
        })
      : client.query<Generated.RunsStateDeltaQueryQuery>({
          query: Generated.RunsStateDeltaQueryDocument,
          fetchPolicy: 'no-cache',
          variables: deltaQueryToGQLVars(query, filters),
          context: propagateErrorsContext(),
        });

  return deltaQuery.then(result => {
    const project = result.data.project;
    if (project == null || project.runs == null) {
      return {
        totalRuns: 0,
        delta: [],
      };
    }
    /* TS3.9 upgrade caused type mismatch here, because generated gql Run
       type doesn't match our hand-written one */
    const ops = project.runs.delta.map(x => parseGqlDeltaOp(x as any));
    return {
      totalRuns: project.runs.totalCount,
      delta: ops,
    };
  });
};
