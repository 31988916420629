import * as _ from 'lodash';

import * as Run from '../../util/runs';
import * as Update from '../../util/update';
import * as Types from './types';

export function applyAggregationUpdate(
  agg: Types.NormalizedAggregations,
  keys: string[] | undefined,
  data: Run.Aggregations,
  updated: string
): Types.NormalizedAggregations {
  if (keys == null) {
    return agg;
  }
  const aggItemIndex = findAggregationItemIndex(agg, keys);
  if (aggItemIndex === -1) {
    return [
      ...agg,
      {
        keys,
        updated,
        data,
      },
    ];
  }
  const aggItem = agg[aggItemIndex];
  if (updated > aggItem.updated) {
    return Update.updateArrayIndex(agg, aggItemIndex, {
      ...aggItem,
      updated,
      data,
    });
  }
  return agg;
}

export function getAggregationResult(
  agg: Types.NormalizedAggregations,
  keys: string[] | undefined
): Run.Aggregations {
  if (keys == null) {
    return Run.EmptyAggregations;
  }
  const aggItem = agg[findAggregationItemIndex(agg, keys)];
  if (aggItem == null) {
    return Run.EmptyAggregations;
  }
  return aggItem.data;
}

function findAggregationItemIndex(
  agg: Types.NormalizedAggregations,
  keys: string[]
) {
  return _.findIndex(agg, ai => _.isEqual(ai.keys, keys));
}
