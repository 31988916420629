import {find, isEqual, isMatch} from 'lodash';
import {createSelector} from 'reselect';

import type {RootState} from '../../types/redux';
import {deepArrayEqual} from '../../util/compare';
import * as Normalize from './normalize';
import {lookupPart} from './normalizerSupport';
import * as Types from './types';

export const getViews = (state: RootState) => state.views;

export const makeViewListSelector = (
  entityName: string,
  projectName: string,
  viewType: string
) => {
  const params: Types.LoadMetadataListParams = {
    entityName,
    projectName,
    viewType,
  };
  return createSelector(getViews, views => {
    // TODO: Factor this find logic out into a function and call it from
    // reducer
    const list = find(views.lists, q => isMatch(q.query, params));
    if (!list) {
      return {
        loading: false,
        views: [],
      };
    }
    return {
      loading: list.loading,
      views: list.viewIds.map(vId => views.views[vId]),
    };
  });
};

// Create a selector that returns a denormalized ('whole') object.
export const makeWholeSelector = <T extends Types.ObjType>(
  ref: Types.PartRefFromType<T>
): ((state: RootState) => NonNullable<Types.WholeFromTypeWithRef<T>>) => {
  return (state: RootState) => {
    return Normalize.denormalize(state.views.parts, ref);
  };
};

// Same as above but for a normalized object.
export const makePartSelector = <T extends Types.ObjType>(
  ref: Types.PartRefFromType<T>
) => {
  return (state: RootState) => {
    return lookupPart(state.views.parts, ref) as unknown as NonNullable<
      Types.PartFromType<T>
    >;
  };
};

export const makePartsSelector = <T extends Types.ObjType>(
  refs: Array<Types.PartRefFromType<T>>
) => {
  let result: Array<NonNullable<Types.PartFromType<T>>> = [];
  return (state: RootState) => {
    const newResult = [];
    for (const ref of refs) {
      newResult.push(
        lookupPart(state.views.parts, ref) as unknown as NonNullable<
          Types.PartFromType<T>
        >
      );
    }
    if (!deepArrayEqual(result, newResult)) {
      result = newResult;
    }
    return result;
  };
};

export const makeOptionalPartSelector = <T extends Types.ObjType>(
  ref: Types.PartRefFromType<T> | undefined
) => {
  return (state: RootState) => {
    if (ref == null) {
      return undefined;
    }
    return lookupPart(state.views.parts, ref);
  };
};

export const makePartMappedSelector = <T extends Types.ObjType, R>(
  ref: Types.PartRefFromType<T>,
  map: (part: Types.PartFromType<T>) => R,
  stateRef: {prevPart?: Types.PartFromType<T>; result?: R}
) => {
  return (state: RootState) => {
    // TODO(john): shady unknown casting
    const part = makePartSelector(ref)(state) as unknown as
      | Types.PartFromType<T>
      | undefined;

    if (part !== stateRef.prevPart) {
      const mapped = (map as any)(part);
      if (!isEqual(mapped, stateRef.result)) {
        stateRef.result = mapped;
      }
      stateRef.prevPart = part as unknown as typeof part | undefined;
    }
    return stateRef.result!;
  };
};

// same as above but for an array of refs to parts of the same type.
export const makeWholeObjectArraySelector = <T extends Types.ObjType>(
  refs: Array<Types.PartRefFromType<T>>,
  stateRef: {
    result?: Array<Types.WholeFromType<T>>;
    selectors?: Array<
      (state: RootState) => NonNullable<Types.WholeFromTypeWithRef<T>>
    >;
    refs?: Array<Types.PartRefFromType<T>>;
  }
) => {
  const wholeObjectSelectors =
    refs === stateRef.refs && stateRef.selectors
      ? stateRef.selectors
      : refs.map(r => makeWholeSelector(r));

  return (state: RootState) => {
    const newResult = wholeObjectSelectors.map(sel => sel(state));
    if (!deepArrayEqual(stateRef.result, newResult)) {
      stateRef.refs = refs;
      stateRef.selectors = wholeObjectSelectors;
      stateRef.result = newResult as any;
    }
    return stateRef.result!;
  };
};

// Selects a whole object and then applies a mapping function. Returns
// reference equal results as long as the mapped result doesn't change.
export const makeWholeMappedSelector = <T extends Types.ObjType, R>(
  wholeSelector: (state: RootState) => Types.WholeFromType<T> | undefined,
  map: (whole: Types.WholeFromType<T>) => R,
  stateRef: {
    prevWhole?: Types.WholeFromType<T> | undefined;
    result?: R;
  }
) => {
  return (state: RootState) => {
    const whole = wholeSelector(state);
    if (whole !== stateRef.prevWhole) {
      // Only return a different reference if mapped
      // result is not deep equal to the previous result.
      const mapped = (map as any)(whole);
      if (!isEqual(mapped, stateRef.result)) {
        stateRef.result = mapped;
      }
      stateRef.prevWhole = whole as unknown as typeof whole | undefined;
    }
    return stateRef.result!;
  };
};

export const makePartExistsSelector =
  (ref: Types.AllPartRefs | null) => (state: RootState) => {
    if (ref == null) {
      return null;
    }
    return Normalize.partExists(state.views.parts, ref);
  };

export const makeNameSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.name;
  };
};

export const makeModifiedSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return false;
    }
    const view = state.views.views[ref.id];
    return view!.modified;
  };
};

export const makeAutosaveSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return false;
    }
    const view = state.views.views[ref.id];
    return view!.autoSave;
  };
};

export const makeUndoSelector = () => {
  return (state: RootState) => {
    return state.views.undoActions;
  };
};

export const makeRedoSelector = () => {
  return (state: RootState) => {
    return state.views.redoActions;
  };
};

export const makeDescriptionSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return '';
    }
    const view = state.views.views[ref.id];
    return view!.description;
  };
};

export const makeUsernameSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.user.username;
  };
};

export const makeProjectNameSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.project ? view!.project.name : null;
  };
};

export const makeEntityNameSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.project ? view!.project.entityName : null;
  };
};

export const makeExistsServerSelector = <T extends Types.ViewObjSchema>(
  ref: Types.ViewRefFromObjSchema<T> | null
) => {
  return (state: RootState) => {
    if (ref == null) {
      return false;
    }
    const view = state.views.views[ref.id];
    return view!.id != null;
  };
};

export const makeStarCountSelector = (ref: Types.ViewRef | null) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.starCount;
  };
};

export const makeStarredSelector = (ref: Types.ViewRef | null) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.starred;
  };
};

export const makeStarLoadingSelector = (ref: Types.ViewRef | null) => {
  return (state: RootState) => {
    if (ref == null) {
      return null;
    }
    const view = state.views.views[ref.id];
    return view!.starLoading;
  };
};

export const makePanelCommentsEnabledSelector = (ref: Types.ViewRef | null) => {
  return (state: RootState) => {
    if (ref == null) {
      return false;
    }
    const view = state.views.views[ref.id];
    return view!.panelCommentsEnabled;
  };
};
