import {
  REPORT_DRAFT_VIEW_TYPE,
  REPORT_VIEW_TYPE,
  ReportViewRef,
} from '../reports/types';
import {Viewer} from '../viewer/types';
import {View, ViewRef} from './types';

export function isOwnView(viewer: Viewer | undefined, view: View): boolean {
  return viewer != null && viewer.id === view.user.id;
}

export function isReportView(viewRef: ViewRef): viewRef is ReportViewRef {
  return (
    viewRef.type === REPORT_VIEW_TYPE || viewRef.type === REPORT_DRAFT_VIEW_TYPE
  );
}
