// Extra context that components use to make queries.
// The Sweep and Group pages put a filter in mergeFilters that limits
// all queries down to that particular sweep our group.
import React from 'react';

import {Filter} from '../../util/filters';
import {Key} from '../../util/runs';

export interface RunsQueryContext {
  entityName: string;
  projectName: string;
  internalProjectId: string | undefined;
  runId?: string;
  mergeFilters?: Filter<Key>;
  runSetName?: string;
  report?: {
    id: string;
    parentID?: string;
  };
  sweep?: {
    id: string;
    earlyTerminate: boolean;
  };
}

type UninitializedRunsQueryContext = Partial<RunsQueryContext>;

export const RunQueryContext =
  React.createContext<UninitializedRunsQueryContext>({});
