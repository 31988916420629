import {ID} from '@wandb/weave/core';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ThunkResult} from '../../types/redux';
import {save} from '../views/actions';
import * as ViewActionsInternal from '../views/actionsInternal';
import * as ViewApi from '../views/api';
import {makeViewListSelector} from '../views/selectors';
import {LoadableView, View, ViewType} from '../views/types';
// eslint-disable-next-line import/no-cycle
import {
  getProjectReadonlyState,
  newWorkspaceViews,
  showNewView,
} from './actions';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {findLegacyOrNwView} from './find';
import {nwToast} from './namedWorkspaceToast';
import {
  viewIsDefault,
  viewIsLegacyWorkspace,
  viewNameColumnForNamedWorkspace,
} from './names';
import * as Selectors from './selectors';
import {
  getIsPersonalWorkspace,
  getPersonalNwId,
  NamedWorkspaceId,
} from './types';
import {handleNwHistoryNavigation} from './url';

// When workspaces have an empty workspace display name, we want to display 'Untitled draft'
// as a placeholder. This is used to move users to edit the workspace name.
export const EMPTY_WORKSPACE_DISPLAY_NAME = '';
export const WORKSPACE_PLACEHOLDER_NAME = 'Untitled view';
/** @deprecated */
export const DEPRECATED_WORKSPACE_PLACEHOLDER_NAME = 'Untitled draft';

const getWorkspaceTypeDisplayName = (viewType?: ViewType) => {
  switch (viewType) {
    case 'project-view':
      return 'workspace';
    case 'sweep-view':
      return 'sweep workspace';
    case 'group-view':
      return 'group workspace';
    case 'run-view':
      return 'run workspace';
    default:
      return 'workspace';
  }
};

export const getUserWorkspaceDisplayName = (
  username: string | undefined,
  viewType: ViewType
) => {
  const prefix = username == null ? undefined : `${username}'s`;
  const workspaceTypeDescription = getWorkspaceTypeDisplayName(viewType);
  const fullString = [prefix, workspaceTypeDescription]
    .filter(s => s != null)
    .join(' ');
  // can't capitalize the char in place, so slice it off the front
  return fullString.charAt(0).toUpperCase() + fullString.slice(1);
};

/**
 * Does all the steps to create the new row for the view table, including updating reducer state and
 * saving it to the server.
 *
 * Note that this doesn't care if the view is a draft or published - that's up
 * to the caller based on how they pass in information
 *
 * @returns the cid of the view that was created and saved
 */

const createNewWorkspaceView = (
  workspaceStateId: string,
  nwId: NamedWorkspaceId,
  // Why unknown? If we need to interact with the spec, we could always treat it as Record<string, any>,
  // but I figured since we don't need to do anything with it for now, we can just treat is as unknown and try to
  // avoid involving any as a type here.
  spec: unknown,
  displayName: string
): ThunkResult<Promise<string | null>> => {
  return async (dispatch, getState, client) => {
    const state = getState();
    const workspace = state.workspaces[workspaceStateId];
    const viewer = state.viewer.viewer;

    // Make sure redux state is populated before moving on
    if (workspace == null) {
      // Log to understand why we're hitting this
      console.error('createNewWorkspaceView - data not ready', {
        views: state.views,
        viewer,
        nwId,
      });
      return Promise.resolve(null);
    }

    const {viewType, entityName, projectName} = workspace;

    const readOnly = getProjectReadonlyState(viewer, workspace, state);

    // it'd be good to move this out to a separate "construct a spec" function
    const name = viewNameColumnForNamedWorkspace(nwId);
    const cid = 'cnw-' + ID(12);
    // I would prefer it if this was Saveable view, but loadFinished wants a loadResult type
    const newView: ViewApi.LoadResultType = {
      // note that the cid is never sent to the server, but is used as a local ID. It will
      // change if the user refreshes the page.
      cid,
      type: viewType,
      name,
      displayName,
      description: '',
      project: {
        entityName,
        name: projectName,
        readOnly,
      },
      spec,
      // This value is used to determine if teammates have the ability to upsertView.
      // In the NW world, non-view only teammates can create, save, and delete shared workspaces
      // so we want to set locked = false. Only the owner of the personal workspace should be
      // able to make any changes, so we want to set locked = true.
      locked:
        nwId.nwType === 'shared workspace' || !getIsPersonalWorkspace(nwId.id)
          ? false
          : true,
      // The below fields are so that loadFinished can run successfully
      updatedAt: new Date(),
      createdAt: new Date(),
      user: viewer
        ? {
            id: viewer.id,
            name: viewer.name,
            username: viewer.username ?? viewer.email,
            photoUrl: viewer.photoUrl,
            admin: viewer.admin,
          }
        : {
            id: 'ANONYMOUS',
            name: '',
            username: '',
            photoUrl: '',
            admin: false,
          },
      entityName,
    };

    const viewRef = {type: viewType, id: cid};
    // various calls to ensure we have the proper state in reducers to know that this workspace view exists
    // Note that loadFinished must come before save so that the save doesn't fail when it looks for the
    // save saga looks for that viewRef
    await dispatch(ViewActionsInternal.loadFinished(cid, newView, true));
    // tell the server that this new view exists
    await dispatch(save(viewRef));
    await dispatch(newWorkspaceViews(workspaceStateId, [cid]));
    return Promise.resolve(cid);
  };
};

/**
 * For recently created workspaces, we can't assume that the save action has occurred, so we can't
 * request the full view including the spec from the server. But since we know we have
 * the full view with the spec stored in the redux store, we can just call showNewView without needing
 * to invoke the load step.
 *
 * This method assumes that it is being called after createNewWorkspaceView(), but the real dependency is
 * that ViewActionsInternal.loadFinished has been called.
 */
const loadRecentlyCreatedWorkspace = (
  workspaceStateId: string,
  nwId: NamedWorkspaceId,
  viewCid: string | null
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (viewCid == null) {
      return;
    }

    const workspace = getState().workspaces[workspaceStateId];
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
    await dispatch(
      showNewView(
        workspaceStateId,
        {type: workspace.viewType, id: viewCid},
        nwId
      )
    );
  };
};

export const migrateAndLoadLegacyWorkspace = (
  workspaceStateId: string,
  legacyServerId: string,
  newNwId: NamedWorkspaceId,
  newDisplayName: string
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState, client) => {
    // can't use the cached version in redux (which is where the legacyAndNwViews variable is from) b/c we need the spec
    // the View type has a spec field, but it is not guaranteed to be populated for a random spec -
    // `spec is only loaded when loading Single View By Id
    const legacyView = await ViewApi.loadSingleViewById(
      client,
      legacyServerId,
      'cache'
    );

    const newViewCid = await dispatch(
      createNewWorkspaceView(
        workspaceStateId,
        newNwId,
        legacyView.spec,
        newDisplayName
      )
    );
    await dispatch(
      loadRecentlyCreatedWorkspace(workspaceStateId, newNwId, newViewCid)
    );
  };
};

/**
 * Since we continue to use legacy views instead of migrating in the NW worlds,
 * we cannot rely on the "displayName" for the correct value.
 */
export function getWorkspaceDisplayName(view?: LoadableView | View) {
  if (view == null) {
    return WORKSPACE_PLACEHOLDER_NAME;
  }

  if (viewIsLegacyWorkspace(view.name)) {
    return viewIsDefault(view.name)
      ? 'Default view'
      : getUserWorkspaceDisplayName(view.user.username, view.type);
  }
  return view.displayName;
}

/** This is a load action that can be taken when visitng a NW url. */
export const createNewWorkspaceAndLoad = (
  workspaceStateId: string,
  newNwId: NamedWorkspaceId,
  displayName: string
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState, client) => {
    const workspace = getState().workspaces[workspaceStateId];
    const cid = await dispatch(
      createNewWorkspaceView(
        workspaceStateId,
        newNwId,
        workspace?.defaultSpec,
        displayName
      )
    );

    return await dispatch(
      loadRecentlyCreatedWorkspace(workspaceStateId, newNwId, cid)
    );
  };
};

export const copyToAndLoadPersonalWorkspace = (
  workspaceStateId: string
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState, client) => {
    const state = getState();
    const viewer = state.viewer.viewer;
    if (viewer == null || !viewer?.username) {
      nwToast('Error copying to personal workspace.');
      throw new Error('error loading personal workspace');
    }
    const workspaceSelector =
      Selectors.makeWorkspaceReduxStateSelector(workspaceStateId)(state);
    const workspaceResult = Selectors.getReadyData(workspaceSelector);

    const newSpecResult = Selectors.makeWorkspaceSpecSelector(
      workspaceStateId,
      workspaceResult.viewType
    )(state);
    const newSpecResultData = Selectors.getReadyData(newSpecResult);
    if (newSpecResultData.fullSpec == null) {
      nwToast('Error copying to personal workspace.');
      throw new Error('copyToAndLoadPersonalWorkspace - error loading spec');
    }

    const viewListResult = makeViewListSelector(
      workspaceResult.entityName,
      workspaceResult.projectName,
      workspaceResult.viewType
    )(state);
    const {foundView, nwId} = findLegacyOrNwView(
      viewer.username,
      viewListResult.views,
      workspaceResult.relatedId,
      workspaceResult.viewType,
      getPersonalNwId(viewer.username, workspaceResult.relatedId)
    );
    if (foundView?.id && nwId) {
      // Fetch personal workspace spec so we can use it for undo action
      // because it's not guaranteed spec is in redux.
      // Example: user could visit nw urls directly
      const personalWorkspaceResult = await ViewApi.loadSingleViewById(
        client,
        foundView.id,
        'no-cache'
      );
      await dispatch(
        ViewActionsInternal.undoableUpdateViewSpec(
          {id: foundView.cid},
          {
            prevSpec: personalWorkspaceResult.spec,
            newSpec: newSpecResultData.fullSpec,
            wholeIsDefinitelyNew: true,
          }
        )
      );
      await dispatch(
        showNewView(
          workspaceStateId,
          {type: workspaceResult.viewType, id: foundView.cid},
          nwId
        )
      );
      handleNwHistoryNavigation('push', nwId);
    } else {
      // if a personal workspace can't be found, go create one for the user
      const personalNwId = getPersonalNwId(
        viewer.username,
        workspaceResult.relatedId
      );
      await dispatch(
        createNewViewWithSpecAndLoad(
          workspaceStateId,
          personalNwId,
          newSpecResultData.fullSpec,
          getUserWorkspaceDisplayName(viewer.username, workspaceResult.viewType)
        )
      );
      handleNwHistoryNavigation('push', personalNwId);
    }

    nwToast('Successfully copied to personal workspace.');
  };
};

/** Used to save a workspace */
export const copyToAndReloadSharedView = (
  workspaceStateId: string
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState, client) => {
    const state = getState();

    const viewer = state.viewer.viewer;
    if (viewer == null || !viewer?.username) {
      nwToast('Error saving view.');
      throw new Error('error loading personal workspace');
    }

    const workspaceSelector =
      Selectors.makeWorkspaceReduxStateSelector(workspaceStateId)(state);
    const workspaceResult = Selectors.getReadyData(workspaceSelector);

    const workspaceViewSelector =
      Selectors.makeWorkspaceViewSelector(workspaceStateId)(state);
    const workspaceViewResult = Selectors.getReadyData(workspaceViewSelector);

    const workspaceSpecSelector = Selectors.makeWorkspaceSpecSelector(
      workspaceStateId,
      workspaceResult.viewType
    )(state);
    const workspaceSpecResult = Selectors.getReadyData(workspaceSpecSelector);
    if (
      workspaceSpecResult.fullSpec == null ||
      workspaceViewResult.view == null ||
      workspaceResult?.nwId == null
    ) {
      nwToast('Error saving view.');
      throw new Error('Error saving view');
    }

    const view = workspaceViewResult.view;

    dispatch(
      ViewActionsInternal.updateViewSpec(view.cid, workspaceSpecResult.fullSpec)
    );
    dispatch(save({type: workspaceResult.viewType, id: view.cid}));
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
    dispatch(
      showNewView(
        workspaceStateId,
        {type: workspaceResult.viewType, id: view.cid},
        workspaceResult.nwId
      )
    );

    const displayName =
      view.displayName.length === 0 ? 'workspace' : `"${view.displayName}"`;
    nwToast(`Successfully saved ${displayName}.`);
  };
};

export const createNewViewWithSpecAndLoad = (
  workspaceStateId: string,
  nwId: NamedWorkspaceId,
  spec: unknown,
  displayName: string
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState, client) => {
    const cid = await dispatch(
      createNewWorkspaceView(workspaceStateId, nwId, spec, displayName)
    );
    await dispatch(loadRecentlyCreatedWorkspace(workspaceStateId, nwId, cid));
    nwToast('Successfully created new view.');
    handleNwHistoryNavigation('push', nwId);
  };
};
