import * as d3 from 'd3';
import {format} from 'd3-format';
import {scaleLinear, scaleLog} from 'd3-scale';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {truncateDisplayName} from '../runhelpers';
import {textWidth} from '../text';
import {PlotFontSize} from './plotFontSize';
import {Line} from './types';

export type YAxisType = 'linear' | 'log';
export type XAxisType = 'linear' | 'log' | 'time';

export enum XAxisValues {
  Step = '_step',
  RunTime = '_runtime',
  Timestamp = '_timestamp',
  AbsoluteRunTime = '_absolute_runtime',
}

export const defaultXAxisValues: string[] = [
  XAxisValues.RunTime,
  XAxisValues.AbsoluteRunTime,
  XAxisValues.Timestamp,
  XAxisValues.Step,
];

export const xAxisLabels: {[key: string]: string} = {
  [XAxisValues.Step]: 'Step',
  [XAxisValues.RunTime]: 'Relative Time (Process)',
  [XAxisValues.AbsoluteRunTime]: 'Relative Time (Wall)',
  [XAxisValues.Timestamp]: 'Wall Time',
};

export function xAxisLabel(key: string): string {
  const label = xAxisLabels[key];
  return label || key;
}

export const axisLabelMargin = 13; // Constant distance between label and axis line

export const axisTickRotate = -45;

export const getAngledXAxisMarginHeight = (keys: string[]) => {
  if (keys.length === 0) {
    return 0;
  }
  const w = 15 + Math.max(...keys.map(k => textWidth(k, 'xsmall')));
  // Convert the text width to height of the angled triangle with a standard trig operation
  return w * Math.sin(Math.abs(axisTickRotate));
};

const SCALE_FUNCTIONS: {
  linear: () => d3.ScaleLinear<number, number>;
  log: () => d3.ScaleLogarithmic<number, number>;
} = {
  linear: scaleLinear,
  log: scaleLog,
};

/**
 * Copied from react-vis because it was a private function
 * React vis uses this internally to create the scale for
 * axis ticks
 *
 * Create a scale function from the scale object.
 *
 * @returns {*} Scale function.
 */
export function getScaleFnFromScaleObject(scaleObject: {
  domain: number[];
  type: XAxisType | YAxisType;
}) {
  if (!scaleObject) {
    return null;
  }
  const {type, domain} = scaleObject;
  const modDomain =
    domain[0] === domain[1]
      ? domain[0] === 0
        ? [-1, 0]
        : [-domain[0], domain[0]]
      : domain;

  const scale = (SCALE_FUNCTIONS as any)[type]().domain(modDomain);
  return scale;
}

export function formatXAxisNonTime(xType: string, xMin: number, xMax: number) {
  if (xType === 'time') {
    return undefined;
  }

  // Decimal notation with 4 significant digits
  const formatWithSI = format('.4~s');
  const formatWithoutSI = format('.4~r');

  // Avoid using milli SI units, since it's weird to see "800m" instead of "0.8"
  const delta = xMax - xMin;
  const absMin = Math.abs(xMin);
  const absMax = Math.abs(xMax);
  const minInMilliRange = absMin >= 0.001 && absMin < 1;
  const maxInMilliRange = absMax >= 0.001 && absMax < 1;
  if (minInMilliRange && maxInMilliRange) {
    return formatWithoutSI;
  }
  if ((minInMilliRange || maxInMilliRange) && delta < 1000) {
    return formatWithoutSI;
  }

  return formatWithSI;
}

// Format the y-axis for the line chart, which doesn't allow
// time on the y axis
export const formatYAxis = (tick: number): string => {
  return format('.5')(tick).length < 7
    ? format('.5')(tick)
    : format('.2~e')(tick);
};

export const getYAxisWidth = (
  labels: string[],
  plotFontSize: PlotFontSize = 'small'
) => {
  if (labels.length === 0) {
    return 0;
  }
  // Find the length of the longest label
  const maxLabelWidth = Math.max(
    ...labels.map(l => textWidth(truncateDisplayName(l), plotFontSize))
  );

  // Add the distance between the label and axis line
  return maxLabelWidth + axisLabelMargin;
};

export function prettyXAxisLabel(xAxis: string, lines: Line[]) {
  /*
   * Make a pretty xAxisLabel
   * Really all we do is:
   * 1) Change special keys _runtime and _timestamp and _step to
   * 2) take times and return (sec), (min) or (hour)
   * otherwise just return the label
   */

  if (!(xAxis in xAxisLabels)) {
    // LB: This is the common case
    return xAxis;
  }

  if (!lines || lines.length === 0) {
    // we can't figure out if its seconds or mins or whatever...
    return 'Time';
  }

  // all timesteps should be the same so we can just look at the first one
  const timestep = lines.length > 0 ? lines[0].timestep || '' : '';
  let label = xAxisLabels[xAxis] || '';
  if (xAxis === '_runtime' || xAxis === '_absolute_runtime') {
    label = 'Time' + (timestep ? ' (' + timestep + ')' : '');
  }

  return label;
}
