/* This is copied from RunFilters, it works on filters directly rather
   than using view object refs.

   TODO(vega2): remove the depulication with a refactor if we end up keeping this
   */

import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import _ from 'lodash';
import React, {useCallback, useLayoutEffect, useState} from 'react';

import {useProjectTagsQuery} from '../../state/graphql/projectTagsQuery';
import {useRunValueSuggestionsQuery} from '../../state/graphql/runValueSuggestionsQuery';
import * as FiltersUtil from '../../util/filters';
import * as RunUtil from '../../util/runs';
import {FilterKeySelectorCreatorProps} from '../Filters/FilterKeySelector';
import {
  filterValueOptionTransform,
  FilterValueSelector,
  FilterValueSelectorCreatorProps,
  FilterValueSelectorDate,
  FilterValueSelectorTime,
} from '../Filters/FilterValueSelector';
import {NamedProjectFieldSelector} from '../ProjectFieldSelector';
import {
  activeFilterCount,
  WBTableActionFilter,
  WBTableActionFilterButton,
  WBTableActionFilterPicker,
} from '../WBTable/WBTableActionsFilter';
import type {RunFilterValueSelectorCreatorProps} from './RunsFilterTableAction';

interface RunsFilterButtonProps {
  className?: string;
  compact?: boolean;
  filtersOpen: boolean;
  filters: FiltersUtil.Filter<RunUtil.Key>;
}

const RunsFilterButton = (props: RunsFilterButtonProps) => {
  const {filters} = props;

  const passThroughProps = _.omit(props, 'compact', 'filtersOpen', 'filters');
  return (
    <WBTableActionFilterButton
      {...passThroughProps} // Required for use as popup trigger
      compact={props.compact}
      filtersOpen={props.filtersOpen}
      filters={filters as any}
    />
  );
};

interface RunsFilterPickerProps {
  entityName: string;
  projectName: string;
  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  filters: FiltersUtil.Filter<RunUtil.Key>;
  overflowFunc?: () => void;
  onFiltersChanged(): void;
  setFilters(filters: FiltersUtil.Filter<RunUtil.Key>): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: RunFilterValueSelectorCreatorProps
  ): React.ReactNode;
}

export const RunsFilterPicker = (props: RunsFilterPickerProps) => {
  const {filters, setFilters, onFiltersChanged, defaultToggleFilters} = props;

  const setFiltersWrapped = useCallback(
    (...args: Parameters<typeof setFilters>) => {
      setFilters(...args);
      onFiltersChanged();
    },
    [setFilters, onFiltersChanged]
  );

  return (
    <WBTableActionFilterPicker
      filters={filters as any}
      defaultToggleFilters={defaultToggleFilters}
      overflowFunc={props.overflowFunc}
      setFilters={setFiltersWrapped}
      filterKeySelector={props.filterKeySelector}
      filterValueSelector={props.filterValueSelector}
    />
  );
};

type RunsFilterKeySelectorProps = FilterKeySelectorCreatorProps & {
  entityName: string;
  projectName: string;
};

export const RunsFilterKeySelector = (props: RunsFilterKeySelectorProps) => {
  const {entityName, projectName, keyValue, onValidSelection, focusOnMount} =
    props;
  const defaultKeys = [
    'run:displayName',
    'tags:-',
    'run:state',
    'run:createdAt',
    'run:duration',
    'run:username',
    'run:sweep',
    'run:group',
    'run:jobType',
    'run:inputArtifacts',
    'run:outputArtifacts',
  ];

  return (
    <NamedProjectFieldSelector
      data-test="filter-key"
      className="filter-dropdown filter-list__key"
      entityName={entityName}
      projectName={projectName}
      defaultKeys={defaultKeys}
      focusOnMount={focusOnMount}
      closeOnChange
      selection
      multi={false}
      value={keyValue}
      setValue={onValidSelection}
      searchByKeyAndText
    />
  );
};

type RunsFilterValueSelectorProps =
  FilterValueSelectorCreatorProps<RunUtil.Key> & {
    entityName: string;
    projectName: string;
  };

export const RunsFilterValueSelector = (
  props: RunsFilterValueSelectorProps
) => {
  const {entityName, projectName} = props;

  const {name, section} = props.filter.key;

  const setPartial = (
    partialFilter: Partial<FiltersUtil.IndividualFilter<RunUtil.Key>>
  ) => {
    props.setFilter({
      ...props.filter,
      ...partialFilter,
    } as FiltersUtil.IndividualFilter<RunUtil.Key>);
  };

  const setFilter = props.setFilter as (
    filter: FiltersUtil.IndividualFilter<RunUtil.Key>
  ) => void;

  switch (section) {
    case 'run':
      if (name === 'createdAt') {
        return (
          <FilterValueSelectorDate
            value={props.filter.value as string}
            setFilter={setPartial}
          />
        );
      } else if (name === 'duration') {
        const filter = props.filter as FiltersUtil.ValueFilter<RunUtil.Key>;
        return (
          <FilterValueSelectorTime
            meta={filter.meta}
            value={props.filter.value as string}
            setFilter={setPartial}
            minimumUnit={null}
          />
        );
      } else {
        return (
          <RunsFilterSimpleValueSelector
            entityName={entityName}
            projectName={projectName}
            filter={props.filter}
            setFilter={setFilter}
          />
        );
      }
    case 'tags':
      return (
        <RunsFilterTagNameSelector
          entityName={entityName}
          projectName={projectName}
          filter={props.filter}
          setFilter={setPartial}
        />
      );
    default:
      return (
        <RunsFilterSimpleValueSelector
          entityName={entityName}
          projectName={projectName}
          filter={props.filter}
          setFilter={setFilter}
        />
      );
  }
};

interface RunsFilterTagNameSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter<RunUtil.Key>;
  setFilter(filter: Partial<FiltersUtil.IndividualFilter<RunUtil.Key>>): void;
}

const RunsFilterTagNameSelector = (props: RunsFilterTagNameSelectorProps) => {
  const {entityName, projectName} = props;
  const projectTagsQuery = useProjectTagsQuery({entityName, projectName});
  const tagCounts =
    !projectTagsQuery.loading || !projectTagsQuery.data?.project.tagCounts
      ? []
      : projectTagsQuery.data?.project.tagCounts;
  const suggestions = tagCounts.map(tc => ({
    key: tc.name,
    value: tc.name,
    text: tc.name,
    count: tc.count,
  }));
  const tagNames = tagCounts.map(tc => tc.name);
  let currentValue: any;
  const isMulti = props.filter.op === 'IN' || props.filter.op === 'NIN';
  if (isMulti) {
    currentValue = props.filter.value;
    for (const name of currentValue) {
      if (!_.includes(tagNames, name)) {
        tagNames.push(name);
      }
    }
  } else {
    currentValue = props.filter.key.name;
    const valueTagName = props.filter.key.name;
    if (!_.includes(tagNames, valueTagName)) {
      tagNames.push(valueTagName);
    }
  }
  return (
    <ModifiedDropdown
      className="filter-dropdown filter-list__value"
      options={suggestions}
      optionTransform={filterValueOptionTransform}
      loading={projectTagsQuery.loading}
      placeholder="value"
      search
      multiple={isMulti}
      inline
      value={currentValue}
      onChange={(e, {value}) => {
        const f = props.filter;
        if (isMulti) {
          props.setFilter({
            ...f,
            value,
            disabled: false,
          } as FiltersUtil.IndividualFilter<RunUtil.Key>);
        } else {
          props.setFilter({
            ...f,
            key: {section: 'tags', name: value as string},
            disabled: false,
          });
        }
      }}
    />
  );
};

interface RunsFilterSimpleValueSelectorProps {
  entityName: string;
  projectName: string;
  filter: FiltersUtil.IndividualFilter<RunUtil.Key>;
  setFilter(filter: Partial<FiltersUtil.IndividualFilter<RunUtil.Key>>): void;
}

const RunsFilterSimpleValueSelector = (
  props: RunsFilterSimpleValueSelectorProps
) => {
  const {entityName, projectName} = props;
  const keyPath = RunUtil.keyToServerPath(props.filter.key);
  const valueSugg = useRunValueSuggestionsQuery({
    entityName,
    projectName,
    keyPath,
    filters: FiltersUtil.EMPTY_FILTERS as FiltersUtil.Filter<RunUtil.Key>,
  });
  const sugg = !valueSugg.loading ? valueSugg.valueSuggestions : [];
  const setFilter = props.setFilter as (
    filter: FiltersUtil.IndividualFilter<RunUtil.Key>
  ) => void;
  return (
    <FilterValueSelector
      {...props}
      loading={valueSugg.loading}
      suggestions={sugg}
      setFilter={setFilter}
    />
  );
};

interface RunsFilterTableActionProps {
  entityName: string;
  projectName: string;
  className?: string;
  compact?: boolean;
  preventOverflow?: boolean;
  filters: FiltersUtil.Filter<RunUtil.Key>;
  defaultToggleFilters?: FiltersUtil.DefaultToggleFilter[];
  overflowFunc?: () => void;
  setFilters(newFilters: FiltersUtil.Filter<RunUtil.Key>): void;
  trigger?(open: boolean, filterCount: number): React.ReactNode;
  onFiltersChanged(): void;
}

export const RunFilters = (props: RunsFilterTableActionProps) => {
  // Filter dropdown is a little jank in the custom charts editor.
  // We need to remount the dropdown when it juts out of the viewport,
  // so that we bring it back into view.
  const [open, setOpen] = useState(false);
  const [remount, setRemount] = useState(false);
  const remountComponent = useCallback(() => {
    // only remount if overflow behavior is set
    if (props.preventOverflow) {
      setRemount(true);
    }
  }, [props.preventOverflow]);
  useLayoutEffect(() => {
    if (remount) {
      setTimeout(() => setRemount(false));
    }
  }, [remount]);

  return remount ? null : (
    <WBTableActionFilter
      preventOverflow={props.preventOverflow}
      open={open}
      setOpen={setOpen}
      trigger={filtersOpen =>
        props.trigger ? (
          props.trigger(filtersOpen, activeFilterCount(props.filters as any))
        ) : (
          <RunsFilterButton
            className={props.className}
            compact={props.compact}
            filtersOpen={filtersOpen}
            filters={props.filters}
          />
        )
      }
      content={
        <RunsFilterPicker
          entityName={props.entityName}
          projectName={props.projectName}
          filters={props.filters}
          setFilters={props.setFilters}
          overflowFunc={remountComponent}
          defaultToggleFilters={props.defaultToggleFilters}
          onFiltersChanged={props.onFiltersChanged}
          filterKeySelector={keySelProps => (
            <RunsFilterKeySelector
              {...keySelProps}
              entityName={props.entityName}
              projectName={props.projectName}
            />
          )}
          filterValueSelector={valSelProps => (
            <RunsFilterValueSelector
              {...valSelProps}
              entityName={props.entityName}
              projectName={props.projectName}
            />
          )}
        />
      }
    />
  );
};
