// eslint-disable-next-line import/no-cycle -- please fix if you can
import {BasicValue} from './runs';

/**
 * Returns an artifact membership identifier based on any of the
 * available properties.
 */
export const makeArtifactMembershipIdentifier = (
  commitHash?: string | null,
  versionIndex?: number | null,
  digest?: string | null
): string => {
  if (commitHash != null) {
    return commitHash;
  } else if (versionIndex != null) {
    return `v${versionIndex}`;
  } else if (digest != null) {
    return digest;
  } else {
    throw new Error(
      'At least one of commitHash, versionIndex or digest must be specified'
    );
  }
};

/**
 * Determines if an artifact membership identifier is a version alias
 * or not, i.e is a string of the form "v{number}"
 */
export const isVersionAlias = (identifier: string | undefined): boolean => {
  return (
    typeof identifier === 'string' &&
    identifier.length >= 1 &&
    identifier[0] === 'v' &&
    !isNaN(parseInt(identifier.slice(1)[0], 10))
  );
};

export type ArtifactKeySection = 'artifact';

export type Value = BasicValue | BasicValue[];

export interface Key {
  section: ArtifactKeySection;
  name: string;
}

export function displayKey(k: Key) {
  if (k.section && k.name !== '') {
    if (k.section === 'artifact') {
      return k.name;
    } else {
      return k.section + ':' + k.name;
    }
  } else {
    return '-';
  }
}

export function keyToServerPath(k: Key) {
  if (k.section === 'artifact') {
    return k.name;
  }
  throw new Error('keyToServerPath error');
}

export function keyToString(k: Key) {
  return k.section + ':' + k.name;
}

export const RE_VERSION = /^v(\d+)$/;

/**
 * Given a list of aliases, derive the version number
 */
export const findVersionNumber = (aliases: string[]): number => {
  const match = aliases.find(a => a.match(RE_VERSION));
  if (match) {
    return parseInt(match.slice(1), 10);
  }
  throw new Error('No version number in artifact');
};

/**
 * Sorts a list of alias strings, with 'latest' as the first one
 * and the version strings by the number
 */
export const sortAliases = (aliases: string[]): string[] => {
  return aliases.sort((a, b) => {
    if (a === 'latest') {
      return b === 'latest' ? 0 : -1;
    }
    if (b === 'latest') {
      return 1;
    }
    const versionA = a.match(RE_VERSION);
    const versionB = b.match(RE_VERSION);
    if (versionA && versionB) {
      const numA = parseInt(versionA[1], 10);
      const numB = parseInt(versionB[1], 10);
      return numA - numB;
    } else if (versionA && !versionB) {
      return -1;
    } else if (!versionA && versionB) {
      return 1;
    }
    return a.localeCompare(b);
  });
};
