import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panels';
import * as Query from '../../util/queryts';
import * as Run from '../../util/runs';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useSampleAndQueryToTable} from '../Export';
import {derived} from '../property-editors/property-editors';
import {getTitleFromConfig, PCConfig} from './common';
export type {ParallelCoordPanelProps, PCColumn} from './common';
export type {PCConfig};

// Should combine with PanelScatterPlot

function transformQuery(query: Query.Query, config: PCConfig) {
  const result = toRunsDataQuery(query, undefined, {
    // fullConfig: true,
    // fullSummary: true,
  });
  result.page = {
    size: 500,
  };
  const columnAccessors = config.columns
    ? config.columns.map(c => c.accessor)
    : config.dimensions || [];

  const fields = columnAccessors.map(accessor => {
    if (accessor != null) {
      return Run.keyFromString(accessor);
    } else {
      return null;
    }
  });

  if (config.legendFields != null) {
    config.legendFields.forEach(field => fields.push(Run.keyFromString(field)));
  }
  result.configKeys = fields
    .filter(key => key && key.section === 'config')
    .map(key => key!.name);
  result.summaryKeys = fields
    .filter(key => key && key.section === 'summary')
    .map(key => key!.name);

  return result;
}

function useTableData(pageQuery: Query.Query, config: PCConfig) {
  const query = toRunsDataQuery(pageQuery, undefined, {});
  query.page = {
    size: 500,
  };

  const columnAccessors = config.columns
    ? config.columns.map(c => c.accessor)
    : config.dimensions || [];

  const fields = columnAccessors.map(accessor => {
    if (accessor != null) {
      return Run.keyFromString(accessor);
    } else {
      return null;
    }
  });
  if (config.legendFields != null) {
    config.legendFields.forEach(field => fields.push(Run.keyFromString(field)));
  }
  query.configKeys = fields
    .filter(key => key && key.section === 'config')
    .map(key => key!.name);
  query.summaryKeys = fields
    .filter(key => key && key.section === 'summary')
    .map(key => key!.name);

  return useSampleAndQueryToTable(query, pageQuery, config);
}

export const PANEL_TYPE = 'Parallel Coordinates Plot';

const configSpec = {
  gradientColor: {
    editor: 'checkbox' as const,
    displayName: 'Use gradient',
    default: true,
  },
  customGradient: {
    editor: 'gradient' as const,
    displayName: 'Gradient',
    default: [
      {offset: 0, color: '#900000'},
      {offset: 50, color: '#D64F04'},
      {offset: 100, color: '#FFE600'},
    ],
    visible: derived((config: any) => config.gradientColor),
  },
};

export const Spec: PanelSpec<typeof PANEL_TYPE, PCConfig> = {
  type: PANEL_TYPE,
  // eslint-disable-next-line import/no-cycle -- please fix if you can
  Component: React.lazy(() => import('./Component')),
  getTitleFromConfig,

  exportable: {
    image: true,
    csv: true,
    api: true,
  },

  transformQuery,
  configSpec,
  useTableData,
  icon: 'panel-parallel',
};
