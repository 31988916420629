import PanelError from '@wandb/weave/common/components/elements/PanelError';
import {Table} from '@wandb/weave/common/util/csv';
import {ID} from '@wandb/weave/common/util/id';
import produce from 'immer';
import {compact, every, forEach, isArray, isObject, max} from 'lodash';
import React, {FC, useCallback, useMemo} from 'react';

import {ErrorBoundary} from '../components/ErrorBoundary';
// eslint-disable-next-line import/no-cycle
import {usePanelContext} from '../components/Panel/PanelContextProvider';
import type {RunsLinePlotConfig} from '../components/PanelRunsLinePlot';
import type {VegaPanel2Config} from '../components/PanelVega2';
import * as PanelWeave from '../components/PanelWeave';
import type {PanelConfigSpec} from '../components/property-editors/property-editors';
import {RunsDataQuery} from '../containers/RunsDataLoader';
import {captureError} from '../integrations';
import {useRunsData, useRunSetsQuery} from '../state/runs/hooks';
import * as CustomRunColorsViewTypes from '../state/views/customRunColors/types';
import * as FilterActions from '../state/views/filter/actions';
import * as ViewHooks from '../state/views/hooks';
import * as PanelActions from '../state/views/panel/actions';
import * as PanelViewTypes from '../state/views/panel/types';
import {Ref as PanelRef} from '../state/views/panel/types';
import * as Filter from '../util/filters';
import * as Query from '../util/queryts';
import * as Run from '../util/runs';
import {InstrumentedLoader} from './../components/utility/InstrumentedLoader';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {AllPanelSpec, getPanelSpec, PanelType} from './getPanelSpec';
import {useDeepEqualValue} from './hooks';
import {runMediaPanelMigrations} from './media';
import {
  BasePanelProps,
  isSingleRun,
  PanelComponentType,
  PanelExportRef,
} from './panelHelpers';
import * as PanelSettings from './panelsettings';

export type {PanelProps} from './panelHelpers';
export {isGrouped} from './panelHelpers';
export {isSingleRun};

// Union type of all the PanelSpecs

// Helpers that extract the ConfigType used in a Spec
type GetSpecType<S, T> = S extends {type: T} ? S : never;
// TODO: combine?
type ConfigTypeFromPanelSpec<S> = S extends PanelSpec<any, infer C, any>
  ? C
  : never;

// Generic to derive JSON representation of a given panel from Spec
export interface PanelWithConfig<T extends PanelType> {
  __id__: string; // This is a permanent unique ID (used to associate panels with comments). Note: don't confuse this with the ref ID, which is ephemeral.
  viewType: T;
  config: ConfigTypeFromPanelSpec<GetSpecType<AllPanelSpec, T>>;
  query?: any; // legacy, used by OpenAI dashboard
}

// This helper distributes the `PanelType` union type by using conditional types.
// Instead of resulting in PanelWithConfig<A | B | ...>,
// it results in PanelWithConfig<A> | PanelWithConfig<B> | ...
// The latter is preferable because we can further type narrow the result into a specific PanelWithConfig<T>.
// See https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
// and https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types
type Dist<T extends PanelType> = T extends {} ? PanelWithConfig<T> : never;

// Valid JSON representation for all available Panels
export type Panel = Dist<PanelType> & {
  key?: string;
};

export type PanelConfig = Panel['config'];

// Helper to create a typed panel with a given layout.
export function layedOutPanel<T extends PanelType>(
  p: PanelWithConfig<T> & PanelLayout
): PanelWithConfig<T> & PanelLayout {
  return p;
}

type TransformQueryFn = (
  query: Query.Query,
  config: PanelConfig
) => RunsDataQuery;

type UseTableDataFn = (
  query: Query.Query,
  config: PanelConfig
) => {table: Table; loading: boolean};

export function transformQuery(
  query: Query.Query,
  panel: Panel
): RunsDataQuery {
  const s = getPanelSpec(panel.viewType);
  // Here we know that s.transformQuery can be called with panel.config, because
  // spec.type === panel.viewType. However, there's no way to get typescript
  // to narrow a union to an individual member, so we cast.
  const transformFn = s.transformQuery as TransformQueryFn;
  const result = transformFn(query, panel.config);

  return result;
}

export function useTableData(
  query: Query.Query,
  panel: Panel
): {table: Table; loading: boolean} {
  const s = getPanelSpec(panel.viewType);
  const useTableDataFn = s.useTableData as UseTableDataFn;
  return useTableDataFn(query, panel.config);
}

export const PanelComp = (
  props: BasePanelProps & {
    panel: Panel;
    panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
    updateConfig(newConfig: PanelConfig): void;
  }
) => {
  const {panel, updateConfig, ...passThroughProps} = props;
  const singleRun = isSingleRun(props);

  const {
    Component: PanelComponent,
    transformConfig,
    transformConfigUpdate,
  } = getPanelSpec(panel.viewType);

  const configTransformed = useMemo(
    () => transformConfig?.(panel.config as any, {singleRun}) ?? panel.config,
    [panel.config, transformConfig, singleRun]
  );

  const transformAndUpdateConfig = useCallback(
    (newConfig: PanelConfig) => {
      const newConfigTransformed =
        transformConfigUpdate != null
          ? transformConfigUpdate(newConfig as any, {singleRun})
          : newConfig;
      updateConfig(newConfigTransformed);
    },
    [updateConfig, transformConfigUpdate, singleRun]
  );

  const {loadingState} = usePanelContext();

  return (
    <React.Suspense
      fallback={
        <div className="loader-wrapper">
          <InstrumentedLoader
            name={panel.__id__ ?? 'panel-comp'}
            onComplete={loadingState?.stopTimerCallback}
          />
        </div>
      }>
      <PanelComponent
        {...passThroughProps}
        // Here we know that s.transformQuery can be called with panel.config, because
        // spec.type === panel.viewType. However, there's no way to get typescript
        // to narrow a union to an invidual member, so we just cast to any.
        config={configTransformed as any}
        updateConfig={transformAndUpdateConfig as (config: any) => void}
      />
    </React.Suspense>
  );
};

type PanelCompProps = Parameters<typeof PanelComp>[0];
type PanelCompReduxProps = Omit<
  PanelCompProps,
  | 'panel'
  | 'updateConfig'
  | 'data'
  | 'loading'
  | 'runsDataQuery'
  | 'query'
  | 'pageQuery'
  | 'customRunColors'
> & {
  panelRef: PanelViewTypes.Ref;
  customRunColorsRef: CustomRunColorsViewTypes.Ref;
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
  initialConfigState?: {[key: string]: any};
};

type MetricName = string;
export type DefinedMetric = {
  '1': MetricName;
  '5'?: number; // 1-index of name of defined metric
  '6'?: number[]; // options
};

export type MetricsDict = {
  [key: string]: string;
};

export function mapDefinedMetricToBaseMetric(
  definedMetrics: DefinedMetric[]
): MetricsDict {
  const keyMap: MetricsDict = {};
  definedMetrics.forEach(definedMetric => {
    const metric = definedMetric['1'];
    const baseMetricIndex = definedMetric['5'];
    if (metric == null || baseMetricIndex == null) {
      return;
    }
    const baseMetric = definedMetrics[baseMetricIndex - 1]['1'];
    const removedBackSlashMetric = metric.replace(/\\./g, '.');
    if (
      metric.includes('\\.') &&
      definedMetrics.some(m => m['1'] === removedBackSlashMetric)
    ) {
      keyMap[removedBackSlashMetric] = baseMetric;
    } else {
      keyMap[metric] = baseMetric;
    }
  });
  return keyMap;
}

export const useModifiedPanel = (
  panelRef: PanelViewTypes.Ref,
  panelSettings: PanelSettings.Settings | undefined
) => {
  const panel = ViewHooks.usePart(panelRef);

  // disabling this event for now since it's chatty (and thus costly in segment),
  // but it might be useful in the future
  // React.useEffect(() => {
  //   window.analytics?.track('Panel Rendered', {
  //     viewType: panel.viewType,
  //   });
  // }, [panel.viewType]);

  return useMemo(() => {
    if (
      panelSettings == null ||
      (panel.viewType !== 'Run History Line Plot' &&
        panel.viewType !== 'Run Comparer')
    ) {
      return panel;
    }

    // We need to pipe through useRunsTableGroupingInPanels so we can show
    // individual runs in the run comparer
    if (panel.viewType === 'Run Comparer') {
      return produce(panel, draft => {
        draft.config.useRunsTableGroupingInPanels =
          panelSettings.useRunsTableGroupingInPanels;
      });
    }

    const panelContainsSystemMetrics =
      panel.config.metrics &&
      !!panel.config.metrics.find(metric => metric.startsWith('system/'));

    let xAxis: string | undefined;
    if (panel.config.xAxis != null) {
      // the panel's own saved xAxis always gets top priority, if it
      // has one:
      xAxis = panel.config.xAxis;
    } else if (
      panelContainsSystemMetrics &&
      ['_runtime', '_timestamp'].indexOf(panelSettings.xAxis) === -1
    ) {
      // the panel doesn't have its own xAxis, but it can't use the global
      // xAxis because it isn't compatible with system metrics -- fall back
      // to '_runtime'
      xAxis = '_runtime';
    } else if (
      panel.config.startingXAxis != null &&
      !panelSettings.xAxisActive
    ) {
      // if the global xAxis is not active use default
      xAxis = panel.config.startingXAxis;
    } else {
      // the panel doesn't have its own xAxis -- it can use the global
      // xAxis instead:
      xAxis = panelSettings.xAxis;
    }

    let xAxisMin: number | undefined;
    if (panel.config.xAxisMin != null) {
      xAxisMin = panel.config.xAxisMin;
    } else if (xAxis === panelSettings.xAxis) {
      // we can only use the global xAxisMin if the global xAxis matches the
      // one we're actually using for this panel:
      xAxisMin = panelSettings.xAxisMin;
    }

    let xAxisMax: number | undefined;
    if (panel.config.xAxisMax != null) {
      xAxisMax = panel.config.xAxisMax;
    } else if (xAxis === panelSettings.xAxis) {
      // we can only use the global xAxisMax if the global xAxis matches the
      // one we're actually using for this panel:
      xAxisMax = panelSettings.xAxisMax;
    }
    // TODO: change smoothing settings to be handled here
    // haven't done it because we need to handle cases where settings are already set
    // const smoothingWeight = panel.config.smoothingWeight != null ? panel.config.smoothingWeight : props.panelSettings.smoothingWeight
    // const smoothingType = panel.config.smoothingType != null ? panel.config.smoothingType : props.panelSettings.smoothingType

    return produce(panel, draft => {
      draft.config = {
        ...panelSettings,
        ...panel.config,
        xAxis,
        xAxisMin,
        xAxisMax,
        // TODO (joyce) - consolidate with useRunsLinePlotSettings and usedPanelSettings
        // current cascading settings logic happens in multiple places and it's difficult to trace
        ignoreOutliers:
          panel.config.ignoreOutliers == null
            ? panelSettings.ignoreOutliers
            : panel.config.ignoreOutliers,
        // See above TODO about smoothing settings
        // smoothingWeight: smoothingWeight,
        // smoothingType: smoothingType
      };
    });
  }, [panel, panelSettings]);
};

export const PanelCompRedux: FC<PanelCompReduxProps> = (
  props: PanelCompReduxProps
) => {
  const {customRunColorsRef, panelExportRef, panelRef, runSetRefs} = props;

  const {inheritedSettings} = usePanelContext();
  const modifiedPanel = useModifiedPanel(props.panelRef, inheritedSettings);
  const panel = useDeepEqualValue(modifiedPanel);

  const updateConfig = ViewHooks.useViewAction(
    panelRef,
    PanelActions.updateConfig
  );

  const customRunColors = ViewHooks.useWhole(customRunColorsRef);

  const runSet = ViewHooks.usePart(runSetRefs[0]);
  const convertSelectionsToFilters = ViewHooks.useViewAction(
    runSet.filtersRef,
    FilterActions.selectionsToFilters
  );

  const query = useRunSetsQuery(runSetRefs);
  const panelQuery = React.useMemo(
    () => transformQuery(query, panel),
    [query, panel]
  );

  const runsDataQuery = useRunsData(panelQuery);

  const logError = React.useCallback(
    (error: Error) => {
      window.analytics?.track('Panel Error', {
        viewType: panel.viewType,
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
      });
      captureError(error, 'Panel ErrorBoundary error');
    },
    [panel.viewType]
  );

  return (
    <ErrorBoundary onError={logError} renderError={renderPanelError}>
      <PanelComp
        {...props}
        panel={panel}
        updateConfig={updateConfig}
        customRunColors={customRunColors}
        pageQuery={query}
        query={panelQuery}
        loading={runsDataQuery.loading}
        data={runsDataQuery.data}
        runsDataQuery={runsDataQuery}
        convertSelectionsToFilters={convertSelectionsToFilters}
        panelExportRef={panelExportRef}
      />
    </ErrorBoundary>
  );
};

function renderPanelError() {
  return (
    <div style={{padding: 40}}>
      <PanelError
        message={
          <div>
            Oops, something went wrong. If this keeps happening, message
            support@wandb.com with a link to this page
          </div>
        }
      />
    </div>
  );
}

type PanelExportType = 'image' | 'csv' | 'api';

export interface PanelSpec<PanelTypeT, ConfigType, QueryType = Query.Query> {
  type: PanelTypeT;
  Component: PanelComponentType<ConfigType>;

  // If true this panel doesn't have an editor modal
  noEditMode?: boolean;

  // can be exported
  exportable?: {[t in PanelExportType]?: boolean};

  configSpec?: PanelConfigSpec;

  // use SingleChartInspectorContainer instead of normal config editor, for transitioning to Inspector everything
  useInspector?: boolean;

  // icon name for <WBIcon>
  icon?: string;

  transformConfig?: (
    config: ConfigType,
    opts?: TransformConfigOptions
  ) => ConfigType;
  transformConfigUpdate?: (
    config: ConfigType,
    opts?: TransformConfigOptions
  ) => ConfigType;

  getTitleFromConfig?: (config: ConfigType) => string;
  transformQuery: (query: QueryType, config: ConfigType) => RunsDataQuery;
  useTableData?: (
    query: QueryType,
    config: ConfigType
  ) => {table: Table; loading: boolean};
}

export type TransformConfigOptions = {
  singleRun?: boolean;
};

export function isExportable(spec: PanelSpec<any, any, any>): boolean {
  return (
    spec.exportable != null &&
    Object.values(spec.exportable).some(v => v === true)
  );
}

export function isExportableAs(
  spec: PanelSpec<any, any, any>,
  exportType: PanelExportType
): boolean {
  return spec.exportable != null && spec.exportable[exportType] === true;
}

export interface LayoutCoords {
  x: number;
  y: number;
}

export interface LayoutDimensions {
  w: number;
  h: number;
}

export type LayoutParameters = LayoutCoords & LayoutDimensions;

export interface PanelLayout {
  layout: LayoutParameters;
}

export type LayedOutPanel = Panel & PanelLayout;

export type LayedOutPanelWithRef = Panel & PanelLayout & {ref: PanelRef};

export type PanelGroupConfig = LayedOutPanel[];

export type PanelGroupId = string;

export interface PanelGroup {
  name: string;
  defaults: any[];
  // TBoard stores this in PanelGroup (view), but Reports store them outside the view.
  globalConfig?: PanelSettings.Settings;
  config: PanelGroupConfig;
}

export interface Config {
  views: {[id: string]: PanelGroup};
  tabs: PanelGroupId[];
}

export const EMPTY: Config = {
  views: {},
  tabs: [],
};

export const EMPTY_SINGLE_TAB: Config = {
  views: {0: {name: 'Panels', defaults: [], config: []}},
  tabs: ['0'],
};

export function configFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): Config | null {
  if (!isObject(json.views) || !isArray(json.tabs) || json.tabs.length === 0) {
    return null;
  }
  // TODO: We should validate everything but we don't, we assume tabs
  // and views are correct.
  const parsedViews: {[id: string]: PanelGroup} = {};
  forEach(
    json.views,
    (pg, viewID) =>
      (parsedViews[viewID] = panelGroupFromJSON(pg, defaultViewType))
  );
  return {
    views: parsedViews,
    tabs: json.tabs,
  };
}

export function panelGroupFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): PanelGroup {
  // This is really cheating. It doesn't do any validation, just fixes up
  // defaultViewTypes
  json.config.forEach((panel: any) => {
    if (panel.viewType == null) {
      panel.viewType = defaultViewType;
    } else if (
      panel.viewType === 'Audio' ||
      panel.viewType === 'Image Browser' ||
      panel.viewType === 'Tables' ||
      panel.viewType === 'Images'
    ) {
      panel.viewType = 'Media Browser';
    }
    if (panel.config == null) {
      panel.config = {};
    }
  });
  // filter out legacy panels -- no more Graph panels, now the info is under the Model tab in the Run page
  let config = json.config.filter((p: any) => p.viewType !== 'Graph');
  config = config.map(panelFromJSON);
  return {...json, config};
}

export interface PanelTemplate {
  yAxis: string;
  regex: RegExp;
  key: string;
  percentage: boolean;
}

export const systemPanelTemplates: {[key: string]: PanelTemplate} = {
  'system/cpu-V2': {
    yAxis: 'Process CPU Utilization (%)',
    regex: /system\/cpu$/,
    key: 'system/cpu-V2',
    percentage: true,
  },
  'system/cpu.cpu-V2': {
    yAxis: 'System CPU Utilization (per core) (%)',
    regex: /system\/cpu\.\d+\.cpu/,
    key: 'system/cpu.cpu-V2',
    percentage: true,
  },
  'system/tpu-V2': {
    yAxis: 'TPU Utilization (%)',
    regex: /system\/tpu/,
    key: 'system/tpu-V2',
    percentage: true,
  },
  'system/ipu.averageBoardTemperature-V2': {
    yAxis: 'IPU Average Board Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average board temp/,
    key: 'system/ipu.averageBoardTemperature-V2',
    percentage: false,
  },
  'system/ipu.averageDieTemperature-V2': {
    yAxis: 'IPU Average Die Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average die temp/,
    key: 'system/ipu.averageDieTemperature-V2',
    percentage: false,
  },
  'system/ipu.clock-V2': {
    yAxis: 'IPU Clock (MHz)',
    regex: /system\/ipu\.\d+\.clock/,
    key: 'system/ipu.clock-V2',
    percentage: false,
  },
  'system/ipu.power-V2': {
    yAxis: 'IPU Power (W)',
    regex: /system\/ipu\.\d+\.ipu power/,
    key: 'system/ipu.power-V2',
    percentage: false,
  },
  'system/ipu.utilization-V2': {
    yAxis: 'IPU Utilization (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(%\)$/,
    key: 'system/ipu.utilization-V2',
    percentage: true,
  },
  'system/ipu.utilizationSession-V2': {
    yAxis: 'IPU Utilization (session) (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(session\)/,
    key: 'system/ipu.utilizationSession-V2',
    percentage: true,
  },
  'system/memory-V2': {
    yAxis: 'System Memory Utilization (%)',
    regex: /system\/memory/,
    key: 'system/memory-V2',
    percentage: true,
  },
  'system/proc.memory.rssMB-V2': {
    yAxis: 'Process Memory In Use (non-swap) (MB)',
    regex: /system\/proc\.memory\.rssMB/,
    key: 'system/proc.memory.rssMB-V2',
    percentage: false,
  },
  'system/proc.memory.percent-V2': {
    yAxis: 'Process Memory In Use (non-swap) (%)',
    regex: /system\/proc\.memory\.percent/,
    key: 'system/proc.memory.percent-V2',
    percentage: true,
  },
  'system/proc.memory.availableMB-V2': {
    yAxis: 'Process Memory Available (non-swap) (MB)',
    regex: /system\/proc\.memory\.availableMB/,
    key: 'system/proc.memory.availableMB-V2',
    percentage: false,
  },
  'system/proc.cpu.threads-V2': {
    yAxis: 'Process CPU Threads In Use',
    regex: /system\/proc\.cpu\.threads/,
    key: 'system/proc.cpu.threads-V2',
    percentage: false,
  },
  'system/disk-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk$/,
    key: 'system/disk-V2',
    percentage: true,
  },
  'system/disk.usage_percent-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk\.((.)+)\.usagePercent/,
    key: 'system/disk.usage_percent-V2',
    percentage: true,
  },
  'system/disk.usage_gb-V2': {
    yAxis: 'Disk Utilization (GB)',
    regex: /system\/disk\.((.)+)\.usageGB/,
    key: 'system/disk.usage_gb-V2',
    percentage: false,
  },
  'system/disk.io-V2': {
    yAxis: 'Disk I/O Utilization (MB)',
    regex: /system\/disk\.(in|out)/,
    key: 'system/disk.io-V2',
    percentage: false,
  },
  'system/network-V2': {
    yAxis: 'Network Traffic (bytes)',
    regex: /system\/network.(recv|sent)/,
    key: 'system/network-V2',
    percentage: false,
  },
  'system/system.power_usage_watts-V2': {
    yAxis: 'System Power Usage (W)',
    regex: /system\/system\.powerWatts$/,
    key: 'system/system.power_usage_watts-V2',
    percentage: false,
  },
  'system/gpu.gpu-V2': {
    yAxis: 'GPU Utilization (%)',
    regex: /system\/gpu\.\d+\.gpu/,
    key: 'system/gpu.gpu-V2',
    percentage: true,
  },
  'system/gpu.temp-V2': {
    yAxis: 'GPU Temp (℃)',
    regex: /system\/gpu\.\d+\.temp/,
    key: 'system/gpu.temp-V2',
    percentage: false,
  },
  'system/gpu.memory-V2': {
    yAxis: 'GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.\d+\.memory$/,
    key: 'system/gpu.memory-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated-V2': {
    yAxis: 'GPU Memory Allocated (%)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated_bytes-V2': {
    yAxis: 'GPU Memory Allocated (bytes)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated_?[bB]ytes$/,
    key: 'system/gpu.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.memory_used_bytes-V2': {
    yAxis: 'GPU Memory Used (bytes)',
    regex: /system\/gpu\.\d+\.memory_?[uU]sed$/,
    key: 'system/gpu.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.recovery_count-V2': {
    yAxis: 'GPU Recovery Count',
    regex: /system\/gpu\.\d+\.recovery_?[cC]ount$/,
    key: 'system/gpu.recovery_count-V2',
    percentage: false,
  },
  'system/gpu.enforced_power_limit_watts-V2': {
    yAxis: 'GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.\d+\.enforcedPowerLimitWatts$/,
    key: 'system/gpu.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.powerPercent-V2': {
    yAxis: 'GPU Power Usage (%)',
    regex: /system\/gpu\.\d+\.powerPercent$/,
    key: 'system/gpu.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.powerWatts-V2': {
    yAxis: 'GPU Power Usage (W)',
    regex: /system\/gpu\.\d+\.powerWatts$/,
    key: 'system/gpu.powerWatts-V2',
    percentage: false,
  },
  'system/gpu.process.gpu-V2': {
    yAxis: 'Process GPU Utilization (%)',
    regex: /system\/gpu\.process\.\d+\.gpu/,
    key: 'system/gpu.process.gpu-V2',
    percentage: true,
  },
  'system/gpu.process.temp-V2': {
    yAxis: 'Process GPU Temp (℃)',
    regex: /system\/gpu\.process\.\d+\.temp/,
    key: 'system/gpu.process.temp-V2',
    percentage: false,
  },
  'system/gpu.process.memory-V2': {
    yAxis: 'Process GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.\d+\.memory$/,
    key: 'system/gpu.process.memory-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated-V2': {
    yAxis: 'Process GPU Memory Allocated (%)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.process.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated_bytes-V2': {
    yAxis: 'Process GPU Memory Allocated (bytes)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated_?[bB]ytes$/,
    key: 'system/gpu.process.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.memory_used_bytes-V2': {
    yAxis: 'Process GPU Memory Used (bytes)',
    regex: /system\/gpu\.process\.\d+\.memory_?[uU]sed_?[bB]ytes$/,
    key: 'system/gpu.process.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.enforced_power_limit_watts-V2': {
    yAxis: 'Process GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.process\.\d+\.enforcedPowerLimitWatts$/,
    key: 'system/gpu.process.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.process.powerPercent-V2': {
    yAxis: 'Process GPU Power Usage (%)',
    regex: /system\/gpu\.process\.\d+\.powerPercent$/,
    key: 'system/gpu.process.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.process.powerWatts-V2': {
    yAxis: 'Process GPU Power Usage (W)',
    regex: /system\/gpu\.process\.\d+\.powerWatts$/,
    key: 'system/gpu.process.powerWatts-V2',
    percentage: false,
  },
  'system/trn.utilization-V2': {
    yAxis: 'Trainium Neuron Core Utilization (%)',
    regex: /system\/trn\.\d+\.neuroncore_utilization$/,
    key: 'system/trn.utilization-V2',
    percentage: true,
  },
  'system/trn.host_total_memory_usage-V2': {
    yAxis: 'Trainium Host Memory Usage, total (bytes)',
    regex: /system\/trn\.host_total_memory_usage$/,
    key: 'system/trn.host_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.neuron_device_total_memory_usage-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, total (bytes)',
    regex: /system\/trn\.neuron_device_total_memory_usage$/,
    key: 'system/trn.neuron_device_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.application_memory-V2': {
    yAxis: 'Trainium Host Memory Usage, application memory (bytes)',
    regex: /system\/trn\.host_memory_usage\.application_memory$/,
    key: 'system/trn.host_memory_usage.application_memory-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.constants-V2': {
    yAxis: 'Trainium Host Memory Usage, constants (bytes)',
    regex: /system\/trn\.host_memory_usage\.constants$/,
    key: 'system/trn.host_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.dma_buffers-V2': {
    yAxis: 'Trainium Host Memory Usage, DMA buffers (bytes)',
    regex: /system\/trn\.host_memory_usage\.dma_buffers$/,
    key: 'system/trn.host_memory_usage.dma_buffers-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.tensors-V2': {
    yAxis: 'Trainium Host Memory Usage, tensors (bytes)',
    regex: /system\/trn\.host_memory_usage\.tensors$/,
    key: 'system/trn.host_memory_usage.tensors-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.constants-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, constants (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.constants$/,
    key: 'system/trn.neuroncore_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_code-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, model code (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.model_code$/,
    key: 'system/trn.neuroncore_memory_usage.model_code-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2': {
    yAxis:
      'Trainium Neuron Device Memory Usage, model shared scratchpad (bytes)',
    regex:
      /system\/trn\.\d+\.neuroncore_memory_usage\.model_shared_scratchpad$/,
    key: 'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.runtime_memory-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, runtime_memory (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.runtime_memory$/,
    key: 'system/trn.neuroncore_memory_usage.runtime_memory-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.tensors-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, tensors (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.tensors$/,
    key: 'system/trn.neuroncore_memory_usage.tensors-V2',
    percentage: false,
  },
};

export function panelFromJSON(json: {
  viewType: string;
  config: any;
  query: any;
  __id__?: string;
}): Panel {
  /**
   * The primary purpose of this function is to take legacy settings for plots and update them
   */

  // Doesn't fully check all panel types and options, just does some old data
  // conversions where necessary.
  let panel = json as Panel;

  // LinePlot is an old view type now merged with Run History Line Plot
  if (json.viewType === 'LinePlot') {
    if (json.config.lines) {
      json.config.metrics = json.config.lines;
    }
    json.config.singleRun = true;
    json.viewType = 'Run History Line Plot';
  }

  const viewType = json.viewType;

  if (viewType === 'Run History Line Plot') {
    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key != null) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    // expression used to be a string
    if (json.config.expression != null) {
      json.config.expressions = [json.config.expression];
      json.config.expression = undefined;
    }

    if (json.config.groupLine != null) {
      json.config.groupAgg = json.config.groupLine;
      json.config.groupLine = undefined;
    }

    if (json.config.overrideLineTitles != null) {
      json.config.overrideSeriesTitles = json.config.overrideLineTitles;
      json.config.overrideLineTitles = undefined;
    }

    const config = json.config as RunsLinePlotConfig;
    if (config.legendFields != null) {
      config.legendFields = config.legendFields.map(Run.fixConfigKeyString);
    }
    if (config.groupBy != null && config.groupBy !== 'None') {
      config.groupBy = Run.fixConfigKey({
        section: 'config',
        name: config.groupBy,
      }).name;
    }

    const {
      'system/gpu.powerPercent-V2': powerPercent,
      'system/gpu.powerWatts-V2': powerWatts,
      'system/gpu.process.powerPercent-V2': processPercent,
      'system/gpu.process.powerWatts-V2': processWatts,
    } = systemPanelTemplates;

    // The system metrics panels got saved with bad titles, so we patch them up
    // if they look incorrect.
    switch (config.chartTitle) {
      case powerPercent.yAxis:
        if (every(config.metrics, m => m.match(powerWatts.regex))) {
          config.chartTitle = powerWatts.yAxis;
        }
        break;
      case processPercent.yAxis:
        if (every(config.metrics, m => m.match(processWatts.regex))) {
          config.chartTitle = processWatts.yAxis;
        }
        break;
    }

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Scatter Plot') {
    const config = json.config;
    if (config.xAxis != null) {
      config.xAxis = Run.fixConfigKeyString(config.xAxis);
    }
    if (config.yAxis != null) {
      config.yAxis = Run.fixConfigKeyString(config.yAxis);
    }
    if (config.zAxis != null) {
      config.zAxis = Run.fixConfigKeyString(config.zAxis);
    }
    if (config.minColor && config.maxColor) {
      config.customGradient = [
        {offset: 0, color: config.minColor},
        {offset: 100, color: config.maxColor},
      ];
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Parallel Coordinates Plot') {
    const config = json.config;
    if (config.dimensions != null) {
      config.dimensions = config.dimensions.map(Run.fixConfigKeyString);
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Media Browser') {
    const config = json.config;

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: runMediaPanelMigrations(config),
    };
  } else if (viewType === 'Bar Chart') {
    const config = json.config;

    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Vega2') {
    // migrate the old query format to the new
    const config = json.config as VegaPanel2Config;
    let userQuery = config.userQuery;
    const queryFields = userQuery?.queryFields ?? [];
    let fieldSettings = config.fieldSettings ?? {};

    const projectField = queryFields.find(qf => qf.name === 'project');
    const runsField = projectField?.fields.find(qf => qf.name === 'runs');
    const runSetsField = queryFields.find(qf => qf.name === 'runSets');
    if (runsField != null && runSetsField == null) {
      /* eslint-disable no-template-curly-in-string */
      userQuery = {
        queryFields: [
          {
            name: 'runSets',
            args: [{name: 'runSets', value: '${runSets}'}],
            fields: runsField.fields,
          },
        ],
      };
      /* eslint-enable no-template-curly-in-string */
      fieldSettings = Object.fromEntries(
        Object.entries(fieldSettings).map(([key, value]) => {
          if (value.startsWith('project_runs_')) {
            return [key, 'runSets_' + value.substring(13)];
          }
          return [key, value];
        })
      );
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: {
        ...config,
        userQuery,
        fieldSettings,
      },
    };
  }
  if (json.query != null) {
    // this type of panel query should only ever contain run filters
    json.query.filters = Filter.fixRunFilter(json.query.filters);
  }
  return panel;
}

// This a unique identifier for panels to figure out which panels are pinned
// TODO: This needs to work for all plot types when we switch to the new PanelBank
// NOTE: Key resolution rules are:
//   - if the panel has an explicit `key` property, this always takes precedence
//     (used for multi-metric default panels)
//   - if the panel is a vega panel, use its `historyFieldSettings.key` as the key
//   - if the panel has only one metric, that metric is the key (the panel is
//     presumed to be a single-metric default panel)
//   - if the panel has multiple metrics (with no explicit `key` property), it
//     has no key (it's presumed to be a custom chart)
export function getKey(panel: Panel): string | null {
  if (panel.key) {
    return panel.key;
  }

  if (panel.viewType === 'Run History Line Plot') {
    const panelMetrics = panel.config.metrics;
    return panelMetrics && panelMetrics.length === 1 ? panelMetrics[0] : null;
  }

  if (panel.viewType === 'Vega') {
    const historyFieldSettings = panel.config.historyFieldSettings;
    return historyFieldSettings != null && historyFieldSettings.key != null
      ? historyFieldSettings.key
      : null;
  }

  if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      null;

    return ks && ks.length === 1 ? ks[0] : null;
  }
  if (panel.viewType === 'Weave') {
    // Use the Weave panel's last pick op's key. This path should
    // be made more specific in the future.
    return PanelWeave.getKeyOfWeavePanel(panel);
  }

  // Handles default case and when panel view type is Bar Chart, Scatter Plot, or Parallel Coordinates Plot
  return null;
}

export function getMetrics(panel: Panel): string[] {
  if (panel.viewType === 'Run History Line Plot') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Vega') {
    if (panel.config.historyFieldSettings != null) {
      return Object.values(panel.config.historyFieldSettings);
    }
    return [];
  } else if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      [];

    return ks;
  } else if (panel.viewType === 'Parallel Coordinates Plot') {
    return panel.config.columns != null
      ? panel.config.columns.map(c => c.accessor || '')
      : [];
  } else if (panel.viewType === 'Scatter Plot') {
    const keys = compact([
      panel.config.xAxis,
      panel.config.yAxis,
      panel.config.zAxis,
    ]);
    return keys;
  } else if (panel.viewType === 'Bar Chart') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Weave') {
    const key = PanelWeave.getKeyOfWeavePanel(panel);
    if (key != null) {
      return [key];
    }
  }

  return [];
}

export const PANEL_GRID_WIDTH = 12;

function panelGridFindNextPanelLoc(
  layouts: LayoutParameters[],
  gridWidth: number,
  panelWidth: number
) {
  const columnBottoms = new Array(gridWidth).fill(0);
  for (const panel of layouts) {
    const panelBottom = panel.y + panel.h;
    for (let x = panel.x; x < panel.x + panel.w; x++) {
      columnBottoms[x] = Math.max(columnBottoms[x], panelBottom);
    }
  }
  const candidates = [];
  for (let x = 0; x < gridWidth - panelWidth + 1; x++) {
    candidates.push(max(columnBottoms.slice(x, x + panelWidth)));
  }
  // argmin
  let min = candidates[0];
  let argmin = 0;
  for (let x = 1; x < candidates.length; x++) {
    if (candidates[x] < min) {
      min = candidates[x];
      argmin = x;
    }
  }
  return {x: argmin, y: min};
}

export function getPanelGridAddPanelLayout(panelConfigs: PanelGroupConfig) {
  return {
    ...panelGridFindNextPanelLoc(
      panelConfigs.map(c => c.layout),
      PANEL_GRID_WIDTH,
      6
    ),
    w: 6,
    h: 2,
  };
}
