import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankConfigUtils from '../panelBankConfig/utils';
import {ActionType, ViewReducerState} from '../reducerSupport';
import {isReportView} from '../util';
import * as Actions from './actions';
import {Ref as WorkspaceSettingsRef} from './types';

export const disableAutoGeneratePanels = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  const newState = Object.assign({}, state);
  newState.parts = Object.assign({}, state.parts);
  newState.parts[ref.type] = Object.assign({}, state.parts[ref.type]);
  newState.parts[ref.type][ref.id] = Object.assign(
    {},
    state.parts[ref.type][ref.id],
    {shouldAutoGeneratePanels: false}
  );

  // hide auto-gen info banner as necessary
  const viewRef = state.views[ref.viewID].partRef;
  if (viewRef && !isReportView(viewRef)) {
    const {panelAutoGenInfoHiddenAt} = state.parts[viewRef.type][viewRef.id];
    if (panelAutoGenInfoHiddenAt == null) {
      // @ts-ignore -- tsc is being silly; we're assigning it to a shallow clone of itself
      newState.parts[viewRef.type] = Object.assign(
        {},
        newState.parts[viewRef.type]
      );
      newState.parts[viewRef.type][viewRef.id] = Object.assign(
        {},
        newState.parts[viewRef.type][viewRef.id],
        {panelAutoGenInfoHiddenAt: new Date().toISOString()}
      );
    }
  }

  const inverseAction = Actions.enableAutoGeneratePanels(
    ref,
    panelBankConfigRef,
    expectedPanels
  );
  return [newState, inverseAction];
};

// enable auto-gen requires using an existing util
// that's written with mutating logic, so we can't
// make it immutable unless that util is refactored
export const enableAutoGeneratePanels = (
  draft: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): ActionType => {
  // update setting
  draft.parts[workspaceSettingsRef.type][
    workspaceSettingsRef.id
  ].shouldAutoGeneratePanels = true;

  // generate panels
  PanelBankConfigUtils.diffAndInitPanels(
    draft,
    panelBankConfigRef,
    expectedPanels,
    true
  );

  // NOTE: This is an example where `expectedPanels` NOT being part of the `views` reducer is a
  // problem. `expectedPanels` must be included on the action payload since it's not accessible
  // within the views reducer. Aside from being a bit clunky, this means we will be using a
  // snapshot of `expectedPanels` at the time the action was created. Most of the time this won't
  // be an issue, but the snapshot could become outdated for undo/redo actions. It's very subtle,
  // but there's potential for a bug to occur here. Ideally, we'd move `expectedPanels` back into
  // the views reducer, but that would cause noticable perf degradations for all view actions
  // using immer (AKA most of them). I think a subtle bug that *might* occur *only* when you
  // undo/redo a auto-gen setting change is a very reasonable tradeoff for general perf.
  // - Connie July 2024

  const inverseAction = Actions.disableAutoGeneratePanels(
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );

  return inverseAction;
};

export const updateLinePlotWorkspaceSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: Partial<LinePlotSettings>
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
      },
    },
  };
  const prevWorkspaceSettings = newState.parts['workspace-settings'][ref.id];

  newState.parts['workspace-settings'][ref.id] = {
    ...prevWorkspaceSettings,
    linePlot: {
      ...prevWorkspaceSettings.linePlot,
      ...settings,
    },
  };

  const inverseAction = Actions.updateLinePlotWorkspaceSettings(
    ref,
    prevWorkspaceSettings.linePlot ?? {}
  );
  return [newState, inverseAction];
};
