import {notEmpty} from '@wandb/weave/common/util/obj';
import _ from 'lodash';

import {getProjectInternalId} from '../components/RunSelector/util';
import {useProjectNameFromInternalIdQuery} from '../generated/graphql';
import type {RunSetConfig} from '../state/views/runSet/types';
import type {RunHistoryKeyInfo} from '../types/run';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as Filter from './filters';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as RunHelpers from './runhelpers';
import * as Run from './runs';

export type Grouping = Run.Key[];

export interface Sort {
  keys: SortKey[];
}

export interface SortKey {
  key: Run.Key;
  ascending: boolean;
}

export const SORTKEY_CREATED_AT: Run.Key = {
  section: 'run',
  name: 'createdAt',
};
export const CREATED_AT_DESC: Sort = {
  keys: [
    {
      key: SORTKEY_CREATED_AT,
      ascending: false,
    },
  ],
};
export const CREATED_AT_ASC: Sort = {
  keys: [
    {
      key: SORTKEY_CREATED_AT,
      ascending: true,
    },
  ],
};

export const DEFAULT_RUNS_SORT = CREATED_AT_DESC;
export const DEFAULT_RUNS_SORT_KEY = CREATED_AT_DESC.keys[0];

/**
 * These represent "logical" queries, not queries that actually get sent to the server. Components (like RunSets) can build these logical queries, which then can be translated into queries to the server, like via RunsDataLoader
 */
interface QueryCommon {
  filters: Filter.Filter<Run.Key>;
  grouping?: Grouping;
  selections?: Filter.Filter<Run.Key>;
  sort: Sort;
}

export interface SingleQuery extends QueryCommon {
  displayName?: string;
  entityName: string;
  // TODO: Should this be runSetId? Also, kind of feels like this shouldn't be here.
  id: string;
  // Hack: RunsDataLoader needs to know keyInfo so it can fix up queries for old runs
  keyInfo?: RunHistoryKeyInfo;
  keysLoading?: boolean;
  name?: string;
  pollInterval?: number;
  projectName: string;
  internalProjectId?: string | undefined;
  runName?: string;
}

export interface RunSetQuery extends QueryCommon {
  enabled?: boolean;
  entityName?: string;
  id: string; // id of the RunSet.
  project?: {
    entityName: string;
    name: string;
    id?: string;
  };
  name?: string;
  projectName?: string;
}

// A base query plus a query for each runset.
export interface Query extends SingleQuery {
  enableArtifactCounts?: boolean;
  enableTags?: boolean;
  aggregationKeys?: string[];
  configKeys?: string[];
  runSets?: RunSetQuery[];
  summaryKeys?: string[];
  wandbKeys?: string[];
}

export function getLegacyProject(query: any) {
  // Old code, just for OpenAI dashboard.
  return query && (query.project || query.model);
}

// export type Query = SingleQuery | MultiQuery;

// Server parsing functions

function sortKeyFromJSON(json: any): SortKey | null {
  if (json == null) {
    return null;
  }
  if (typeof json.ascending !== 'boolean') {
    return null;
  }
  let key: Run.Key | null = null;
  if (typeof json.name === 'string') {
    key = Run.keyFromString(json.name);
  } else {
    key = Run.keyFromJSON(json.key);
  }
  if (key != null) {
    return {key, ascending: json.ascending};
  }
  return null;
}

function sortFromJSON(json: any): Sort | null {
  if (json == null) {
    return null;
  }
  const keyJSONArr: SortKey[] = json.keys ?? [json];
  const keys = _.compact(keyJSONArr.map(sortKeyFromJSON));
  return keys.length > 0 ? {keys} : null;
}

export function sortFromJSONSafe(json: any): Sort {
  return sortFromJSON(json) ?? DEFAULT_RUNS_SORT;
}

export function groupingFromJSON(json: any): Grouping | null {
  if (!_.isArray(json)) {
    return null;
  }
  return json.map(Run.keyFromJSON).filter(notEmpty);
}

/// Helpers

export function shouldRunSetAggregate(queries: SingleQuery[]) {
  return queries.filter(q => q.grouping && q.grouping.length > 0).length > 0;
}

export function sortRuns(sort: Sort, runs: Run.Run[]) {
  for (const {key, ascending} of sort.keys) {
    runs = _.sortBy(runs, r => Run.getValue(r, key));
    if (!ascending) {
      runs = _.reverse(runs);
    }
  }
  return runs;
}

// Only used by unit tests (please change this comment if no longer true)
export function groupRuns(grouping: Grouping, runs: Run.Run[]) {
  const groupKey = grouping[0];
  if (groupKey == null) {
    return runs;
  }
  const groups = _.groupBy(runs, r => Run.getValue(r, groupKey));
  return _.map(groups, (rs, name) => RunHelpers.mergeRuns(rs, name));
}

export function addSearchFilter(
  filters: Filter.Filter<Run.Key>,
  search: RunSetConfig['search'],
  grouping?: Grouping
) {
  if (search.query.length === 0) {
    return filters;
  }
  // By default, enable regex search.
  // This is important for backwards compatibility in older reports.
  const isRegexEnabled = search.isRegex ?? true;
  const makeFilter = isRegexEnabled
    ? Filter.runRegexFilter
    : Filter.runContainsFilter;
  let searchFilter = Filter.Or([
    makeFilter(search.query),
    makeFilter(search.query, 'run', 'displayName'),
  ]);
  if (grouping != null && isGroupedByGroup(grouping)) {
    searchFilter = makeFilter(search.query, 'run', 'group');
  }
  return Filter.And([filters, searchFilter]);
}

export function isGroupedByGroup(grouping: Grouping) {
  return (
    grouping.length > 0 &&
    _.isEqual(grouping[0], {
      section: 'run',
      name: 'group',
    })
  );
}

export function useFirstEnabledProject(query: Query) {
  // This is used to pick the first enabled project in query. Ideally queries
  // would query across all enabled projects, but that's harder to do. So the
  // project fields query, and history key queries use this to just query the
  // first project.
  let entityName = query.entityName;
  let projectName = query.projectName;
  let sort = query.sort;
  const rs = query.runSets?.find(s => s.enabled);
  const internalId = getProjectInternalId(rs?.project?.id) ?? '';

  const {data: projectNameFromInternalId} = useProjectNameFromInternalIdQuery({
    variables: {internalId},
    skip: rs?.project?.id == null,
  });

  if (query.runSets != null) {
    const enabledRunSets = query.runSets.find(s => s.enabled);
    if (enabledRunSets != null) {
      if (projectNameFromInternalId?.project?.name != null) {
        projectName = projectNameFromInternalId?.project?.name;
      } else if (enabledRunSets.projectName != null) {
        projectName = enabledRunSets.projectName || projectName;
      }
      if (enabledRunSets.entityName != null) {
        entityName = enabledRunSets.entityName || entityName;
      }

      sort = enabledRunSets.sort;
    }
  }
  return {entityName, projectName, sort};
}

export function sortToOrderString(sort: Sort): string {
  return sort.keys
    .map(
      ({key, ascending}) =>
        (ascending ? '+' : '-') + Filter.keyToServerPath(key)
    )
    .join(',');
}
