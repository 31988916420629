// eslint-disable-next-line import/no-cycle -- please fix if you can
import {LinePlotPanel} from '../components/PanelSettings';
import {PointVisualizationOptions} from '../components/WorkspaceSettingsModal/controls/PointVisualizationTypes';
import {SmoothingTypeValues} from './../components/elements/SmoothingConfig';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as PlotHelpers from './plotHelpers';

/**
 * PanelSettings are settings that apply to a _panel_; not necessarily any
 * one specific panel, but panels in general. They may be used to define
 * common settings for a group of panels, such as all panels in a workspace
 * or section of a workspace.
 */
export interface Settings {
  ignoreOutliers?: boolean;
  localSmoothingSettingsActive?: number;
  localxAxisSettingsActive?: number;
  smoothingActive?: boolean;
  smoothingType: SmoothingTypeValues;
  smoothingWeight: number;
  useRunsTableGroupingInPanels: boolean;
  xAxis: PlotHelpers.XAxisValues | string;
  xAxisActive?: boolean;
  xAxisMax?: number;
  xAxisMin?: number;

  /** Whether run names should be colored in panel tooltips */
  colorRunNames?: boolean;
  /** Maximum number of runs to include for a panel */
  maxRuns?: number;
  /** Default point vizualization method for runs line plots */
  pointVisualizationMethod?: PointVisualizationOptions;
  /** Whether legends should be hidden in runs line plots */
  suppressLegends?: boolean;
  /** Whether the min/max lines should be displayed on a bucketed chart */
  showMinMaxOnHover?: boolean;
  /** Determines how many runs are displayed in panel tooltips */
  tooltipNumberOfRuns?: TooltipNumberOfRunsOptions;
}

export interface SmoothingSettings {
  smoothingWeight: number;
  smoothingType: SmoothingTypeValues;
}

export enum TooltipNumberOfRunsOptions {
  Single = 'single',
  Default = 'default',
  All = 'all_runs',
}

export const EMPTY_SMOOTHING_SETTINGS: SmoothingSettings = {
  smoothingWeight: 0,
  smoothingType: 'exponentialTimeWeighted',
};

export const EMPTY_SETTINGS: Settings = {
  ...EMPTY_SMOOTHING_SETTINGS,
  xAxis: PlotHelpers.XAxisValues.Step,
  ignoreOutliers: false,
  xAxisActive: false,
  smoothingActive: false,
  useRunsTableGroupingInPanels: true,
};

const panelUsingOwnSmoothingSettings = (panel: LinePlotPanel) => {
  const {
    smoothingWeight,
    smoothingType,
    useGlobalSmoothingWeight,
    useLocalSmoothing,
  } = panel.config;
  return (
    (useGlobalSmoothingWeight == null || useGlobalSmoothingWeight === false) &&
    (useLocalSmoothing == null || !useLocalSmoothing) &&
    (smoothingWeight != null || smoothingType != null)
  );
};

export const getPanelSettingsGroups = (
  panels: LinePlotPanel[],
  usingWorkspaceSettings: boolean
) => {
  const linePlotPanelsUsingOwnSmoothing: LinePlotPanel[] = [];
  const linePlotPanelsUsingLocalSmoothing: LinePlotPanel[] = [];
  const linePlotPanelsUsingSettingSmoothing: LinePlotPanel[] = [];
  panels.forEach(panel => {
    const usingOwnSettings = panelUsingOwnSmoothingSettings(panel);
    if (usingOwnSettings) {
      linePlotPanelsUsingOwnSmoothing.push(panel);
      return;
    }
    const {useLocalSmoothing} = panel.config;
    const workspaceSettingsButPanelUsesLocal =
      useLocalSmoothing && usingWorkspaceSettings;
    if (workspaceSettingsButPanelUsesLocal) {
      linePlotPanelsUsingLocalSmoothing.push(panel);
      return;
    }
    linePlotPanelsUsingSettingSmoothing.push(panel);
  });
  return {
    linePlotPanelsUsingOwnSmoothing,
    linePlotPanelsUsingLocalSmoothing,
    linePlotPanelsUsingSettingSmoothing,
  };
};
