import {ActionType as TSActionType} from 'typesafe-actions';

import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as CustomRunColorsActions from './customRunColors/actions';
import * as DiscussionCommentActions from './discussionComment/actions';
import * as FilterActions from './filter/actions';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsActionsInternal from './groupSelections/actionsInternal';
import * as MarkdownBlockActions from './markdownBlock/actions';
import * as Normalize from './normalize';
import {StateType} from './normalizerSupport';
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import type * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelSettingsActions from './panelSettings/actions';
import * as ReportActions from './report/actions';
import * as RunSetActions from './runSet/actions';
import * as SectionActions from './section/actions';
import * as SortActions from './sort/actions';
import * as TempSelectionsActions from './tempSelections/actions';
import * as TempSelectionsActionsInternal from './tempSelections/actionsInternal';
import * as Types from './types';
import * as WorkspaceSettingsActions from './workspaceSettings/actions';

export type ActionType = TSActionType<
  | typeof Actions
  | typeof ActionsInternal
  | typeof SortActions
  | typeof FilterActions
  | typeof CustomRunColorsActions
  | typeof GroupSelectionsActions
  | typeof GroupSelectionsActionsInternal
  | typeof PanelActions
  | typeof PanelBankConfigActions
  | typeof PanelBankConfigActionsInternal
  | typeof PanelBankSectionConfigActions
  | typeof PanelSettingsActions
  | typeof SectionActions
  | typeof MarkdownBlockActions
  | typeof RunSetActions
  | typeof ReportActions
  | typeof TempSelectionsActions
  | typeof TempSelectionsActionsInternal
  | typeof DiscussionCommentActions
  | typeof WorkspaceSettingsActions
>;

interface ViewList {
  loading: boolean;
  // The metadatalist query that produced this list
  query: Types.LoadMetadataListParams;
  // Pointers to the .views field of the reducer
  viewIds: string[];
}

export interface ViewReducerState {
  // lists are the results of querying the server for lists of views
  lists: {[id: string]: ViewList};
  // any views that are loaded from the server or user created
  // (and not yet saved).
  views: {[id: string]: Types.LoadableView};
  loading: boolean;
  // normalized view specs
  parts: StateType;

  undoActions: ActionType[]; // stack of actions that undo past actions
  redoActions: ActionType[]; // stack of actions that undo future actions
}

export function deleteParts(state: ViewReducerState, ref: Types.AllPartRefs) {
  if (!Normalize.partExists(state.parts, ref)) {
    return;
  }
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefs = partsWithRef.map(p => p.ref);
  for (const subRef of subRefs) {
    delete state.parts[subRef.type][subRef.id];
  }
}

export function removeHistoryForObject(
  state: ViewReducerState,
  ref: Types.AllPartRefs
) {
  const {partsWithRef} = Normalize.denormalizeWithParts(state.parts, ref);
  const subRefIDs = new Set(partsWithRef.map(p => p.ref.id));
  const filterActionsForDeletedRefsFn = (undoAction: ActionType) => {
    if ('payload' in undoAction && 'ref' in undoAction.payload) {
      return !subRefIDs.has(undoAction.payload.ref.id);
    }

    return true;
  };
  state.undoActions = state.undoActions.filter(filterActionsForDeletedRefsFn);
  state.redoActions = state.redoActions.filter(filterActionsForDeletedRefsFn);
}
