import {RadioGroup} from '@material-ui/core';
import {WBIcon} from '@wandb/ui';
import WandbLoader from '@wandb/weave/common/components/WandbLoader';
import {Select} from '@wandb/weave/components/Form/Select';
import {TextField} from '@wandb/weave/components/Form/TextField';
import React, {useEffect, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, Message, Popup} from 'semantic-ui-react';

import {
  IconAddNew,
  IconFolderProject,
} from '../../../../../../services/weave-python/weave-public/weave-js/src/components/Icon';
import {
  cloudProviderValuesToDisplayNames,
  getAzureBucketInfo,
  StorageBucketInfoProps,
} from '../../../components/CreateTeam/hooks';
import {StorageBucketInfoFormValue} from '../../../components/CreateTeam/types';
import {
  CloudProvider,
  StorageBucketInfo,
  StorageBucketInfoInput as StorageBucketInfoInputType,
} from '../../../generated/graphql';
import * as C from './CommonDrawer.styles';
import * as T from './CreateTeamDrawer.styles';
import * as S from './StorageBucketInfoInput.styles';
interface SelectStorageBucketInfoProps {
  bucketInfo: NonNullable<StorageBucketInfoFormValue>;
  setBucketInfo: (
    storageBucketInfo: NonNullable<StorageBucketInfoFormValue>
  ) => void;
  storageBucketInfoOptions: StorageBucketInfo[];
  getInitialBucketInfo: () => StorageBucketInfoInputType;
  selectedValue: StorageBucketOption | undefined;
  setSelectedValue: (value: StorageBucketOption) => void;
}
interface StorageBucketOption {
  value: string;
  label: React.JSX.Element | string;
}
function SelectStorageBucketInfo({
  bucketInfo,
  setBucketInfo,
  storageBucketInfoOptions,
  getInitialBucketInfo,
  selectedValue,
  setSelectedValue,
}: SelectStorageBucketInfoProps) {
  /**
   * Used to keep around the state of an unsaved bucket while the user is editing.
   * If the user switches to a previously saved bucket, we keep this around so that
   * if they switch *back*, they won't have to re-enter it.
   */
  const lastSeenNewBucketInfo = React.useRef<StorageBucketInfoInputType>();

  useEffect(() => {
    // whenever the bucket info changes AND it's a new bucket,
    // save it here
    if (bucketInfo && (!('ID' in bucketInfo) || !bucketInfo.ID)) {
      lastSeenNewBucketInfo.current = bucketInfo;
    }
  }, [bucketInfo]);

  useEffect(() => {
    if ('ID' in bucketInfo) {
      const selectedBucket = storageBucketInfoOptions.find(
        o => o.ID === bucketInfo?.ID
      );
      if (!selectedBucket) {
        return;
      }
      setSelectedValue({
        label: (
          <S.BucketStorageSelectWrapper data-test="bucket-name-option">
            <IconFolderProject />
            {selectedBucket.name}
            <S.BucketStorageSelectProvider>
              {`(${
                cloudProviderValuesToDisplayNames[selectedBucket.provider]
              })`}
            </S.BucketStorageSelectProvider>
          </S.BucketStorageSelectWrapper>
        ),
        value: bucketInfo?.ID,
      });
    }
  }, [bucketInfo, setSelectedValue, storageBucketInfoOptions]);

  const storageBucketOptions = [
    ...storageBucketInfoOptions.map(option => ({
      label: (
        <S.BucketStorageSelectWrapper data-test="bucket-name-option">
          <IconFolderProject />
          {option.name}
          <S.BucketStorageSelectProvider>
            {`(${cloudProviderValuesToDisplayNames[option.provider]})`}
          </S.BucketStorageSelectProvider>
        </S.BucketStorageSelectWrapper>
      ),
      value: option.name,
    })),
    {
      label: (
        <S.BucketStorageSelectWrapper>
          <IconAddNew /> Add bucket
        </S.BucketStorageSelectWrapper>
      ),
      value: 'pending',
    },
  ];
  const groupedStorageBucketOptions = [
    {label: 'Buckets in use', options: storageBucketOptions},
  ];
  return (
    <Select<StorageBucketOption>
      id="storage-bucket-info-select"
      options={
        storageBucketInfoOptions?.length > 0
          ? groupedStorageBucketOptions
          : storageBucketOptions
      }
      value={selectedValue}
      placeholder={selectedValue ? null : 'Select bucket'}
      onChange={(d: StorageBucketOption | null) => {
        if (d?.value === 'pending') {
          setSelectedValue({
            label: (
              <S.BucketStorageSelectWrapper>
                <IconAddNew /> Add bucket
              </S.BucketStorageSelectWrapper>
            ),
            value: 'pending',
          });
          setBucketInfo(
            lastSeenNewBucketInfo.current ?? getInitialBucketInfo()
          );
          return;
        }

        const option = storageBucketInfoOptions.find(o => o.name === d?.value);
        if (!option) {
          throw new Error(`Could not find option with id ${d?.value}`);
        }
        setBucketInfo(option);
      }}
    />
  );
}

interface NotReadyBucketInfo {
  formIsReady: false;
  bucketInfo: null;
}
interface ReadyBucketInfo {
  formIsReady: true;
  bucketInfo: NonNullable<StorageBucketInfoFormValue>;
}

interface CloudProviderOptions {
  value: CloudProvider;
  text: string;
}

function useLoadedStorageBucketInfo(
  formIsReady: boolean,
  bucketInfo: StorageBucketInfoFormValue
): NotReadyBucketInfo | ReadyBucketInfo {
  if (!formIsReady || !bucketInfo) {
    return {formIsReady: false, bucketInfo: null};
  }

  // this provides type narrowing -- if you condition on the returned
  // formIsReady, TS will know whether bucketInfo exists
  return {bucketInfo, formIsReady: true};
}

export default function StorageBucketInfoInput({
  usingExternalStorage,
  setUsingExternalStorage,
  isExternalStorageEnabled,
  bucketInfo: bucketInfoProp,
  setBucketInfo,
  getInitialBucketInfo,
  isValidState,
  storageBucketInfoOptions,
  cloudProviderOptions,
  formIsReady: formIsReadyProp,
  storageLabelOptions,
}: StorageBucketInfoProps) {
  const {formIsReady, bucketInfo} = useLoadedStorageBucketInfo(
    formIsReadyProp,
    bucketInfoProp
  );
  const setBucketInfoWrapped = (newValue: StorageBucketInfoFormValue) => {
    setBucketInfo(newValue);
  };

  const onChangeField = <K extends keyof StorageBucketInfoInputType>(
    key: K,
    keyValue: StorageBucketInfoInputType[K]
  ) => {
    if (!formIsReady) {
      throw new Error(
        'onChangeField should never be called when bucketInfo is null'
      );
    }
    setBucketInfo({
      ...bucketInfo,
      [key]: keyValue,
    });
  };

  // for Azure, the user enters the account name and container name separately,
  // then we join them, separated by a space, as the "name" we send to the
  // backend
  const azureBucketInfo = getAzureBucketInfo(bucketInfo);
  const setAzureAccountName = (accountName: string) => {
    accountName = accountName.replaceAll('/', '');
    const name = `${accountName}/${azureBucketInfo?.containerName ?? ''}`;

    if (!formIsReady) {
      return;
    }

    if (name !== bucketInfo.name) {
      onChangeField('name', name);
    }
  };
  const setAzureContainerName = (containerName: string) => {
    containerName = containerName.replaceAll('/', '');
    const name = `${azureBucketInfo?.accountName ?? ''}/${containerName}`;

    if (!formIsReady) {
      return;
    }

    if (name !== bucketInfo.name) {
      onChangeField('name', name);
    }
  };
  const [selectedValue, setSelectedValue] = useState<StorageBucketOption>();
  return (
    <div data-test="storage-bucket-info">
      {storageLabelOptions ?? (
        <RadioGroup>
          <S.StorageTypeControlLabel
            value={false}
            label={
              <>
                <S.StorageTypeLabel>Server storage</S.StorageTypeLabel>
                <S.StorageTypeDescription>
                  Use the same file storage as the rest of this W&amp;B instance
                </S.StorageTypeDescription>
              </>
            }
            control={
              <S.StorageTypeRadio
                checked={!usingExternalStorage}
                onClick={() => setUsingExternalStorage(false)}
              />
            }
          />
          <Popup
            content={
              <>
                External storage is not available with your current license. To
                enable this feature, please contact{' '}
                <a href="mailto:sales@wandb.com">sales@wandb.com</a>.
              </>
            }
            disabled={isExternalStorageEnabled}
            position="left center"
            trigger={
              <S.StorageTypeControlLabel
                data-test="external-storage-label"
                value={true}
                disabled={!isExternalStorageEnabled}
                label={
                  <>
                    <S.StorageTypeLabel>External storage</S.StorageTypeLabel>
                    <S.StorageTypeDescription>
                      Specify a bucket in another cloud provider to use as
                      storage.&nbsp;
                      <S.SeeDocsLink
                        href={`https://docs.wandb.ai/guides/hosting/secure-storage-connector`}>
                        See docs
                        <WBIcon name="right-arrow" />
                      </S.SeeDocsLink>
                    </S.StorageTypeDescription>
                  </>
                }
                control={
                  <S.StorageTypeRadio
                    data-test="external-storage-radio"
                    checked={usingExternalStorage}
                    onClick={() => setUsingExternalStorage(true)}
                  />
                }
              />
            }
          />
        </RadioGroup>
      )}
      {!!usingExternalStorage &&
        (formIsReady ? (
          <T.FormRow>
            <C.Label>{'Bucket'}</C.Label>
            <SelectStorageBucketInfo
              bucketInfo={bucketInfo}
              setBucketInfo={setBucketInfoWrapped}
              getInitialBucketInfo={getInitialBucketInfo}
              storageBucketInfoOptions={storageBucketInfoOptions}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            {selectedValue?.value === 'pending' && bucketInfo && (
              <S.StorageBucketNewBucket>
                <S.StorageBucketInfoDivider />
                <S.StorageBucketInputs>
                  <S.InputLabel>Cloud provider</S.InputLabel>
                  <Select<CloudProviderOptions>
                    id="storage-bucket-info-cloud-provider"
                    getOptionLabel={option => option.text}
                    value={{
                      value:
                        bucketInfo.provider || cloudProviderOptions[0]?.value,
                      text:
                        bucketInfo.provider || cloudProviderOptions[0]?.value,
                    }}
                    onChange={(d: CloudProviderOptions | null) => {
                      onChangeField('provider', d?.value as CloudProvider);
                    }}
                    options={cloudProviderOptions || []}
                  />
                  {/* azureBucketInfo will be truthy when Azure is the selected cloud provider */}
                  {azureBucketInfo ? (
                    <>
                      <S.InputLabel>Account name</S.InputLabel>
                      <TextField
                        data-test="storage-bucket-info-azure-account-name"
                        value={azureBucketInfo.accountName}
                        onChange={(value: string) => setAzureAccountName(value)}
                        disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                        placeholder="Name of Azure storage account"
                      />

                      <S.InputLabel>Container name</S.InputLabel>
                      <TextField
                        data-test="storage-bucket-info-azure-container-name"
                        value={azureBucketInfo.containerName}
                        onChange={(value: string) =>
                          setAzureContainerName(value)
                        }
                        disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                        placeholder="Name of Azure storage container"
                      />
                    </>
                  ) : (
                    <>
                      <S.InputLabel>Name</S.InputLabel>
                      <div data-test="storage-bucket-info-name">
                        <TextField
                          value={bucketInfo.name}
                          onChange={(value: string) =>
                            onChangeField('name', value)
                          }
                          disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                          placeholder="Name of storage bucket"
                        />
                      </div>
                    </>
                  )}
                  {bucketInfo.provider === CloudProvider.Aws && (
                    <>
                      <S.InputLabel>KMS key ARN (optional)</S.InputLabel>
                      <TextField
                        data-test="storage-bucket-info-kms-key-id"
                        value={bucketInfo.kmsKeyID ?? ''}
                        onChange={(value: string) =>
                          onChangeField('kmsKeyID', value)
                        }
                        disabled={'ID' in bucketInfo && !!bucketInfo.ID}
                        placeholder="ID of encryption key"
                      />
                    </>
                  )}
                </S.StorageBucketInputs>
              </S.StorageBucketNewBucket>
            )}
            {isValidState.state === 'loading' && (
              <S.BucketStorageMessage>
                <Icon name="circle notched" loading />
                Checking if we can connect to your storage
              </S.BucketStorageMessage>
            )}
            {'errors' in isValidState &&
              isValidState.errors != null &&
              isValidState.errors.length > 0 && (
                <S.BucketStorageMessage error>
                  {isValidState.errors.length === 1 ? (
                    isValidState.errors[0] + '.'
                  ) : (
                    <Message.List>
                      {isValidState.errors.map(error => (
                        <Message.Item key={error}>{error}.</Message.Item>
                      ))}
                    </Message.List>
                  )}
                </S.BucketStorageMessage>
              )}
            {'warnings' in isValidState &&
              isValidState.warnings != null &&
              isValidState.warnings.length > 0 && (
                <S.BucketStorageMessage warning>
                  {isValidState.warnings.length === 1 ? (
                    isValidState.warnings[0] + '.'
                  ) : (
                    <Message.List>
                      {isValidState.warnings.map(warning => (
                        <Message.Item key={warning}>{warning}.</Message.Item>
                      ))}
                    </Message.List>
                  )}
                </S.BucketStorageMessage>
              )}

            {isValidState.state === 'valid' && (
              <S.BucketStorageMessage success>
                <Icon name="check" /> Your storage is successfully connected.
              </S.BucketStorageMessage>
            )}
          </T.FormRow>
        ) : (
          <WandbLoader />
        ))}
    </div>
  );
}
